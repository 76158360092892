import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Colors } from '../../../styles/Colors'
import { RadioButtonField } from '../../common/Form/RadioButtonField'
import messages from '../../../translations/messages'
import { StyledSecondSection } from '../../../styles/styledComponents'
import { StyledInfoWrapper } from '../index'
import { breakpoints } from '../../../utils/menuUtil'
import { InfoSkeleton } from '../InfoSkeleton'
import { H2, H3, P } from '../../../styles/Typography'

const StyledRadioWrapper = styled.div`
	margin-bottom: 15px;
`

const StyledDialogSection = styled.div`
	padding-top: 20px;
	padding-bottom: 20px;
`

const StyledNumberList = styled.ol`
	padding-left: 27px;
`

const StyledInsideSection = styled.div`
	background-color: ${Colors.aeraWhite};
	padding: 20px 20px 20px 20px;
	${breakpoints.mediumDevice} {
		padding: 40px 40px 40px 40px;
	}
	${breakpoints.largeDevice} {
		padding: 80px 80px 80px 80px;
		max-width: 1100px;
	}
	margin-bottom: 20px;
	margin-top: 10px;
`

const StyledAdditionalInfoWrapper = styled.div`
	margin-bottom: 46px;
	margin-top: 36px;
`

export const PePInfo = ({ user, isLoading }) => {
	const intl = useIntl()

	const { pepStatus = {} } = user || {}
	const { isPep, pepRole, pepPosition, pepName, pepCountryOfPosition } = pepStatus || null

	const [openPepIsPepHelpDialog, setOpenPepIsPepHelpDialog] = useState(false)

	const handleOpenPepIsPepHelpDialog = () => {
		setOpenPepIsPepHelpDialog(!openPepIsPepHelpDialog)
	}

	const renderPepAddition = () => {
		return (
			<div>
				<P>{intl.formatMessage(messages.PepHelpModalIntroduction)}</P>

				<StyledDialogSection>
					<H2>{intl.formatMessage(messages.PepHelpModalPepTitle)}</H2>
					<StyledNumberList>
						<P>
							<li>{intl.formatMessage(messages.PepHelpModalPepSubTitle1)}</li>
						</P>
						<P>
							<li>{intl.formatMessage(messages.PepHelpModalPepSubTitle2)}</li>
						</P>
						<P>
							<li>{intl.formatMessage(messages.PepHelpModalPepSubTitle3)}</li>
						</P>
						<P>
							<li>{intl.formatMessage(messages.PepHelpModalPepSubTitle4)}</li>
						</P>
						<P>
							<li>{intl.formatMessage(messages.PepHelpModalPepSubTitle5)}</li>
						</P>
						<P>
							<li>{intl.formatMessage(messages.PepHelpModalPepSubTitle6)}</li>
						</P>
						<P>
							<li>{intl.formatMessage(messages.PepHelpModalPepSubTitle7)}</li>
						</P>
						<P>
							<li>{intl.formatMessage(messages.PepHelpModalPepSubTitle8)}</li>
						</P>
					</StyledNumberList>
				</StyledDialogSection>

				<StyledDialogSection>
					<H2>{intl.formatMessage(messages.PepFamilyMemberHelpModalTitle)}</H2>
					<P>{intl.formatMessage(messages.PepFamilyMemberHelpModalSubtitle)}</P>
				</StyledDialogSection>

				<StyledDialogSection>
					<H2>{intl.formatMessage(messages.PepAssociateHelpModalTitle)}</H2>
					<P>{intl.formatMessage(messages.PepAssociateHelpModalIntro)}</P>
					<StyledNumberList>
						<P>
							<li>{intl.formatMessage(messages.PepAssociateHelpModalSubtitle1)}</li>
						</P>
						<P>
							<li>{intl.formatMessage(messages.PepAssociateHelpModalSubtitle2)}</li>
						</P>
						<P>
							<li>{intl.formatMessage(messages.PepAssociateHelpModalSubtitle3)}</li>
						</P>
					</StyledNumberList>
				</StyledDialogSection>
			</div>
		)
	}

	const renderHelpForPep = () => {
		if (openPepIsPepHelpDialog) {
			return (
				<div className="arrowPointForSection">
					<StyledInsideSection>{renderPepAddition()}</StyledInsideSection>
				</div>
			)
		}
	}

	// Alternatives from PEPStatus cms api documentation
	// https://api-docs.aerahost.com/development/index-internal-services.html#_cms-pepstatus
	const pepRoleOptions = {
		PEP: 'PEP',
		CLOSE_FAMILY: 'CloseFamily',
		KNOWN_COWORKER: 'KnownCoworker',
		NONE: 'None'
	}

	const getPepRoleTranslation = cell => {
		switch (cell) {
			case pepRoleOptions.PEP:
				return intl.formatMessage(messages.PepRolePEP)
			case pepRoleOptions.CLOSE_FAMILY:
				return intl.formatMessage(messages.PepRoleCloseFamily)
			case pepRoleOptions.KNOWN_COWORKER:
				return intl.formatMessage(messages.PepRoleKnownCoworker)
			case pepRoleOptions.NONE:
				return intl.formatMessage(messages.PepRoleNone)
			default:
				return cell
		}
	}

	const pepPositionOptions = {
		EMPLOYED: 'Employed',
		STUDENT: 'Student',
		SOCIALLY_SECURED: 'SociallySecured',
		OTHER: 'Other'
	}

	const getPepPositionTranslation = cell => {
		switch (cell) {
			case pepPositionOptions.EMPLOYED:
				return intl.formatMessage(messages.PepPositionEmployed)
			case pepPositionOptions.STUDENT:
				return intl.formatMessage(messages.PepPositionStudent)
			case pepPositionOptions.SOCIALLY_SECURED:
				return intl.formatMessage(messages.PepPositionSociallySecured)
			case pepPositionOptions.OTHER:
				return intl.formatMessage(messages.PepPositionOther)
			default:
				return cell
		}
	}

	const isPepHerself = () => {
		return pepRole === pepRoleOptions.PEP
	}

	const isNotPep = () => {
		return pepRole === pepRoleOptions.NONE
	}

	const knownCoworkerIsPep = () => {
		return pepRole === pepRoleOptions.KNOWN_COWORKER
	}

	const closeFamilyIsPep = () => {
		return pepRole === pepRoleOptions.CLOSE_FAMILY
	}

	const renderAdditionalPepInfo = () => {
		return isLoading ? (
			<InfoSkeleton />
		) : (
			<StyledAdditionalInfoWrapper>
				<StyledInfoWrapper>
					<H3>{intl.formatMessage(messages.PepRole)}</H3>
					<P>{(pepRole && getPepRoleTranslation(pepRole)) || '-'}</P>
				</StyledInfoWrapper>
				<StyledInfoWrapper>
					{knownCoworkerIsPep() ? (
						<H3>{intl.formatMessage(messages.CoworkerPepPosition)}</H3>
					) : closeFamilyIsPep() ? (
						<H3>{intl.formatMessage(messages.CloseFamilyPepPosition)}</H3>
					) : (
						<H3>{intl.formatMessage(messages.PepPosition)}</H3>
					)}
					<P>{(pepPosition && getPepPositionTranslation(pepPosition)) || '-'}</P>
				</StyledInfoWrapper>
				{!isPepHerself() && !isNotPep() && (
					<>
						<StyledInfoWrapper>
							{knownCoworkerIsPep() ? (
								<H3>{intl.formatMessage(messages.CoworkerPepFullName)}</H3>
							) : (
								<H3>{intl.formatMessage(messages.FamilyMemberPepFullName)}</H3>
							)}
							<P>{pepName || '-'}</P>
						</StyledInfoWrapper>
						<StyledInfoWrapper>
							<H3>{intl.formatMessage(messages.PepCountryOfPosition)}</H3>
							<P>{pepCountryOfPosition || '-'}</P>
						</StyledInfoWrapper>
					</>
				)}
			</StyledAdditionalInfoWrapper>
		)
	}

	const renderPepRadioButtons = () => {
		return (
			<>
				<StyledRadioWrapper>
					<RadioButtonField
						value={isPep}
						type="checkbox"
						label={intl.formatMessage(messages.PepIsPepLabel)}
						name="isPEP"
						id="isPEP"
						disabled
						isLoading={isLoading}
						helpIcon
						onHelpClick={() => handleOpenPepIsPepHelpDialog()}
						helpTextForButton={intl.formatMessage(messages.HelpForIsBuyingOverButton)}
						ariaButton={openPepIsPepHelpDialog}
					/>
				</StyledRadioWrapper>
				{renderHelpForPep()}
			</>
		)
	}

	return (
		<>
			<StyledSecondSection>
				<H2>{intl.formatMessage(messages.PepTitle)}</H2>
				{renderPepRadioButtons()}
				{isPep && renderAdditionalPepInfo()}
			</StyledSecondSection>
		</>
	)
}

PePInfo.propTypes = {
	user: PropTypes.object,
	isLoading: PropTypes.bool
}
