import React from "react"
import styled from "styled-components"
import { useIntl } from "react-intl"
import { HashLink as Link } from "react-router-hash-link"

import { Colors } from "../../../styles/Colors"
import messages from "../../../translations/messages"
import { useStore } from "../../../state/store"
import { getMenuItemPathByIndex } from "../../../utils/menuUtil"
import { focusVisibleBaseStyles } from "../../../styles/styledComponents"

//${(props) => props.menuIsOpen && `display: none`};
const StyledHyperLink = styled(Link)`
    height: 80px;
    min-width: 250px;
    max-width: 500px;
    background-color: ${Colors.aeraPrimaryDarkGrey};
    color: ${Colors.aeraWhite};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 20px;
    z-index: -1;
    :focus-visible {
        ${focusVisibleBaseStyles};
    }
`

export const BypassBlock = () => {
    const intl = useIntl()

    const { state } = useStore()

    const path = getMenuItemPathByIndex(state.activeLink, state.isAuthenticated)

    return (
        // eslint-disable-next-line jsx-a11y/tabindex-no-positive
        <StyledHyperLink tabIndex={1} to={`${path}/#mainBypassContent${state.activeLink}`}>
            {intl.formatMessage(messages.BypassBlockBody)}
        </StyledHyperLink>
    )
}
