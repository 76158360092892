import messages from '../translations/messages'
import {
	CONSENTS_PATH,
	CONTACT_PATH,
	CONTACT_PATH_LOG,
	CONTACT_PATH_LOG_LIST,
	DATA_PROCESSING_PATH,
	LOGIN_HOME_PATH,
	LOGOUT_HOME_PATH,
	PROFILE_PATH,
	SITEMAP_PATH,
	TRANSACTIONS_PATH
} from '../containers/Header/Menu/routes'

export const menuItemsIndex = {
	PROFILE_PAGE: '0',
	TRANSACTION_PAGE: '1',
	CONSENTS_PAGE: '2',
	PROCESSING_PAGE: '3',
	CONTACT_PAGE: '4-0',
	CONTACT_PAGE_LOG: '4-1',
	CONTACT_PAGE_LOG_DETAILS: '4-2',
	NOT_FOUND_PAGE: '5',
	LOGIN_PAGE: '6',
	SITEMAP_PAGE: '8',
	LOGOUT_PAGE: '9'
}

export const getMenuItemIndexByPath = (path, isAuthenticated) => {
	switch (path) {
		case LOGOUT_HOME_PATH:
			return menuItemsIndex.LOGOUT_PAGE
		case LOGIN_HOME_PATH:
			return menuItemsIndex.LOGIN_PAGE
		case PROFILE_PATH:
			return menuItemsIndex.PROFILE_PAGE
		case TRANSACTIONS_PATH:
			return menuItemsIndex.TRANSACTION_PAGE
		case CONSENTS_PATH:
			return menuItemsIndex.CONSENTS_PAGE
		case DATA_PROCESSING_PATH:
			return menuItemsIndex.PROCESSING_PAGE
		case CONTACT_PATH:
			return menuItemsIndex.CONTACT_PAGE
		case CONTACT_PATH_LOG_LIST:
			return menuItemsIndex.CONTACT_PAGE_LOG
		case CONTACT_PATH_LOG:
			return menuItemsIndex.CONTACT_PAGE_LOG_DETAILS
		case SITEMAP_PATH:
			return menuItemsIndex.SITEMAP_PAGE
		default:
			return isAuthenticated ? menuItemsIndex.PROFILE_PAGE : menuItemsIndex.LOGIN_PAGE
	}
}

export const getMenuItemPathByIndex = (menuItemIndex, isAuthenticated) => {
	switch (menuItemIndex) {
		case menuItemsIndex.LOGOUT_PAGE:
			return LOGOUT_HOME_PATH
		case menuItemsIndex.LOGIN_PAGE:
			return LOGIN_HOME_PATH
		case menuItemsIndex.PROFILE_PAGE:
			return PROFILE_PATH
		case menuItemsIndex.TRANSACTION_PAGE:
			return TRANSACTIONS_PATH
		case menuItemsIndex.CONSENTS_PAGE:
			return CONSENTS_PATH
		case menuItemsIndex.PROCESSING_PAGE:
			return DATA_PROCESSING_PATH
		case menuItemsIndex.CONTACT_PAGE:
			return CONTACT_PATH
		case menuItemsIndex.CONTACT_PAGE_LOG_LIST:
			return CONTACT_PATH_LOG_LIST
		case menuItemsIndex.CONTACT_PAGE_LOG:
			return CONTACT_PATH_LOG
		case menuItemsIndex.SITEMAP_PAGE:
			return SITEMAP_PATH
		default:
			return isAuthenticated ? PROFILE_PATH : LOGIN_HOME_PATH
	}
}

export const getPageIdByMenuItemIndex = pageIndex => {
	return `mainBypassContent${pageIndex}`
}

export const breakpointSizes = {
	smallDeviceBreakpointSize: 576,
	mediumDeviceBreakpointSize: 768,
	largeDeviceBreakpointSize: 992,
	extraLargeDeviceBreakpointSize: 1200
}

export const breakpointSizesInPixels = {
	smallDeviceBreakpointSize: breakpointSizes.smallDeviceBreakpointSize.toString() + 'px',
	mediumDeviceBreakpointSize: breakpointSizes.mediumDeviceBreakpointSize.toString() + 'px',
	largeDeviceBreakpointSize: breakpointSizes.largeDeviceBreakpointSize.toString() + 'px',
	extraLargeDeviceBreakpointSize: breakpointSizes.extraLargeDeviceBreakpointSize.toString() + 'px'
}

export const breakpoints = {
	smallDevice: `@media (min-width: ${breakpointSizesInPixels.smallDeviceBreakpointSize})`,
	mediumDevice: `@media (min-width: ${breakpointSizesInPixels.mediumDeviceBreakpointSize})`,
	largeDevice: `@media (min-width: ${breakpointSizesInPixels.largeDeviceBreakpointSize})`,
	extraLargeDevice: `@media (min-width: ${breakpointSizesInPixels.extraLargeDeviceBreakpointSize})`
}

export const getMenuOptions = intl => {
	const menuOptions = [
		{ label: intl.formatMessage(messages.ProfileMenuOption), link: PROFILE_PATH },
		{ label: intl.formatMessage(messages.TransactionsMenuOption), link: TRANSACTIONS_PATH },
		{ label: intl.formatMessage(messages.ConsentsMenuOption), link: CONSENTS_PATH },
		{ label: intl.formatMessage(messages.ProcessingMenuOption), link: DATA_PROCESSING_PATH },
		{ label: intl.formatMessage(messages.ContactMenuOption), link: CONTACT_PATH }
	]
	return menuOptions
}

export const getContactSubMenuOptions = intl => {
	const contactSubMenuOptions = [
		{ label: intl.formatMessage(messages.ContactMenuNewInquirySubOption), link: CONTACT_PATH },
		{ label: intl.formatMessage(messages.ContactMenuMyInquiriesSubOption), link: CONTACT_PATH_LOG_LIST }
	]

	return contactSubMenuOptions
}
