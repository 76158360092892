import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import styled, { css } from 'styled-components'
import { useLocation } from 'react-router-dom'

import messages from '../../translations/messages'
import { useStore } from '../../state/store'
import { StyledAlertSection, StyledContainer, StyledHeaderSection } from '../../styles/styledComponents'
import { breakpoints, getPageIdByMenuItemIndex, menuItemsIndex } from '../../utils/menuUtil'
import { getLoginUrl } from '../../utils/loginUtil'
import { Colors } from '../../styles/Colors'

import { setFetchedLoginUrl } from '../../state/actions'
import { setCodeVerifier } from '../../state/actions'
import { generateCodeVerifier } from '../../utils/loginUtil'
import { renderNetworkError } from '../../utils/feedbackUtil'
import { LoginButton } from '../../containers/LoginButton'
import { AeraAlert } from '../common/Feedback/AeraAlert'
import { Bold_34, Body_24, Bold_20, Bold_16 } from '../../styles/Typography'
import { Divider } from '@mui/material'
import { Chevron } from '../../assets/icons/Chevron'

const StyledMainContent = styled.div`
	display: flex;
	width: 100%;
	gap: 32px;
	padding: 24px;
	flex-direction: column;

	${breakpoints.smallDevice} {
		flex-direction: row;
		padding: 24px;
	}
	${breakpoints.largeDevice} {
		flex-direction: row;
		padding: 32px;
	}
`

const StyledDiv = styled.div`
	display: flex;
	width: 100%;
	padding: 24px;
	flex-direction: column;
	align-items: flex-start;
	gap: 24px;
	flex-shrink: 0;
	align-self: stretch;
	background-color: ${Colors.aeraPrimaryLightestGrey};
	border-radius: 24px;


	${breakpoints.smallDevice} {
		display: flex;
		width: 50%;
		padding: 24px;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
	}
	${breakpoints.largeDevice} {
		display: flex;
		width: 50%;
		padding: 24px;
		flex-direction: column;
		align-items: flex-start;
		gap: 24px;
		flex-shrink: 0;
	}
`

const StyledDiv2 = styled.div`
	width: 100%;
	flex-shrink: 1;

	${breakpoints.smallDevice} {
		width: 100%;
	}
	${breakpoints.largeDevice} {
		width: 50%;
	}
`

const StyledLogInButton = styled(LoginButton)`
	display: block;
`

const StyledLogInButtonWrapper = styled.div`
	width: 100%;

	${breakpoints.smallDevice} {
		width: 140px;
		height: 24px;
	}
`

const StyledDivider = styled(Divider)`
	padding-top: 61px;
	padding-bottom: 24px;
	padding-left: 24px;
	padding-right: 24px;
	width: 100%;
	display: flex;
	align-items: center;
	gap: 6px;
	align-self: stretch;

	${breakpoints.smallDevice} {
		display: flex;
		align-items: center;
		gap: 6px;
	}
	${breakpoints.largeDevice} {
		display: flex;
		align-items: center;
		gap: 24px;
	}
`

const StyledDropdownContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 16px 24px;
	gap: 24px;

	border-radius: 48px;
	background-color: ${Colors.aeraPrimaryLightestGrey};
	background-clip: content-box;

	${breakpoints.smallDevice} {
		display: inline-flex;
	}

	${breakpoints.largeDevice} {
		display: flex;
	}
`

const StyledChevron = styled(Chevron)`
	width: 14px;
	height: 7px;
	padding-left: 24px;
	transition: transform 0.3s ease;
`

const StyledIconContainer = styled.div`
	float: right;
`

const StyledDropdown = styled.details`
	display: flex;
	width: 100%;
	padding: 16px 24px;
	flex-direction: column;
	align-items: flex-start;
	gap: 16px;
	align-self: stretch;

	border-radius: 24px;
	background: ${Colors.aeraPrimaryLightestGrey};

	summary {
		list-style: none;
		align-items: stretch;
	}

	&[open] {
		background-color: #f1f0f3;
	}
	
	&[open] ${StyledIconContainer} {
		transform: rotate(180deg);
	}
  }
`

const StyledBold_16Light = styled(Bold_16)`
	font-family: Inter;
	font-size: 16px;
	font-weight: 400;
	line-height: 19.36px;
	text-align: left;

	display: flex;
	padding-top: 16px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 16px;
`


export const LoginPage = () => {
	const intl = useIntl()
	const { state, dispatch } = useStore()
	const location = useLocation()

	const [verifier, setVerifier] = useState(null)

	const [showNotCustomerFeedback, setShowNotCustomerFeedback] = useState(location?.state?.showNotCustomerFeedback)
	const [showNetworkError, setShowNetworkError] = useState(location?.state?.showNetworkError)
	const [showUserDeletionStarted, setShowUserDeletionStarted] = useState(location?.state?.showUserDeletionStarted)
	const userCancelled = location?.state?.userCancelled

	const pageId = getPageIdByMenuItemIndex(menuItemsIndex.LOGIN_PAGE)

	useEffect(() => {
		if (location) {
			/*if (showNotCustomerFeedback) {
				window.history.replaceState({}, document.showNotCustomerFeedback)
			} else */
			if (showNetworkError) {
				window.history.replaceState({}, document.showNetworkError)
			} else if (showUserDeletionStarted) {
				window.history.replaceState({}, document.showUserDeletionStarted)
			}
		}
	}, [location, showNotCustomerFeedback, showNetworkError])

	useEffect(() => {
		const oldVerifier = state.oldCodeVerifier
		let newVerifier = generateCodeVerifier()
		if (newVerifier === oldVerifier) {
			newVerifier = generateCodeVerifier()
		}
		setVerifier(newVerifier)
		dispatch(setCodeVerifier(newVerifier))
	}, [showNotCustomerFeedback, showNetworkError, showUserDeletionStarted, userCancelled])

	useEffect(() => {
		const fetchData = async () => {
			const url = await getLoginUrl(verifier)
			dispatch(setFetchedLoginUrl(url))
		}
		fetchData()
	}, [verifier])

	const removeErrorFeedback = () => {
		/*if (showNotCustomerFeedback) {
			window.history.replaceState({}, document.showNotCustomerFeedback)
			setShowNotCustomerFeedback(false)
		} else */
		if (showNetworkError) {
			window.history.replaceState({}, document.showNetworkError)
			setShowNetworkError(false)
		} else if (showUserDeletionStarted) {
			window.history.replaceState({}, document.showUserDeletionStarted)
			setShowUserDeletionStarted(false)
		}
	}

	const renderFeedback = () => {
		return (
			<StyledAlertSection>
				{showNotCustomerFeedback && (
					<AeraAlert severity="warning" handleClose={() => removeErrorFeedback()}>
						{intl.formatMessage(messages.FeedbackNotCustomerWarning)}
					</AeraAlert>
				)}
				{showNetworkError && renderNetworkError(() => removeErrorFeedback(), intl.formatMessage(messages.FeedbackLoginNetworkError), intl)}
				{showUserDeletionStarted && <AeraAlert handleClose={() => removeErrorFeedback()}>{intl.formatMessage(messages.FeedbackUserDeletionStarted)}</AeraAlert>}
			</StyledAlertSection>
		)
	}

	const renderFAQ = () => {
		var details = [...document.querySelectorAll('details')];
		// Add the onclick listeners.
		details.forEach((detail) => {
			detail.addEventListener("toggle", () => {
				if (detail.open) {
					setTargetDetail(detail)

				};
			});
		});

		// Close all the details that are not targetDetail.
		function setTargetDetail(targetDetail) {
			details.forEach((detail) => {
				if (detail !== targetDetail) {
					detail.open = false;
				}
			});
		}

		return (
			<StyledDropdownContainer>
				<StyledDropdown>
					<summary>
						<Bold_16>Hvem er Aera Payment & Identification? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>Aera er et norsk selskap som ble opprettet av Coop og NorgesGruppen sammen i 2016. Selskapet er opprettet for å ivareta varehandelsaktørenes interesser innen betalingsformidling, samt for å opprettholde dagens høye effektivitet og lave kostnadsnivå for betaling i Norge. Som forbruker har du med stor sannsynlighet brukt flere av tjenestene våre. Vi leverer betalingsterminaler til blant annet dagligvarebutikkene til Coop og NorgesGruppen samt bokhandlerne til Norli. Vi leverer også teknologiske tjenester til Coopay og CoopKey.</StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Hva er Aera kontobetaling? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>Aera kontobetaling er en betalingstjeneste som leveres av Aera gjennom våre kunder sine mobil-betalingsapplikasjoner. Kontobetaling er en måte å gjennomføre betalinger på, direkte fra konto-til konto. Dette er annerledes enn for vanlige kortbetalinger hvor det er flere aktører involvert. Kontobetaling ble muliggjort av betalingstjenestedirektivet (PSD2) som trådte i kraft i Norge i 2019. </StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Hvordan kan jeg slette tjenesten? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>Du kan enkelt slette tjenesten inne i Forbrukerportalen. Innloggingsknappen finner du på våre nettsider. </StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Hvorfor måtte jeg bli kunde av Aera for å ta i bruk tjenesten? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>Aera kan levere kontobetalingstjenesten fordi vi er regulert som et finansforetak av det norske Finanstilsynet. En sentral del av tjenesten er å gjennomføre betalinger på dine vegne. Da er vi lovpålagt å gjøre tilstrekkelige kontroller slik at vi vet hvem det er vi gjennomfører betalinger på vegne av. </StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Hva er kundetiltak? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>Hvitvaskingsloven pålegger oss som finansinstitusjon å innhente informasjon om våre kunder, dette kalles kundetiltak. Vi må blant annet kjenne til formålet med kundeforholdet og vite hvordan du som kunde bruker våre tjenester. Dette gjør vi gjennom å stille spørsmål til alle våre kunder. Om du ikke besvarer spørsmålene kan du ikke benytte våre tjenester. </StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Hvorfor må jeg svare på spørsmål? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>Myndighetene krever at vi har kunnskap om hvordan våre kunder bruker tjenestene, og krever at informasjonen skal registreres og oppdateres jevnlig. En viktig del av vårt samfunnsansvar er at vi skal verne om våre kunder, vår bedrift og samfunnet fra kriminell virksomhet som hvitvasking og terrorfinansiering. Aera er pålagt å jevnlig oppdatere kundeinformasjon, og vil derfor be deg årlig oppdatere informasjonen. </StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Hva skjer hvis jeg ikke svarer på spørsmålene? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>Dersom du ikke svarer på spørsmålene når du skal ta i bruk tjenesten, har vi ikke mulighet til å opprette kundeforhold med deg. Dersom du ikke svarer på spørsmålene ved årlig oppdatering av kundeinformasjon, vil tjenesten bli sperret frem til vi har fått din besvarelse. </StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Hvorfor trenger dere informasjon om meg og hvordan jeg bruker tjenesten? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>I henhold til hvitvaskingsloven er vi forpliktet til å samle inn informasjon om våre kunder og deres finansielle transaksjoner. Vi vet at de aller fleste kundene våre er ærlige, men for å kunne avdekke de som ikke er det, ber vi alle kunder om å svare på de samme spørsmålene. </StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Hva er en PEP (Politisk Eksponert Person)? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>En PEP er definert som en som innehar eller i løpet av det siste året har innehatt høytstående offentlig verv eller annen stilling i en annen stat enn Norge. Det samme gjelder slike personers nærmeste familiemedlemmer og kjente medarbeidere. </StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Hvem er nærmeste familiemedlemmer til en PEP? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>
						<li>Foreldre.</li>
						<li>Ektefelle, samboer, registrert partner.</li>
						<li>Barn, barns ektefelle eller registrert partner/samboer.</li>
					</StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Hva menes med kjent medarbeider til en PEP? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>
						Kjent medarbeider defineres som personer som har, eller nylig har hatt: 
						<li>Nær forretningsforbindelse til en PEP.</li>
						<li>Reelle rettigheter til, eller er medeier av formue sammen med en PEP.</li>
						<li>Reelle rettigheter til, eller er medeier av formue sammen med en PEP, der hensikten er å gi fordel til PEP. </li>
					</StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Gjelder deres taushetsplikt all informasjon jeg leverer til enhver tid? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>Taushetsplikten for Aera sine ansatte er regulert i finansforetaksloven, som omfatter all informasjon om ditt kundeforhold med Aera. Dette gjelder informasjon uavhengig om den er gitt skriftlig eller muntlig. Unntak fra taushetsplikten gjelder bare forhold som er regulert ved lov, og dette vil f.eks. kunne gjelde spesifikk informasjon til myndighetene i forbindelse med rapportering av straffbare handlinger. Informasjonen er kun tilgjengelig for de hos Aera som arbeider med fagområdet, og er heller ikke tilgjengelig for tredjeparter. </StyledBold_16Light>
				</StyledDropdown>
				<StyledDropdown>
					<summary>
						<Bold_16>Har dere lov til å be om denne informasjonen fra kundene deres? </Bold_16>
						<StyledIconContainer> <StyledChevron /> </StyledIconContainer>
					</summary>
					<StyledBold_16Light>Aera følger gjeldende lovverk ved innsamling av personlig informasjon. Norsk lov krever at vi innhenter personopplysninger for å oppnå tilstrekkelig kundekunnskap. Kravene for innhenting av informasjon gjelder alle våre kunder. Informasjonen vi har om deg behandles konfidensielt og i tråd med lover om taushetsplikt og personvern. </StyledBold_16Light>
				</StyledDropdown>
			</StyledDropdownContainer>
		)
	}

	return (
		<StyledContainer aria-labelledby={pageId} $isLoggedIn={state.isAuthenticated}>
			{renderFeedback()}

			<StyledMainContent>
				<StyledDiv>
					<Bold_34>Innrullert deg i Aera kontobetaling gjennom en av våre partnere?</Bold_34>
					<StyledLogInButtonWrapper><StyledLogInButton /></StyledLogInButtonWrapper>
				</StyledDiv>
				<StyledDiv2>
					<Body_24>Vi er opptatt av at du, som forbruker, skal ha full oversikt over hele ditt kundeforhold med Aera. <br /><br /> Derfor har vi samlet alt i en egen forbrukerportal. Her får du oversikt over alle registrerte data, alle transaksjoner du har gjennomført med tjenesten, og alle samtykker du har gitt. Henvendelser til vår kundeservices rettes også gjennom portalen. <br /><br /> Vi har også forsøkt å besvare spørsmål som ofte stilles , i vår FAQ.</Body_24>
				</StyledDiv2>
			</StyledMainContent>

			<StyledDivider >
				<Bold_20>FAQ / Ofte stilte spørsmål</Bold_20>
			</StyledDivider>

			{renderFAQ()}
		</StyledContainer>
	)
}
