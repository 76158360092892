import React, { useEffect, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { Helmet } from "react-helmet-async"
import styled from "styled-components"

import { StyledAlertSection, StyledContainer, StyledHeaderSection, StyledMetadataSection } from "../../styles/styledComponents"
import messages from "../../translations/messages"
import { PersonalInfo } from "./PersonalInfo"
import { PePInfo } from "./PePInfo"
import { backend } from "../../api/backend"
import { getPageIdByMenuItemIndex, menuItemsIndex } from "../../utils/menuUtil"
import { STORAGE_PROFILE_LAST_UPDATED, STORAGE_PROFILE_USER } from "../../utils/storageUtil"
import { renderNetworkError, renderNoInformationError, renderTimeoutError } from "../../utils/feedbackUtil"
import { fetchLoginUrlFromTimeout, useStore } from "../../state/store"
import { generateCodeVerifier, getLoginUrl } from "../../utils/loginUtil"
import { setActiveLink, setCodeVerifier, setFetchedLoginFromTimeoutUrl, setPath } from "../../state/actions"
import { PROFILE_PATH } from "../../containers/Header/Menu/routes"
import { responseCodes, statusCodes } from "../../utils/networkUtil"
import { convertTimeStampToLocalTimeZone } from "../../utils/formatUtil"
import { H1, P } from "../../styles/Typography"

export const StyledInfoWrapper = styled.div`
    max-width: 450px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 550px) {
        flex-direction: column;
        align-items: start;
        justify-content: start;
    }
`

export const ProfilePage = () => {
    const intl = useIntl()
    const { state, dispatch } = useStore()
    const pageId = getPageIdByMenuItemIndex(menuItemsIndex.PROFILE_PAGE)

    const saveLastUpdatedInSessionStorage = (lastUpdated) => {
        sessionStorage.setItem(STORAGE_PROFILE_LAST_UPDATED, JSON.stringify(lastUpdated))
    }

    const getUserDataFromSessionStorage = () => {
        return JSON.parse(sessionStorage.getItem(STORAGE_PROFILE_USER))
    }

    const saveUserDataInSessionStorage = (user) => {
        sessionStorage.setItem(STORAGE_PROFILE_USER, JSON.stringify(user))
    }

    const getLastUpdatedFromSessionStorage = () => {
        return JSON.parse(sessionStorage.getItem(STORAGE_PROFILE_LAST_UPDATED))
    }

    const [userInfoIsLoading, setUserInfoIsLoading] = useState(false)
    const [user, setUser] = useState(getUserDataFromSessionStorage() || null)

    const [tokenExpired, setTokenExpired] = useState(false)
    const [noUserInfo, setNoUserInfo] = useState(false)
    const [networkError, setNetworkError] = useState(false)

    const [lastUpdated, setLastUpdated] = useState(getLastUpdatedFromSessionStorage() || null)

    const [verifier, setVerifier] = useState(null)

    useEffect(() => {
        dispatch(setPath(PROFILE_PATH))
        dispatch(setActiveLink(menuItemsIndex.PROFILE_PAGE))
    }, [])

    useEffect(() => {
        if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
            const oldVerifier = state.oldCodeVerifier
            let verifier = generateCodeVerifier()
            if (verifier === oldVerifier) {
                verifier = generateCodeVerifier()
            }
            setVerifier(verifier)
            dispatch(setCodeVerifier(verifier))
        }
    }, [tokenExpired])

    useEffect(() => {
        if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
            const fetchData = async () => {
                const url = await getLoginUrl(verifier)
                dispatch(setPath(PROFILE_PATH))
                dispatch(setActiveLink(menuItemsIndex.PROFILE_PAGE))
                dispatch(setFetchedLoginFromTimeoutUrl(url))
            }
            fetchData()
        }
    }, [verifier])

    const renderAlerts = () => {
        /*<div role="region" aria-live="polite"></div>*/
        return (
            <StyledAlertSection>
                {noUserInfo && renderNoInformationError(() => setNoUserInfo(false), intl)}
                {tokenExpired && renderTimeoutError(() => setTokenExpired(false), null, intl)}
                {networkError && renderNetworkError(() => setNetworkError(false), null, intl)}
            </StyledAlertSection>
        )
    }

    const hasMountedRef = useRef(false)
    useEffect(() => {
        const controller = new AbortController()
        if (!hasMountedRef.current) {
            setUserInfoIsLoading(true)
            backend
                .get("/consumer", { signal: controller.signal })
                .then((response) => {
                    const user = response.data
                    setUser(user)
                    saveUserDataInSessionStorage(user)
                    if (user === null) {
                        setNoUserInfo(true)
                    }
                    const timeStamp = response.data.responseInfo?.timeStamp
                    const timeStampTimeZone = convertTimeStampToLocalTimeZone(timeStamp)
                    setLastUpdated(timeStampTimeZone)
                    saveLastUpdatedInSessionStorage(timeStampTimeZone)
                })
                .catch((error) => {
                    const { response = {} } = error
                    //console.warn("Error retrieving user information: ", response)
                    if (error.name !== "CanceledError") {
                        if (response?.status === statusCodes.UNAUTHORIZED || response.data?.responseInfo?.responseCode === responseCodes.ID_TOKEN_EXPIRED) {
                            setTokenExpired(true)
                        } else if (response?.status === statusCodes.NOT_FOUND) {
                            setNoUserInfo(true)
                        } else {
                            setNetworkError(true)
                        }
                    }
                })
                .finally(() => {
                    setUserInfoIsLoading(false)
                })
        } else {
            return () => controller.abort()
        }
    }, [])

    return (
        <StyledContainer aria-labelledby={pageId}>
            <Helmet>
                <title>{intl.formatMessage(messages.ProfileMenuOption)}</title>
            </Helmet>
            {renderAlerts()}
            <StyledHeaderSection>
                <H1 id={pageId}>{intl.formatMessage(messages.Profile)}</H1>
                <P>
                    {intl.formatMessage(messages.ProfileBody)} {intl.formatMessage(messages.ProfileContactIfIncorrectInfo)}
                </P>
                <StyledMetadataSection>
                    {intl.formatMessage(messages.LastUpdated)}: {lastUpdated || "-"}
                </StyledMetadataSection>
            </StyledHeaderSection>
            <div>
                <PersonalInfo user={user} isLoading={userInfoIsLoading} />
                <PePInfo user={user} isLoading={userInfoIsLoading} />
            </div>
        </StyledContainer>
    )
}
