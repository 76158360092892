import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

// TODO: Focus-visible override and not inline styling
const StyledSVG = styled.svg``

const AeraLogo = ({ width, idPrefix, ...props }) => (
    <StyledSVG
        className="prefix__icon"
        height={width ? width * 0.26 : 26}
        width={width ? width : 100}
        id={idPrefix + "prefix__Group_8"}
        data-name="Group 8"
        viewBox="0 0 97.263 26"
        {...props}
    >
        <defs>
            <style>{".prefix__cls-1{fill:#00b040}.prefix__cls-2{fill:#007a3d}"}</style>
        </defs>
        <path
            id={idPrefix + "prefix__Path_1"}
            data-name="Path 1"
            className="prefix__cls-1"
            d="M67.453 1.813V.2H61v12.336a13.129 13.129 0 016.453-10.723"
            transform="translate(-3.117 -.01)"
        />
        <path
            id={idPrefix + "prefix__Rectangle_1"}
            data-name="Rectangle 1"
            className="prefix__cls-1"
            transform="translate(57.978 12.526)"
            d="M0 0h6.453v13.38H0z"
        />
        <path
            id={idPrefix + "prefix__Path_2"}
            data-name="Path 2"
            className="prefix__cls-1"
            d="M74.442 0A13.4 13.4 0 0067.8 1.8v10.726c0-4.175 2.752-6.073 6.263-6.358h.759V0h-.38"
            transform="translate(-3.464)"
        />
        <path
            id={idPrefix + "prefix__Path_3"}
            data-name="Path 3"
            className="prefix__cls-2"
            d="M61.1 12.623h6.453V1.9A12.7 12.7 0 0061.1 12.623"
            transform="translate(-3.122 -.097)"
        />
        <path
            id={idPrefix + "prefix__Path_4"}
            data-name="Path 4"
            className="prefix__cls-1"
            d="M20.7 24.992v1.613h6.453V13.7A13 13 0 0120.7 24.992"
            transform="translate(-1.058 -.7)"
        />
        <path
            id={idPrefix + "prefix__Path_5"}
            data-name="Path 5"
            className="prefix__cls-1"
            d="M20.7.2v1.613a13.161 13.161 0 016.453 11.2V.2z"
            transform="translate(-1.058 -.01)"
        />
        <path
            id={idPrefix + "prefix__Path_6"}
            data-name="Path 6"
            className="prefix__cls-1"
            d="M13 20.022a6.927 6.927 0 010-13.854 7.072 7.072 0 016.642 4.839V1.8A12.877 12.877 0 0013 0a13 13 0 100 26 13.4 13.4 0 006.642-1.8v-9.112A6.947 6.947 0 0113 20.022"
        />
        <path
            id={idPrefix + "prefix__Path_7"}
            data-name="Path 7"
            className="prefix__cls-2"
            d="M20.7 1.9v9.109a7.8 7.8 0 010 4.175v9.109a13.161 13.161 0 006.453-11.2A12.957 12.957 0 0020.7 1.9"
            transform="translate(-1.058 -.097)"
        />
        <path
            id={idPrefix + "prefix__Path_8"}
            data-name="Path 8"
            className="prefix__cls-1"
            d="M43.6 6.073a7.1 7.1 0 016.073 3.511H37.527A6.98 6.98 0 0143.6 6.073M43.6 0a13 13 0 000 26 13.138 13.138 0 0012.146-8.35h-7.022a6.923 6.923 0 01-11.672-2.372H56.41A18.427 18.427 0 0056.6 13a13.02 13.02 0 00-13-13"
            transform="translate(-1.563)"
        />
        <path
            id={idPrefix + "prefix__Path_9"}
            data-name="Path 9"
            className="prefix__cls-1"
            d="M95.7.2v1.613a13.161 13.161 0 016.453 11.2V.2z"
            transform="translate(-4.89 -.01)"
        />
        <path
            id={idPrefix + "prefix__Path_10"}
            data-name="Path 10"
            className="prefix__cls-1"
            d="M95.7 24.992v1.613h6.453V13.7A13.408 13.408 0 0195.7 24.992"
            transform="translate(-4.89 -.7)"
        />
        <path
            id={idPrefix + "prefix__Path_11"}
            data-name="Path 11"
            className="prefix__cls-1"
            d="M88 20.022a6.927 6.927 0 110-13.854 7.072 7.072 0 016.642 4.839V1.8A12.877 12.877 0 0088 0a13 13 0 000 26 13.4 13.4 0 006.642-1.8v-9.112A7.036 7.036 0 0188 20.022"
            transform="translate(-3.832)"
        />
        <path
            id={idPrefix + "prefix__Path_12"}
            data-name="Path 12"
            className="prefix__cls-2"
            d="M95.7 1.9v9.109a7.8 7.8 0 010 4.175v9.109a13.161 13.161 0 006.453-11.2A13.372 13.372 0 0095.7 1.9"
            transform="translate(-4.89 -.097)"
        />
        <path
            id={idPrefix + "prefix__Path_13"}
            data-name="Path 13"
            className="prefix__cls-2"
            d="M37.522 10.2h-6.548a12.829 12.829 0 00-.474 2.752 14.846 14.846 0 00.19 2.942h6.263a6.126 6.126 0 01-.38-1.8 7.175 7.175 0 01.949-3.891"
            transform="translate(-1.558 -.521)"
        />
    </StyledSVG>
)

AeraLogo.propTypes = {
    idPrefix: PropTypes.string.isRequired,
    width: PropTypes.number
}

export default AeraLogo
