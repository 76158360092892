import React, { useRef } from 'react'
import styled from 'styled-components'

import { MenuOption } from './MenuOption'
import { getMenuIsOpen, useStore } from '../../../state/store'
import { toggleMenu } from '../../../state/actions'
import { LoginButton } from '../../LoginButton'
import { useClickOutsideHandler } from '../../../hooks/useOutsideAlerter'
import { HamburgerIconContainer } from '../HamburgerIconContainer'
import { getContactSubMenuOptions, getMenuOptions, breakpoints } from '../../../utils/menuUtil'
import { StyledListItem } from './MenuOption'
import { Colors } from '../../../styles/Colors'
import { CONTACT_PATH } from './routes'
import { useFocusInside } from '../../../hooks/useFocusInside'
import { useIntl } from 'react-intl'

const StyledNavBar = styled.nav``

const StyledUnorderedList = styled.ul`
	display: flex;
	list-style: none;

	${props => !props.$colMenuOpen && `display: none;`}
	flex-direction: column;
	align-items: start;
	justify-content: start;
	position: absolute;
	top: 119px;
	left: 0;
	background-color: ${Colors.aeraWhite};
	width: 100%;
	height: 90vh;
	overflow-x: hidden;
	overflow-y: scroll;
	padding-bottom: 160px;
	${breakpoints.extraLargeDevice} {
		${props => !props.$colMenuOpen && `display: flex;`}
		min-width: 700px;
		flex-direction: row;
		padding-bottom: 0;
		height: 119px;
		position: relative;
		top: 0;
		overflow: visible;
	}
	:hover {
		font-weight: 500;
	}
`

const StyledLoginElem = styled.div`
	&:hover {
		background-color: ${Colors.aeraPrimaryLightGrey};
		border-left: 4px solid ${Colors.aeraPrimaryDarkGreen};
	}
	${breakpoints.extraLargeDevice} {
		display: none;
	}
`

export const Menu = () => {
	const intl = useIntl()
	const { state, dispatch } = useStore()
	const wrapperRef = useRef(null)

	const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
	const menu = document.getElementById('main-navigation')
	let firstFocusableElement = menu?.querySelectorAll(focusableElements)[0]
	const focusableContent = menu?.querySelectorAll(focusableElements)
	const lastFocusableElement = focusableContent?.[focusableContent?.length - 1]

	useFocusInside(firstFocusableElement, lastFocusableElement, state.menuIsOpen)

	const handleClickOutside = async () => {
		document.getElementById('menuButton').onclick = () => {
			if (menuIsOpen) dispatch(toggleMenu())
			return
		}
		const menuIsOpen = await getMenuIsOpen()
		if (menuIsOpen) dispatch(toggleMenu())
	}
	useClickOutsideHandler(wrapperRef, handleClickOutside)

	const isSubMenu = link => {
		return link === CONTACT_PATH
	}

	return (
		state.isAuthenticated && (
			<StyledNavBar id="main-navigation" $colMenuOpen={state.menuIsOpen} ref={wrapperRef} aria-label="Main" role="navigation">
				{state.isAuthenticated && <HamburgerIconContainer />}
				<StyledUnorderedList $colMenuOpen={state.menuIsOpen}>
					{getMenuOptions(intl)?.map((menuOption, index) => {
						return (
							<MenuOption
								key={index}
								label={menuOption.label}
								link={menuOption.link}
								id={index}
								subMenu={isSubMenu(menuOption.link)}
								subMenuItems={getContactSubMenuOptions(intl)}
							/>
						)
					})}
					<StyledListItem>
						<StyledLoginElem>
							<LoginButton />
						</StyledLoginElem>
					</StyledListItem>
				</StyledUnorderedList>
			</StyledNavBar>
		)
	)
}
