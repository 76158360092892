import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout'
import LoginIcon from '@mui/icons-material/Login'
import styled, { css } from 'styled-components'

import { fetchLoginUrl, useStore } from '../../state/store'
import { setActiveLink, setPath, setAuthenticated, setAuthToken, setRefreshToken, removePersonalData, toggleMenu, setFetchedLoginUrl, setCodeVerifier } from '../../state/actions'
import { Colors } from '../../styles/Colors'
import messages from '../../translations/messages'
import { LOGIN_HOME_PATH, PROFILE_PATH } from '../Header/Menu/routes'
import { menuItemsIndex } from '../../utils/menuUtil'
import { P, Bold_20 } from '../../styles/Typography'
import { generateCodeVerifier, getLoginUrl } from '../../utils/loginUtil'
import { Button } from '@mui/material'
import { backend } from '../../api/backend'

const StyledLoginWrapper = styled.div`
	padding: 40px 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

const IconBaseStyle = css`
	margin-right: 7px;
`

const StyledLogoutIcon = styled(LogoutIcon)`
	${IconBaseStyle};
`

const StyledPrimaryButton = styled.button`
	width: 100%;
	padding: 8px 24px;

	justify-content: center;
	align-items: center;
	align-self: stretch;
	gap: 8px;

	border-radius: 4px;
	border: none;
	background: ${Colors.aeraPrimaryDarkGreen};
`

const StyledBold_20 = styled(Bold_20)`
	color: ${Colors.aeraWhite};
`

const StyledLink = styled(Link)`
	:active {
		color: ${Colors.aeraBlack};
	}
`

const StyledParagraph = styled(P)`
	width: 100%;
	text-align: left;
	&:hover {
		font-weight: 500;
	}
`

export const LoginButton = () => {
	const intl = useIntl()
	const { state, dispatch } = useStore()

	const logOutUser = () => {
		dispatch(setAuthenticated(false))
		dispatch(setAuthToken(null))
		dispatch(setRefreshToken(null))
		dispatch(removePersonalData())
		if (state.menuIsOpen) {
			dispatch(toggleMenu())
		}
	}

	const handleClick = () => {
		dispatch(setActiveLink(menuItemsIndex.LOGIN_PAGE))
		dispatch(setPath(LOGIN_HOME_PATH))

		if (state.isAuthenticated) {
			backend
				.get("/oidc/logout")
				.then(response => {
					console.log(response)
					logOutUser()
				})
				.catch(error => {
					console.warn('Error during log out: ', error)
					logOutUser()
				})
		}
	}

	const handleLoginClick = () => {
		/*
		const oldVerifier = state.oldCodeVerifier
		let newVerifier = generateCodeVerifier()
		if (newVerifier === oldVerifier) {
			newVerifier = generateCodeVerifier()
		}
		setVerifier(newVerifier)
		dispatch(setCodeVerifier(newVerifier))

		const fetchData = async () => {
			const url = await getLoginUrl(newVerifier)
			dispatch(setFetchedLoginUrl(url))
		}
		fetchData()
		*/
		dispatch(setActiveLink(menuItemsIndex.PROFILE_PAGE))
		dispatch(setPath(PROFILE_PATH))

		window.location.href = fetchLoginUrl()
	}

	function renderLogin() {
		return (
			<StyledPrimaryButton aria-label={intl.formatMessage(messages.LogIn)} onClick={() => handleLoginClick()}>
				<StyledBold_20 aria-hidden="true"> Logg inn {/*intl.formatMessage(messages.LogIn)*/} </StyledBold_20>
			</StyledPrimaryButton>
		)
	}

	function renderLogout() {
		return (
			<StyledLink to="" aria-label={intl.formatMessage(messages.LogOut)} onClick={() => handleClick()}>
				<StyledLoginWrapper aria-hidden="true">
					<StyledLogoutIcon style={{ fill: Colors.aeraPrimaryDarkGrey }} fontSize="small" aria-hidden="true" />
					<StyledParagraph aria-hidden="true">{intl.formatMessage(messages.LogOut)}</StyledParagraph>
				</StyledLoginWrapper>
			</StyledLink>
		)
	}

	return <>{state.isAuthenticated ? renderLogout() : renderLogin()}</>
}
