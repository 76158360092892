import styled, { css } from 'styled-components'
import { breakpoints } from '../utils/menuUtil'
import { Colors } from './Colors'

const headerBaseStyle = css`
	color: #222;
	font-weight: 400;
	letter-spacing: 0.01rem;
	font-family: 'Inter-UI', Inter, Arial, sans-serif;
`
export const H1 = styled.h1`
	/*word-wrap: break-all;
    white-space: normal;*/
	word-break: break-word !important;
	white-space: normal !important;
	${headerBaseStyle};
	font-size: 1.6rem;
	${breakpoints.smallDevice} {
		font-size: 1.8rem;
	}
	${breakpoints.largeDevice} {
		font-size: 2rem;
	}
`

export const H2 = styled.h2`
	${headerBaseStyle};
	margin-bottom: 15px;
	font-size: 1.3rem;
	${breakpoints.smallDevice} {
		font-size: 1.4rem;
	}
	${breakpoints.largeDevice} {
		font-size: 1.5rem;
	}
`

export const H3 = styled.h3`
	${headerBaseStyle};
	font-size: 0.9rem;
	${breakpoints.smallDevice} {
		font-size: 0.95rem;
	}
	${breakpoints.largeDevice} {
		font-size: 1.05rem;
	}
`

/*"PlantinMTStd-light", Plantin, "Plantin", "Times New Roman", serif;*/

const paragraphBaseStyle = css`
	color: ${Colors.aeraPrimaryDarkGrey};
	font-weight: 400;
	letter-spacing: 0;
	font-size: 0.8rem;
	${breakpoints.smallDevice} {
		font-size: 0.9rem;
	}
	${breakpoints.largeDevice} {
		font-size: 1rem;
	}
`

export const P = styled.p`
	${paragraphBaseStyle};
`

export const A = styled.a`
	${paragraphBaseStyle};
	color: ${Colors.aeraLinkColor};
`

export const P2 = styled.p`
	color: ${Colors.aeraPrimaryDarkGrey};
	font-weight: 400;
	letter-spacing: 0;
	font-size: 0.8rem;
	${breakpoints.smallDevice} {
		font-size: 0.9rem;
	}
	${breakpoints.largeDevice} {
		font-size: 1rem;
	}
`

export const Label = styled.label`
	${props => props.errorMessage && `color: ${Colors.aeraError}`}
	font-size: 0.9rem;
	${breakpoints.smallDevice} {
		font-size: 1rem;
	}
	${breakpoints.largeDevice} {
		font-size: 1.2rem;
	}
`

export const Bold_34 = styled.h1`
	color: ${Colors.aeraBlack};

	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;

	margin-bottom: 24px;

	${breakpoints.smallDevice} {
		font-size: 34px;
	}
	${breakpoints.largeDevice} {
		font-size: 34px;
	}
`

export const Body_24 = styled.h2`
	color: ${Colors.aeraBlack};

	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	${breakpoints.smallDevice} {
		font-size: 24px;
	}
	${breakpoints.largeDevice} {
		font-size: 24px;
	}
`

export const Bold_20 = styled.label`
	pointer-events: none;
	color: ${Colors.aeraPrimaryDarkGreen};

	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	gap: 16px;

	${breakpoints.smallDevice} {
		font-size: 16px;
	}
	${breakpoints.largeDevice} {
		font-size: 20px;
	}
`

export const Bold_16 = styled.label`
	pointer-events: none;
	color: ${Colors.black};

	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`

//overflow-x: hidden;
