export const formatDateTime = dateTime => {
	return dateTime ? dateTime.substring(0, 10) + ' ' + dateTime.substring(11, 16) : null
}

export const formatDate = date => {
	return date ? date.substring(0, 10) : null
}

export const convertTimeZone = (dateTime, timeZone = 'no-NO') => {
	if (!dateTime) return null
	const date = new Date(dateTime)
	return date ? date.toLocaleString(timeZone) : null
}

const formatNumberToTwoDigits = numb => {
	if (numb.toString().length === 1) {
		return '0' + numb.toString()
	}
	return numb
}

export const dateIsToday = date => {
	const dateObject = new Date(date)
	const today = new Date()
	return today.toDateString() === dateObject.toDateString()
}

export const dateIsYesterday = date => {
	const dateObject = new Date(date)
	const yesterday = new Date()
	yesterday.setDate(yesterday.getDate() - 1)
	return yesterday.toDateString() === dateObject.toDateString()
}

export const convertTimeStampToLocalTimeZoneHours = timeStamp => {
	const date = new Date(timeStamp)
	const timezoneOffsetInMS = new Date().getTimezoneOffset()
	date.setTime(date.getTime() + timezoneOffsetInMS)

	const hours = formatNumberToTwoDigits(date.getHours())
	const minutes = formatNumberToTwoDigits(date.getMinutes())

	const fullDate = hours + ':' + minutes
	return fullDate
}

export const convertTimeStampToLocalTimeZone = timeStamp => {
	const date = new Date(timeStamp)
	const timezoneOffsetInMS = new Date().getTimezoneOffset()
	date.setTime(date.getTime() + timezoneOffsetInMS)

	const year = date.getFullYear()
	const day = formatNumberToTwoDigits(date.getDate())
	const month = formatNumberToTwoDigits(date.getMonth() + 1)
	const hours = formatNumberToTwoDigits(date.getHours())
	const minutes = formatNumberToTwoDigits(date.getMinutes())

	const fullDate = day + '/' + month + '/' + year + ', ' + hours + ':' + minutes
	return fullDate
}
