import React from "react"
import styled from "styled-components"

import { Skeleton } from "@mui/material"
import { SROnlyParagraph } from "../../../../styles/styledComponents"

const StyledSkeletonWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
`

const StyledSkeletonRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
`

const StyledSkeletonCell = styled(Skeleton)`
    margin-right: 10px;
`

export const TableLoadingSkeleton = () => {
    const renderSkeletonRow = () => {
        return (
            <StyledSkeletonRow>
                <StyledSkeletonCell variant="text" width={300} />
                <StyledSkeletonCell variant="text" width={120} />
                <StyledSkeletonCell variant="text" width={300} />
                <StyledSkeletonCell variant="text" width={300} />
            </StyledSkeletonRow>
        )
    }

    const renderTableLoadingSkeleton = () => {
        return (
            <>
                <StyledSkeletonWrapper aria-hidden="true">
                    {renderSkeletonRow()}
                    {renderSkeletonRow()}
                    {renderSkeletonRow()}
                    {renderSkeletonRow()}
                    {renderSkeletonRow()}
                    {renderSkeletonRow()}
                </StyledSkeletonWrapper>
                <SROnlyParagraph aria-live="polite">Loading...</SROnlyParagraph>
            </>
        )
    }

    return renderTableLoadingSkeleton()
}
