import React from 'react'
import styled, { keyframes } from 'styled-components'
import PropTypes from 'prop-types'

const Rotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`

const Dash = keyframes`
    0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
    }
    50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
    }
    100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
    }
`

const SpinnerContainer = styled.div`
	justify-content: center;
	align-items: center;
	height: ${props => props.height || '50px'};
	width: ${props => props.width || '50px'};
	flex-shrink: 0;
	margin: ${props => props.margin || '24px'};
`

const SpinnerSvg = styled.svg`
	-ms-animation: ${Rotate} 1s linear infinite;
	-moz-animation: ${Rotate} 1s linear infinite;
	-webkit-animation: ${Rotate} 1s linear infinite;
	animation: ${Rotate} 1s linear infinite;

	& .path {
		stroke: ${props => (props.$strokeColor ? props.$strokeColor : `#00923c`)};
		stroke-linecap: round;
		-ms-animation: ${Dash} 1.5s ease-in-out infinite;
		-moz-animation: ${Dash} 1.5s ease-in-out infinite;
		-webkit-animation: ${Dash} 1.5s ease-in-out infinite;
		animation: ${Dash} 1.5s ease-in-out infinite;
	}
`

export const AeraSpinner = ({ strokeWidth, strokeColor, ...props }) => {
	return (
		<SpinnerContainer {...props}>
			<SpinnerSvg {...props} $strokeColor={strokeColor || null} viewBox="0 0 50 50">
				<circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth={strokeWidth || '6'} />
			</SpinnerSvg>
		</SpinnerContainer>
	)
}

AeraSpinner.propTypes = {
	strokeWidth: PropTypes.string,
	strokeColor: PropTypes.string
}
