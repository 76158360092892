import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { baseInputStyles, StyledHelpText, StyledInputWrapper } from '../formStyles'
import { FieldError } from '../Feedback/FieldError'
import { breakpoints } from '../../../../utils/menuUtil'
import { Label } from '../../../../styles/Typography'

export const StyledTextArea = styled.textarea`
	${baseInputStyles};
	width: ${props => !props.cols && `230px;`};
	${breakpoints.smallDevice} {
		width: ${props => !props.cols && `382px;`};
	}
`

export const TextAreaField = ({ id, value, label, required, disabled, errorMessage, helpText, onChange, rows, cols, maxLength, ...props }) => {
	return (
		<StyledInputWrapper>
			<Label errorMessage={errorMessage} htmlFor={id}>
				{label}
				{!required && ' - optional'}
			</Label>
			<StyledTextArea
				maxLength={maxLength ? maxLength : '300'}
				//aria-required={required ? "true" : "false"}
				aria-describedby={
					helpText && errorMessage ? `helpText-${id} error-message-${id}` : helpText ? `helpText-${id}` : errorMessage ? `error-message-${id}` : null
				}
				aria-invalid={errorMessage ? true : null}
				errorMessage={errorMessage}
				value={value}
				onChange={onChange}
				id={id}
				name={id}
				rows={rows}
				cols={cols}
				disabled={disabled}
				{...props}
			/>
			{errorMessage && <FieldError id={`error-message-${id}`} errorMessage={errorMessage} />}
			{helpText && <StyledHelpText id={`helpText-${id}`}>{helpText}</StyledHelpText>}
		</StyledInputWrapper>
	)
}

TextAreaField.propTypes = {
	id: PropTypes.string.isRequired,
	value: PropTypes.string,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	errorMessage: PropTypes.string,
	helpText: PropTypes.string,
	onChange: PropTypes.func,
	cols: PropTypes.number,
	rows: PropTypes.number,
	maxLength: PropTypes.string
}
