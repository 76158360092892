import React from 'react'
import PropTypes from 'prop-types'

import { Colors } from '../../../styles/Colors'
import { AeraSpinner } from '../AeraSpinner'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { focusVisibleBaseStyles, SROnlyParagraph } from '../../../styles/styledComponents'
import styled, { css } from 'styled-components'

export const buttonTypes = {
	OUTLINED: 'outlined',
	CANCEL: 'cancel',
	DANGER: 'danger',
	BACK: 'back'
}

export const buttonSizes = {
	MEDIUM: 'medium',
	SMALL: 'small'
}

const cancelButtonStyle = css`
	background-color: ${Colors.aeraPrimaryLightestGrey};
	border: 2px solid ${Colors.aeraPrimaryLightestGrey};
	color: ${Colors.aeraBlack};

	&:focus-visible {
		color: ${Colors.aeraBlack};
		background-color: ${Colors.aeraPrimaryLightGrey};
		border: 2px solid ${Colors.aeraPrimaryLightGrey};
	}
	&:hover {
		color: ${Colors.aeraBlack};
		background-color: ${Colors.aeraPrimaryLightGrey};
		border: 2px solid ${Colors.aeraPrimaryLightGrey};
	}
`

const backButtonStyle = css`
	background-color: transparent;
	border: none;
	text-decoration: underline;
	color: ${Colors.aeraLinkColor};
	&:hover {
		background-color: transparent;
		border: none;
		color: ${Colors.aeraLinkColor};
		font-weight: 600;
		&:disabled {
			background-color: transparent;
			border: none;
		}
	}
	&:focus-visible {
		background-color: transparent;
		${focusVisibleBaseStyles};
	}
	&:disabled {
		background-color: transparent;
		border: none;
	}
`

const dangerButtonStyle = css`
	opacity: 80%;
	background-color: ${Colors.aeraRed};
	border: 2px solid ${Colors.aeraRed};
	&:focus {
		background-color: ${Colors.aeraRedHover};
		border: 2px solid ${Colors.aeraRedHover};
	}
	&:hover {
		background-color: ${Colors.aeraRedHover};
		border: 2px solid ${Colors.aeraRedHover};
	}
	${props =>
		props.outlined &&
		css`
			background-color: transparent;
			border: 2px solid ${Colors.aeraRed};
			color: ${Colors.aeraRed};
			&:hover {
				opacity: 100%;
				background-color: transparent;
				border: 2px solid ${Colors.aeraRed};
				color: ${Colors.aeraRed};
			}
			&:focus {
				opacity: 100%;
				background-color: transparent;
				border: 2px solid ${Colors.aeraRed};
				color: ${Colors.aeraRed};
			}
		`}
`

const smallButtonStyle = css`
	padding: 8px;
	font-size: 0.8rem;
	font-weight: 500;
	${props =>
		props.$isLoading &&
		` 
            height: 40px;
    `}
`

const mediumButtonStyle = css`
	padding: 10px;
	font-size: 0.9rem;
	font-weight: 500;
	${props =>
		props.$isLoading &&
		` 
            height: 50px;
           
    `}
`

const StyledButton = styled.button`
	border-radius: 5px;

	color: ${Colors.aeraWhite};
	background-color: ${Colors.aeraPrimaryDarkGreen};
	border: 2px solid ${Colors.aeraPrimaryDarkGreen};
	padding: 18px;
	font-size: 1rem;
	${props =>
		props.$width &&
		css`
			display: inline-block;
			width: ${props.$width};
		`}
	&:focus-visible {
		background-color: ${Colors.aeraPrimaryDarkGreenHover};
		border: 2px solid ${Colors.aeraPrimaryDarkGreenHover};
		${focusVisibleBaseStyles};
	}
	&:hover {
		cursor: pointer;
		background-color: ${Colors.aeraPrimaryDarkGreenHover};
		border: 2px solid ${Colors.aeraPrimaryDarkGreenHover};
	}

	${props =>
		props.outlined &&
		css`
			background-color: transparent;
			border: 2px solid ${Colors.aeraPrimaryDarkGreen};
			color: ${Colors.aeraPrimaryDarkGreen};
			&:hover {
				opacity: 100%;
				background-color: transparent;
				border: 2px solid ${Colors.aeraPrimaryDarkGreen};
				color: ${Colors.aeraPrimaryDarkGreen};
			}
			&:focus {
				opacity: 100%;
				background-color: transparent;
				border: 2px solid ${Colors.aeraPrimaryDarkGreen};
				color: ${Colors.aeraPrimaryDarkGreen};
			}
		`};
	${props =>
		props.$cancel &&
		css`
			${cancelButtonStyle}
		`}
	${props =>
		props.$danger &&
		css`
			${dangerButtonStyle}
		`}
    
    ${props =>
		props.small &&
		css`
			${smallButtonStyle}
		`}
    ${props =>
		props.medium &&
		css`
			${mediumButtonStyle}
		`}
    ${props =>
		props.back &&
		css`
			${backButtonStyle}
		`}

    ${props =>
		props.disabled && !props.$isLoading
			? `
                background-color: ${Colors.aeraPrimaryLightGrey};
                border: 2px solid ${Colors.aeraPrimaryLightGrey};
                color: ${Colors.aeraPrimaryGrey};
                &:hover {
                    background-color: ${Colors.aeraPrimaryLightGrey};
                    border: 2px solid ${Colors.aeraPrimaryLightGrey};
                    color: ${Colors.aeraPrimaryGrey};
                    cursor: not-allowed;
            };`
			: props.disabled &&
			  props.$isLoading &&
			  `
            &:hover {
                cursor: not-allowed;
             };
            `}
  
    ${props =>
		props.$round &&
		`
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    `}
`

const StyledIconWrapper = styled.div`
	margin-top: 5px;
	margin-right: 5px;
`

const StyledIconRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: inherit;
`

const StyledButtonSpinner = styled(AeraSpinner)`
	position: absolute;
	margin: ${props => (props.$small ? `-14px 0 0 -5px` : props.$medium ? `-15px 0 0 -6px` : `-18px 0 0 -8px`)};
`

const StyledLoadingWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`

const StyledHiddenChildren = styled.span`
	visibility: hidden;
	opacity: 0;
`

export const Button = props => {
	const { children, icon, isLoading, disabled, danger, outlined, cancel, round, width, onClick } = props || {}

	const getStrokeColor = () => {
		if (disabled) return Colors.aeraPrimaryGrey
		if (cancel) return Colors.aeraPrimaryDarkGrey
		if (outlined && danger) return Colors.aeraRed
		if (danger) return Colors.aeraWhite
		else return
	}

	const renderLoadIcon = () => {
		return (
			<>
				<StyledHiddenChildren aria-hidden="true">{children}</StyledHiddenChildren>
				<StyledLoadingWrapper>
					<StyledButtonSpinner
						strokeColor={getStrokeColor()}
						$medium={props.medium}
						$small={props.small}
						height={props.small ? 15 : props.medium ? 20 : 25}
						margin={0}
						aria-hidden="true"
					/>
				</StyledLoadingWrapper>
				<SROnlyParagraph aria-live="polite">Loading...</SROnlyParagraph>
			</>
		)
	}

	const getIcon = () => {
		if (props.back) return <ArrowBackIcon aria-hidden="true" />
		else return props.icon
	}

	const renderIcon = icon => {
		return (
			<StyledIconRow data-testid="icon-wrapper">
				<StyledIconWrapper>{icon}</StyledIconWrapper>
				{children}
			</StyledIconRow>
		)
	}

	return (
		<StyledButton
			onClick={onClick}
			$isLoading={isLoading}
			disabled={isLoading || disabled}
			outlined={outlined}
			$danger={danger}
			$cancel={cancel}
			$width={width}
			$round={round}
			{...props}
		>
			{icon || props.back ? renderIcon(getIcon()) : isLoading ? renderLoadIcon() : children}
		</StyledButton>
	)
}

Button.propTypes = {
	onClick: PropTypes.func,
	icon: PropTypes.object,
	isLoading: PropTypes.bool,
	disabled: PropTypes.bool,
	width: PropTypes.string,
	small: PropTypes.bool,
	medium: PropTypes.bool,
	secondary: PropTypes.bool,
	danger: PropTypes.bool,
	cancel: PropTypes.bool,
	back: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}
