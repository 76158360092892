import React from "react"
import styled from "styled-components"

import { AeraLogoLink } from "./AeraLogoLink"
import { LanguageSelector } from "./LanguageSelector"
import { LoginButton } from "../LoginButton"
import { Colors } from "../../styles/Colors"
import { useStore } from "../../state/store"
import { Menu } from "./Menu"
import { breakpoints } from "../../utils/menuUtil"

const StyledHeaderWrapper = styled.header`
    position: fixed;
    top: 0;
    width: 100%;
    height: 120px;
    background-color: ${Colors.aeraWhite};
    padding: 0 30px 0 30px;
    border-bottom: 1px solid ${Colors.aeraPrimaryLightGrey};
    display: flex;
    //gap: 10px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
`

const StyledRightWrapper = styled.div`
    width: 400px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
`

const StyledLoginWrapper = styled.div`
    ${(props) => props.$isAuthenticated && `display: none;`}
    ${breakpoints.extraLargeDevice} {
        ${(props) => props.$isAuthenticated && `display: inline-block;`}
    }
`

export const Header = () => {
    const { state } = useStore()

    return (
        <StyledHeaderWrapper>
            <AeraLogoLink />
            <Menu />
            <StyledRightWrapper>
                {state.isAuthenticated ?
                    <>
                        <LanguageSelector />
                        <StyledLoginWrapper $isAuthenticated={state.isAuthenticated}>
                            <LoginButton />
                        </StyledLoginWrapper>
                    </> : null}
            </StyledRightWrapper>
        </StyledHeaderWrapper>
    )
}
