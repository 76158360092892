import { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'

const KEY_NAME_ESC = 'Escape'
const KEY_EVENT_TYPE = 'keyup'

// Close something if escape is pressed, i.e. modal
export const useEscapeKey = handleClose => {
	// TODO: useEffectEvent?

	// useCallback: Cache function to not re-render as long as dependency does not change
	const handleEscKey = useCallback(
		event => {
			if (event.key === KEY_NAME_ESC) {
				handleClose()
			}
		},
		[handleClose]
	)

	useEffect(() => {
		document.addEventListener(KEY_EVENT_TYPE, handleEscKey, false)
		return () => document.removeEventListener(KEY_EVENT_TYPE, handleEscKey, false)
	}, [handleEscKey])
}

useEscapeKey.propTypes = {
	handleClose: PropTypes.func.isRequired
}
