import React, { createContext, useContext, useReducer } from "react"
import PropTypes from "prop-types"
import { globalReducer } from "../reducers"
import {
    STORAGE_LOCALE,
    STORAGE_REFRESH_TOKEN,
    STORAGE_AUTH_TOKEN,
    STORAGE_AUTHENTICATED,
    STORAGE_ACTIVE_LINK,
    STORAGE_MENU_IS_OPEN,
    STORAGE_PATH_LOGIN,
    STORAGE_LOGIN_URL,
    STORAGE_LOGIN_FROM_TIMEOUT_URL
} from "../../utils/storageUtil"
import { PROFILE_PATH } from "../../containers/Header/Menu/routes"
import { setActiveLink } from "../actions"

const StoreContext = createContext()

export const getLocale = () => {
    const locale = localStorage.getItem(STORAGE_LOCALE)
    if (locale) {
        return JSON.parse(locale)
    }
    return "nb"
}

export const getAuthenticated = () => {
    const isAuthenticated = localStorage.getItem(STORAGE_AUTHENTICATED)
    return isAuthenticated ? JSON.parse(isAuthenticated) : false
}

export const getAuthToken = () => {
    const token = localStorage.getItem(STORAGE_AUTH_TOKEN)
    return token ? JSON.parse(token) : null
}

export const getRefreshToken = () => {
    const token = localStorage.getItem(STORAGE_REFRESH_TOKEN)
    return token ? JSON.parse(token) : null
}

export const getMenuIsOpen = () => {
    const menuIsOpen = sessionStorage.getItem(STORAGE_MENU_IS_OPEN)
    return menuIsOpen ? JSON.parse(menuIsOpen) : false
}

export const getActiveLink = () => {
    const activeLink = sessionStorage.getItem(STORAGE_ACTIVE_LINK)
    return activeLink && activeLink !== undefined ? JSON.parse(activeLink) : null
}

const handleNoPath = () => {
    setActiveLink("0")
    return PROFILE_PATH
}

export const getPath = () => {
    const getPath = sessionStorage.getItem(STORAGE_PATH_LOGIN)
    return getPath ? JSON.parse(getPath) : handleNoPath()
}

export const fetchLoginUrl = () => {
    const loginUrl = sessionStorage.getItem(STORAGE_LOGIN_URL)
    const loginUrlFromTimeout = sessionStorage.getItem(STORAGE_LOGIN_FROM_TIMEOUT_URL)
    return loginUrl ? JSON.parse(loginUrl) : loginUrlFromTimeout ? JSON.parse(loginUrlFromTimeout) : null //TODO: What to do if no URL? : logOutUser()?
}

export const fetchLoginUrlFromTimeout = () => {
    const fetchLoginUrlFromTimeout = sessionStorage.getItem(STORAGE_LOGIN_FROM_TIMEOUT_URL)
    return fetchLoginUrlFromTimeout ? JSON.parse(fetchLoginUrlFromTimeout) : null
}

const INITIAL_STATE = {
    isAuthenticated: getAuthenticated(),
    loginStatusId: null,
    authToken: getAuthToken(),
    refreshToken: getRefreshToken(),
    locale: getLocale(),
    menuIsOpen: getMenuIsOpen(),
    activeLink: getActiveLink(),
    getPath: getPath(),
    fetchLoginUrl: fetchLoginUrl(),
    fetchLoginUrlFromTimeout: fetchLoginUrlFromTimeout(),
    oldCodeVerifier: null
}

export const StoreProvider = ({ children }) => {
    const [state, dispatch] = useReducer(globalReducer, INITIAL_STATE)

    return <StoreContext.Provider value={{ state, dispatch }}>{children}</StoreContext.Provider>
}

StoreProvider.propTypes = {
    children: PropTypes.object
}

export const useStore = () => useContext(StoreContext)
