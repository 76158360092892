import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { useStore } from '../../../state/store'
import { LOGIN_HOME_PATH } from '../../Header/Menu/routes'
import { setActiveLink } from '../../../state/actions'
import { menuItemsIndex } from '../../../utils/menuUtil'

export const ProtectedRoute = ({ children }) => {
	const { state, dispatch } = useStore()
	const navigate = useNavigate()

	useEffect(() => {
		if (!state.isAuthenticated) {
			dispatch(setActiveLink(menuItemsIndex.LOGIN_PAGE))
			navigate(LOGIN_HOME_PATH, { replace: true })
		}
	}, [])

	return children
}

ProtectedRoute.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	redirectPath: PropTypes.string
}
