import styled, { css } from 'styled-components'
import { Colors } from '../../../styles/Colors'
import { breakpoints } from '../../../utils/menuUtil'
import { focusVisibleBaseStyles } from '../../../styles/styledComponents'
import { Label, P2 } from '../../../styles/Typography'

export const StyledFormWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
`

export const StyledInputWrapper = styled.div`
	margin-bottom: 18px;
	margin-right: 16px;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	width: 262px;
	${breakpoints.smallDevice} {
		width: 416px;
	}
`

export const baseInputStyles = css`
	padding: 10px;
	font-size: 1rem;
	border: 1px solid ${Colors.aeraPrimaryDarkGrey};
	border-radius: 4px;
	:disabled {
		cursor: not-allowed;
	}
	width: 262px;
	${breakpoints.smallDevice} {
		width: 416px;
	}
	&:focus-visible {
		${focusVisibleBaseStyles};
	}
	${props => props.errorMessage && `border: 1px solid ${Colors.aeraError}`};
`

export const StyledHelpText = styled(P2)``

export const StyledRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: start;
`

export const StyledLabel = styled(Label)``
