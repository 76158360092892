import React, { useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import { StyledAlertSection, StyledContainer, StyledHeaderSection, StyledMainSection, StyledMetadataSection } from '../../styles/styledComponents'
import messages from '../../translations/messages'
import { LabelBox } from '../common/LabelBox'
import { breakpoints, getPageIdByMenuItemIndex, menuItemsIndex } from '../../utils/menuUtil'
import { backend } from '../../api/backend'

import { LabelBoxLoadingSkeleton } from '../common/LabelBox/LabelBoxLoadingSkeleton'
import { Colors } from '../../styles/Colors'
import { STORAGE_CONSENT, STORAGE_CONSENT_LAST_UPDATED } from '../../utils/storageUtil'
import {
	renderContactSupportLink,
	renderErrorFlatFeedback,
	renderNetworkError,
	renderNoInformationError,
	renderReAuthenticateLink,
	renderTimeoutError
} from '../../utils/feedbackUtil'
import { generateCodeVerifier, getLoginUrl } from '../../utils/loginUtil'
import { setActiveLink, setCodeVerifier, setFetchedLoginFromTimeoutUrl, setPath } from '../../state/actions'
import { fetchLoginUrlFromTimeout, useStore } from '../../state/store'
import { CONSENTS_PATH } from '../../containers/Header/Menu/routes'
import { statusCodes, responseCodes } from '../../utils/networkUtil'
import { convertTimeStampToLocalTimeZone } from '../../utils/formatUtil'
import { FlatFeedback } from '../common/Feedback/FlatFeedback'
import { Modal } from '../common/Feedback/Modal'
import { Button } from '../common/Button'
import { consentStatuses as statuses } from '../../utils/statusUtil'
import { AeraAlert } from '../common/Feedback/AeraAlert'
import { DeleteProfileSection } from './DeleteProfileSection'
import { H1, P } from '../../styles/Typography'

const StyledRow = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: center;
	flex-wrap: wrap;
	gap: 32px;
	${breakpoints.mediumDevice} {
		justify-content: start;
	}
`

const StyledDeleteButton = styled(Button)`
	margin-left: 20px;
`

export const ConsentsPage = () => {
	const intl = useIntl()
	const { state, dispatch } = useStore()

	const pageId = getPageIdByMenuItemIndex(menuItemsIndex.CONSENTS_PAGE)

	const [showRevokeConsentModal, setShowRevokeConsentModal] = useState(false)
	const [selectedMpa, setSelectedMpa] = useState(null)

	const saveConsentDataInSessionStorage = consent => {
		sessionStorage.setItem(STORAGE_CONSENT, JSON.stringify(consent))
	}

	const getConsentDataFromSessionStorage = () => {
		return JSON.parse(sessionStorage.getItem(STORAGE_CONSENT))
	}

	const saveLastUpdatedInSessionStorage = lastUpdated => {
		sessionStorage.setItem(STORAGE_CONSENT_LAST_UPDATED, JSON.stringify(lastUpdated))
	}

	const getLastUpdatedFromSessionStorage = () => {
		return JSON.parse(sessionStorage.getItem(STORAGE_CONSENT_LAST_UPDATED))
	}

	const [consentsLoading, setConsentsLoading] = useState(false)
	const [consents, setConsents] = useState(getConsentDataFromSessionStorage() || null)

	const [tokenExpired, setTokenExpired] = useState(false)
	const [noUserInfo, setNoUserInfo] = useState(false)
	const [networkError, setNetworkError] = useState(false)

	const [consentRevoked, setConsentRevoked] = useState(false)
	const [revokeConsentError, setRevokeConsentError] = useState(false)
	const [revokeConsentExpiredTokenError, setRevokeConsentExpiredTokenError] = useState(false)

	const [lastUpdated, setLastUpdated] = useState(getLastUpdatedFromSessionStorage() || null)

	const [verifier, setVerifier] = useState(null)

	useEffect(() => {
		dispatch(setPath(CONSENTS_PATH))
		dispatch(setActiveLink(menuItemsIndex.CONSENTS_PAGE))
	}, [])

	useEffect(() => {
		if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
			const oldVerifier = state.oldCodeVerifier
			let verifier = generateCodeVerifier()
			if (verifier === oldVerifier) {
				verifier = generateCodeVerifier()
			}
			setVerifier(verifier)
			dispatch(setCodeVerifier(verifier))
		}
	}, [tokenExpired])

	useEffect(() => {
		if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
			const fetchData = async () => {
				const url = await getLoginUrl(verifier)
				dispatch(setPath(CONSENTS_PATH))
				dispatch(setActiveLink(menuItemsIndex.CONSENTS_PAGE))
				dispatch(setFetchedLoginFromTimeoutUrl(url))
			}
			fetchData()
		}
	}, [verifier])

	const hasMountedRef = useRef(false)

	useEffect(() => {
		// Cancel requests when component unmounts to avoid race conditions
		const controller = new AbortController()
		if (!hasMountedRef.current) {
			setConsentsLoading(true)
			backend
				.get('/consents', { signal: controller.signal })
				.then(response => {
					const consents = response.data.consents
					setConsents(response.data.consents)
					saveConsentDataInSessionStorage(consents)

					if (consents?.length === 0) {
						setNoUserInfo(true)
					}
					const timeStamp = response.data.responseInfo?.timeStamp
					const timeStampTimeZone = convertTimeStampToLocalTimeZone(timeStamp)
					setLastUpdated(timeStampTimeZone)
					saveLastUpdatedInSessionStorage(timeStampTimeZone)
				})
				.catch(error => {
					const { response = {} } = error
					if (error.name !== 'CanceledError') {
						if (response?.status === statusCodes.UNAUTHORIZED || response?.data?.responseInfo?.responseCode === responseCodes.ID_TOKEN_EXPIRED) {
							setTokenExpired(true)
						} else if (response?.status === statusCodes.NOT_FOUND) {
							setNoUserInfo(true)
						} else {
							setNetworkError(true)
						}
					}
				})
				.finally(() => {
					setConsentsLoading(false)
				})
		} else {
			return () => controller.abort()
		}
	}, [])

	const handleDeleteButton = consent => {
		setSelectedMpa(consent)
		setShowRevokeConsentModal(true)
	}

	const renderConsents = () => {
		if (!tokenExpired && !networkError && consents?.length === 0) {
			return <FlatFeedback headerText={intl.formatMessage(messages.NoRegistered, { data: intl.formatMessage(messages.ConsentsSmall) })} />
		}
		if (!consents) {
			return renderErrorFlatFeedback(intl)
		}
		return (
			<StyledRow>
				{consents?.map((consent, index) => (
					<div data-testid="consent" key={index}>
						<LabelBox consent={consent} handleDelete={() => handleDeleteButton(consent)} />
					</div>
				))}
			</StyledRow>
		)
	}

	const handleRevokeConsent = () => {
		setConsentsLoading(true)

		const controller = new AbortController()

		backend
			.post(
				'/consent/delete',
				{
					mpaId: selectedMpa?.mpaConceptId || undefined
				},
				{ signal: controller.signal }
			)
			.then(() => {
				const consentBeingDeleted = consents.find(consent => consent.mpaName === selectedMpa.mpaName)
				const filteredConsents = consents.filter(consent => consent.mpaName !== selectedMpa.mpaName)
				setConsents([...filteredConsents, { ...consentBeingDeleted, consentStatus: statuses.REVOKED }])
				setConsentRevoked(true)
			})
			.catch(error => {
				const { response = {} } = error
				if (error.name !== 'CanceledError') {
					if (response?.status === statusCodes.UNAUTHORIZED || response?.data?.responseInfo?.responseCode === responseCodes.ID_TOKEN_EXPIRED) {
						setRevokeConsentExpiredTokenError(true)
					} else {
						setRevokeConsentError(true)
					}
				}
			})
			.finally(() => {
				setConsentsLoading(false)
				setShowRevokeConsentModal(false)
				controller.abort()
			})
	}

	const renderRevokeButtons = () => {
		return (
			<>
				<Button width="50%" cancel small onClick={() => setShowRevokeConsentModal(false)}>
					{intl.formatMessage(messages.NoCancelLabel)}
				</Button>
				<StyledDeleteButton data-testid="revoke" width="50%" danger small onClick={() => handleRevokeConsent()}>
					{intl.formatMessage(messages.RevokeConsentModalAcceptLabel)}
				</StyledDeleteButton>
			</>
		)
	}

	const renderAlerts = () => {
		return (
			<StyledAlertSection>
				<Modal
					id="revoke-modal"
					open={showRevokeConsentModal}
					handleClose={() => setShowRevokeConsentModal(false)}
					title={intl.formatMessage(messages.RevokeConsentModalTitle, { mpaName: selectedMpa?.mpaName })}
					subTitle={intl.formatMessage(messages.RevokeConsentModalSubTitle)}
					headerColor={Colors.aeraRed}
					renderAdditionalInfo={() => renderRevokeButtons()}
				/>
				{noUserInfo && renderNoInformationError(() => setNoUserInfo(false), intl)}
				{tokenExpired && renderTimeoutError(() => setTokenExpired(false), null, intl)}
				{networkError && renderNetworkError(() => setNetworkError(false), null, intl)}

				{revokeConsentExpiredTokenError && (
					<AeraAlert severity="error" handleClose={() => setRevokeConsentExpiredTokenError(false)}>
						{intl.formatMessage(messages.FeedbackErrorDuringRevokeConsent, {
							callToAction: renderReAuthenticateLink(intl)
						})}
					</AeraAlert>
				)}
				{consentRevoked && (
					<AeraAlert severity="success" handleClose={() => setConsentRevoked(false)}>
						{intl.formatMessage(messages.FeedbackRevokeConsentSuccess)}
					</AeraAlert>
				)}
				{revokeConsentError && (
					<AeraAlert severity="error" handleClose={() => setRevokeConsentError(false)}>
						{intl.formatMessage(messages.FeedbackErrorDuringRevokeConsent, {
							callToAction: renderContactSupportLink(intl)
						})}
					</AeraAlert>
				)}
			</StyledAlertSection>
		)
	}

	return (
		<StyledContainer aria-labelledby={pageId}>
			<Helmet>
				<title>{intl.formatMessage(messages.ConsentsMenuOption)}</title>
			</Helmet>
			{renderAlerts()}
			<StyledHeaderSection>
				<H1 id={pageId}>{intl.formatMessage(messages.Consents)}</H1>
				<P>{intl.formatMessage(messages.ConsentDescription)}</P>
				<StyledMetadataSection>
					{intl.formatMessage(messages.LastUpdated)}: {lastUpdated || '-'}
				</StyledMetadataSection>
			</StyledHeaderSection>
			<StyledMainSection>{consentsLoading ? <LabelBoxLoadingSkeleton /> : renderConsents()}</StyledMainSection>
			<DeleteProfileSection />
		</StyledContainer>
	)
}
