import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { Colors } from "../../../../styles/Colors"
import { breakpoints } from "../../../../utils/menuUtil"
import { H3, P } from "../../../../styles/Typography"

const StyledPlaceholderWrapper = styled.div`
    margin-top: 6px;
    margin-bottom: 18px;
    display: inline-block;
    word-wrap: break-word;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid ${Colors.aeraPrimaryDarkGrey};
    border-radius: 4px;
    background-color: ${Colors.aeraPrimaryLightGrey};
    width: 262px;
    ${breakpoints.smallDevice} {
        width: 414px;
    }
`

export const Placeholder = ({ label, text, maxWidth }) => {
    return (
        <>
            <H3>{label}</H3>
            <StyledPlaceholderWrapper maxWidth={maxWidth}>
                <P>{text}</P>
            </StyledPlaceholderWrapper>
        </>
    )
}

Placeholder.propTypes = {
    label: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    maxWidth: PropTypes.string
}
