import styled, { css } from "styled-components"
import { Colors } from "./Colors"
import { breakpoints } from "../utils/menuUtil"

export const StyledContainer = styled.main`
    margin-top: 100px;
`

const baseSectionStyle = css`
    margin-left: -10px;
    height: 100%;
    padding: 40px 40px 40px 40px;
    ${breakpoints.smallDevice} {
        padding: 80px 80px 80px 80px;
        margin-left: 0;
    }
`

export const StyledHeaderSection = styled.section`
    ${baseSectionStyle};
    ${(props) => (props.backgroundColor ? `background-color: ${props.backgroundColor}` : `background-color: ${Colors.aeraPrimaryLightestGrey}`)};
`

export const StyledAlertSection = styled.div`
    padding-top: 20px;
    width: 100%;
    z-index: 1;
`

export const StyledMainSection = styled.section`
    ${baseSectionStyle};
    background-color: ${Colors.aeraWhite};
`

export const StyledSecondSection = styled.section`
    ${baseSectionStyle};
    ${(props) => (props.backgroundColor ? `background-color: ${props.backgroundColor}` : `background-color: ${Colors.aeraSecondaryLightGreen}`)};
`

export const StyledTableWrapper = styled.div`
    padding-top: 50px;
`

export const StyledListItem = styled.li`
    margin-top: 20px;
    margin-left: 20px;
`

export const StyledMetadataSection = styled.p`
    margin-top: 10px;
    font-size: 0.8rem;
`

export const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
`

export const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
`

export const focusVisibleBaseStyles = css`
    z-index: 4;
    outline: 2px transparent solid;
    box-shadow: 0 0 0 2px ${Colors.aeraWhite}, 0 0 0 4px ${Colors.aeraFocusBorder}, 0 0 4px 8px ${Colors.aeraWhite};
`

/* Screen Reader Only
  https://kittygiraudel.com/2021/02/17/hiding-content-responsibly/
  https://github.com/sandrina-p/workshop-a11y-react-alicante
  SRONly can add additional info to GUI that is not understandable without good vision
  I.e. aria-hidden=true for one element without enough information without visual cues being added,
  and SROnly for the element with more information for screen readers
  Use aria-live with SRCOnly
*/
const SROnlyBaseStyles = css`
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
`

export const SROnlyParagraph = styled.p`
    ${SROnlyBaseStyles};
`

export const StyledAppendiceImagesWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    padding: 16px;
    border: 1px dashed grey;
    border-radius: 5px;
    overflow: hidden;
    width: 262px;
    ${breakpoints.smallDevice} {
        width: 414px;
    }
`

export const StyledAppendiceImageWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
    overflow: hidden;
`
