export const supportInquiryStatuses = {
    NEW_TICKET: "New",
    IN_PROGRESS_TICKET: "In Progress",
    CLOSED_TICKET: "Closed"
}

export const consentStatuses = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",

    BLOCKED: "BLOCKED",
    REVOKED: "REVOKED",
    PENDING_DELETE: "PENDING_DELETE"
}

export const statuses = {
    ...supportInquiryStatuses,
    ...consentStatuses,
    DEACTIVATED: "DEACTIVATED",
    OPEN: "OPEN",
    CLOSED: "CLOSED"
}
