import React from "react"

import { fetchLoginUrlFromTimeout } from "../state/store"
import messages from "../translations/messages"
import HikingGreen from "../assets/images/ManyPixels/HikingGreen.png"
import { FlatFeedback } from "../components/common/Feedback/FlatFeedback"
import { CONTACT_PATH } from "../containers/Header/Menu/routes"
import { Link } from "react-router-dom"
import { Colors } from "../styles/Colors"
import { AeraAlert } from "../components/common/Feedback/AeraAlert"

export const renderReAuthenticateLink = (intl) => {
    const loginUrl = fetchLoginUrlFromTimeout()
    if (!loginUrl) {
        return intl.formatMessage(messages.FeedbackReAuthenticate)
    }

    return (
        <a style={{ color: Colors.aeraLinkColor }} href={loginUrl}>
            {intl.formatMessage(messages.FeedbackReAuthenticate)}
        </a>
    )
}

export const renderContactSupportLink = (intl) => {
    return (
        <Link style={{ color: Colors.aeraLinkColor }} to={CONTACT_PATH}>
            {intl.formatMessage(messages.FeedbackContactSupport)}
        </Link>
    )
}

export const renderContactSupportEmailLink = (intl) => {
    return `${intl.formatMessage(messages.FeedbackContactSupport)}: support@aera.id`
}

export const renderTimeoutError = (handleClose, message, intl) => {
    return (
        <AeraAlert severity="warning" handleClose={() => handleClose()}>
            {message ? (
                message
            ) : (
                <>
                    {intl.formatMessage(messages.FeedbackTimeoutWarning)} {renderReAuthenticateLink(intl)}
                </>
            )}
            .
        </AeraAlert>
    )
}

export const renderNetworkError = (handleClose, message, intl, displayEmail = false) => {
    const text = message
        ? message
        : intl.formatMessage(messages.FeedbackNetworkError, {
              // If contact page does not work, we want users to contact them via email
              callToAction: displayEmail ? renderContactSupportEmailLink(intl) : renderContactSupportLink(intl)
          })
    return (
        <AeraAlert severity="error" handleClose={() => handleClose()}>
            {text}.
        </AeraAlert>
    )
}

export const renderNoInformationError = (handleClose, intl) => {
    return (
        <AeraAlert handleClose={() => handleClose()} severity="info">
            {intl.formatMessage(messages.FeedbackNoInformationInfo)}
        </AeraAlert>
    )
}

/* ---- Flatfeedbacks for the middle of the page, i.e. for empty tables ---- */

// When data is empty because it can not be fetched; either network error or timeout error
export const renderErrorFlatFeedback = (intl) => {
    return (
        <FlatFeedback
            image={{
                imageSrc: HikingGreen,
                imageAlt: "Man hiking alone"
            }}
            headerText={intl.formatMessage(messages.CouldNotRetrieveDataHeader)}
            paragraphText={intl.formatMessage(messages.CouldNotRetrieveDataParagraph)}
        />
    )
}

export const severities = {
    INFO: "info",
    SUCCESS: "success",
    WARNING: "warning",
    ERROR: "error"
}

export const severityColors = {
    INFO: {
        color: Colors.infoBg,
        iconColor: Colors.info
    },
    SUCCESS: {
        color: Colors.aeraSecondaryLightGreen,
        iconColor: Colors.success
    },
    WARNING: {
        color: Colors.warningBg,
        iconColor: Colors.warning
    },
    ERROR: {
        color: Colors.errorBg,
        iconColor: Colors.error
    }
}

export const getColorBySeverity = (severity) => {
    switch (severity) {
        case severities.SUCCESS:
            return severityColors.SUCCESS
        case severities.WARNING:
            return severityColors.WARNING
        case severities.ERROR:
            return severityColors.ERROR
        default:
            return severityColors.INFO
    }
}
