import axios from "axios"
import { STORAGE_AUTH_TOKEN } from "../utils/storageUtil"

export const backend = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_URL}/consumerportal`,
    method: "get",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
    },
    maxRedirects: 5,
    redirect: "manual"
})

backend.interceptors.request.use(
    function (config) {
        const token = JSON.parse(localStorage.getItem(STORAGE_AUTH_TOKEN))
        if (token) config.headers.Authorization = `Bearer ${token}`
        return config
    },
    function (error) {
        return Promise.reject(error)
    }
)
