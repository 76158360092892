import React, { useState, useEffect } from "react"
import { useIntl } from "react-intl"
import DataTable from "react-data-table-component"
import { Helmet } from "react-helmet-async"
import styled from "styled-components"

import {
    StyledAlertSection,
    StyledContainer,
    StyledHeaderSection,
    StyledMainSection,
    StyledMetadataSection,
    StyledTableWrapper
} from "../../styles/styledComponents"
import messages from "../../translations/messages"
import { getPageIdByMenuItemIndex, menuItemsIndex } from "../../utils/menuUtil"
import { TableLoadingSkeleton } from "../common/Table/TableLoadingSkeleton"
import { backend } from "../../api/backend"
import { renderFormattedDateCell, renderFormattedTransactionAmountCell, renderDataTipCell, StyledTableText } from "../common/Table/formatterUtil"
import { renderTimeoutError, renderNoInformationError, renderNetworkError } from "../../utils/feedbackUtil"
import { statusCodes, responseCodes } from "../../utils/networkUtil"
import { fetchLoginUrlFromTimeout, useStore } from "../../state/store"
import { generateCodeVerifier, getLoginUrl } from "../../utils/loginUtil"
import { setActiveLink, setCodeVerifier, setFetchedLoginFromTimeoutUrl, setPath } from "../../state/actions"
import { TRANSACTIONS_PATH } from "../../containers/Header/Menu/routes"
import { STORAGE_TRANSACTIONS, STORAGE_TRANSACTIONS_LAST_UPDATED } from "../../utils/storageUtil"
import { convertTimeStampToLocalTimeZone } from "../../utils/formatUtil"
import { YearMonthSelectFields } from "../common/Form/YearMonthSelectFields"
import { FlatFeedback } from "../common/Feedback/FlatFeedback"
import { H1, P } from "../../styles/Typography"

const StyledTransactionTableWrapper = styled(StyledTableWrapper)`
    margin-top: -60px;
`

const StyledFilterWrapper = styled.div`
    margin-left: 24px;
    margin-bottom: 36px;
`

export const TransactionsPage = () => {
    const intl = useIntl()
    const { state, dispatch } = useStore()
    const pageId = getPageIdByMenuItemIndex(menuItemsIndex.TRANSACTION_PAGE)

    const saveTransactionDataInSessionStorage = (consent) => {
        sessionStorage.setItem(STORAGE_TRANSACTIONS, JSON.stringify(consent))
    }

    const getTransactionDataFromSessionStorage = () => {
        return JSON.parse(sessionStorage.getItem(STORAGE_TRANSACTIONS))
    }

    const saveLastUpdatedInSessionStorage = (lastUpdated) => {
        sessionStorage.setItem(STORAGE_TRANSACTIONS_LAST_UPDATED, JSON.stringify(lastUpdated))
    }

    const getLastUpdatedFromSessionStorage = () => {
        return JSON.parse(sessionStorage.getItem(STORAGE_TRANSACTIONS_LAST_UPDATED))
    }

    const [transactionsLoading, setTransactionsLoading] = useState(false)
    const [transactions, setTransactions] = useState(getTransactionDataFromSessionStorage() || null)
    //const [defaultTransactions, setDefaultTransactions] = useState(getTransactionDataFromSessionStorage() || null)
    //const [searchText, setSearchText] = useState("")

    const [tokenExpired, setTokenExpired] = useState(false)
    const [noUserInfo, setNoUserInfo] = useState(false)
    const [networkError, setNetworkError] = useState(false)

    const [lastUpdated, setLastUpdated] = useState(getLastUpdatedFromSessionStorage() || null)

    const [verifier, setVerifier] = useState(null)

    useEffect(() => {
        dispatch(setPath(TRANSACTIONS_PATH))
        dispatch(setActiveLink(menuItemsIndex.TRANSACTION_PAGE))
    }, [])

    useEffect(() => {
        if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
            const oldVerifier = state.oldCodeVerifier
            let verifier = generateCodeVerifier()
            if (verifier === oldVerifier) {
                verifier = generateCodeVerifier()
            }
            setVerifier(verifier)
            dispatch(setCodeVerifier(verifier))
        }
    }, [tokenExpired])

    useEffect(() => {
        if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
            const fetchData = async () => {
                const url = await getLoginUrl(verifier)
                dispatch(setPath(TRANSACTIONS_PATH))
                dispatch(setActiveLink(menuItemsIndex.TRANSACTION_PAGE))
                dispatch(setFetchedLoginFromTimeoutUrl(url))
            }
            fetchData()
        }
    }, [verifier])

    // TODO: Split into words so you can search for i.e. Aera Dev instead of whole text like Aera Candy Dev
    /*const includesAttribute = (attribute) => {
        return attribute && attribute.toString().toLowerCase().includes(searchText.toLowerCase())
    }

    const includesAnyAttribute = (transaction) => {
        return (
            includesAttribute(transaction.transactionDate) ||
            includesAttribute(transaction.amount) ||
            includesAttribute(transaction.transactionType) ||
            includesAttribute(transaction.message)
            //includesAttribute(transaction.merchant) ||
            //includesAttribute(transaction.paymentInstrument)
        )
    }

    useEffect(() => {
        if (defaultTransactions?.length > 0) {
            if (!searchText || searchText === "") {
                setTransactions(defaultTransactions)
            } else {
                const filteredTransactions = defaultTransactions.filter((transaction) => {
                    return includesAnyAttribute(transaction)
                })
                setTransactions(filteredTransactions)
            }
        }
    }, [searchText])*/

    const addIdToTransactions = (transactions) => {
        if (!transactions || transactions?.length === 0) return transactions
        const transactionsWithId = transactions.map((transaction, index) => ({ ...transaction, id: transaction.transactionDate + "-" + index }))
        return transactionsWithId
    }

    useEffect(() => {
        const getTransactions = async () => {
            setTransactionsLoading(true)
            backend
                .get("/transactions")
                .then((response) => {
                    const transactions = response.data?.transactions
                    setTransactions(addIdToTransactions(transactions))
                    //setDefaultTransactions(transactions)
                    saveTransactionDataInSessionStorage(transactions)
                    const timeStamp = response.data.responseInfo?.timeStamp
                    const timeStampTimeZone = convertTimeStampToLocalTimeZone(timeStamp)
                    setLastUpdated(timeStampTimeZone)
                    saveLastUpdatedInSessionStorage(timeStampTimeZone)
                })
                .catch((error) => {
                    const { response = {} } = error
                    console.warn("Error during transactions retrieval: ", response)
                    if (response?.status === statusCodes.UNAUTHORIZED || response?.data?.responseInfo?.responseCode === responseCodes.ID_TOKEN_EXPIRED) {
                        setTokenExpired(true)
                    } else if (response?.status === statusCodes.NOT_FOUND) {
                        setNoUserInfo(true)
                    } else {
                        setNetworkError(true)
                    }
                })
                .finally(() => {
                    setTransactionsLoading(false)
                })
        }
        getTransactions()
    }, [])

    const renderDateFilter = () => {
        return (
            <StyledFilterWrapper>
                <YearMonthSelectFields setArray={(transactions) => setTransactions(transactions)} />
            </StyledFilterWrapper>
        )
    }

    const transactionTypes = {
        PAYMENT: "Payment",
        REFUND: "Refund",
        REVERSAL: "Reversal"
    }

    const renderFormattedTransactionTypeCell = (cell) => {
        const intl = useIntl()
        let translatedCell
        if (cell === transactionTypes.PAYMENT) {
            translatedCell = intl.formatMessage(messages.Payment)
        } else if (cell === transactionTypes.REFUND) {
            translatedCell = intl.formatMessage(messages.Refund)
        } else if (cell === transactionTypes.REVERSAL) {
            translatedCell = intl.formatMessage(messages.Reversal)
        } else {
            translatedCell = cell
        }
        return cell ? <StyledTableText>{translatedCell}</StyledTableText> : <StyledTableText>-</StyledTableText>
    }

    const columns = [
        {
            id: "transactionDate",
            name: intl.formatMessage(messages.TransactionDate),
            selector: (row) => row.transactionDate,
            //sortable: true,
            cell: (row) => renderFormattedDateCell(row.transactionDate)
        },
        {
            id: "transactionType",
            name: intl.formatMessage(messages.TransactionType),
            selector: (row) => row.transactionType,
            //sortable: true,
            cell: (row) => renderFormattedTransactionTypeCell(row.transactionType)
        },
        {
            id: "wspName",
            name: intl.formatMessage(messages.PaymentApp),
            selector: (row) => row.wspName
            //sortable: true
        },
        {
            id: "merchantName",
            name: intl.formatMessage(messages.Merchant),
            selector: (row) => row.merchantName,
            cell: (row) => renderDataTipCell(row.merchantName)
            //sortable: true
        },
        {
            id: "accountNumber",
            name: intl.formatMessage(messages.AccountNumber),
            selector: (row) => (row.fromAccountNumber ? row.fromAccountNumber : row.toAccountNumber),
            //sortable: true,
            right: true
        },
        {
            id: "amount",
            name: intl.formatMessage(messages.AmountUsed),
            selector: (row) => row.amount,
            //sortable: true,
            right: true,
            cell: (row) => renderFormattedTransactionAmountCell(row.amount)
        }
    ]

    const paginationComponentOptions = {
        //rowsPerPageText: intl.formatMessage(messages.RowsPerPageTableLabel),
        rangeSeparatorText: intl.formatMessage(messages.Of),
        noRowsPerPage: true
    }

    const renderAlerts = () => {
        return (
            <StyledAlertSection>
                {noUserInfo && renderNoInformationError(() => setNoUserInfo(false), intl)}
                {tokenExpired && renderTimeoutError(() => setTokenExpired(false), null, intl)}
                {networkError && renderNetworkError(() => setNetworkError(false), null, intl)}
            </StyledAlertSection>
        )
    }

    /*const getNoTransactionsMessage = () => {
        if (defaultTransactions?.length === 0) {
            return intl.formatMessage(messages.NoTransactionsRegisteredOnUser)
        } else if (defaultTransactions?.length > 0 && transactions?.length === 0) {
            return intl.formatMessage(messages.NoTransactionsFromFilter)
        }
        return intl.formatMessage(messages.NoTransactionsFound)
    }*/

    const renderNoTransactionsFeedback = () => {
        return <FlatFeedback paragraphText={intl.formatMessage(messages.NoTransactionsFound)} />
    }

    const renderTransactionTable = () => {
        /*if (!transactionsLoading && !tokenExpired && !networkError && transactions?.length === 0) {
            return <FlatFeedback headerText={intl.formatMessage(messages.NoRegistered, { data: intl.formatMessage(messages.TransactionsSmall) })} />
        }
        if (!transactionsLoading && !transactions) {
            return renderErrorFlatFeedback()
            //return <FlatFeedback headerText={intl.formatMessage(messages.NoInformationAvailable, { data: intl.formatMessage(messages.TransactionsSmall) })} />
        }*/

        return (
            <StyledTransactionTableWrapper>
                {renderDateFilter()}
                <DataTable
                    columns={columns}
                    keyField="id"
                    data={transactions || []}
                    noDataComponent={renderNoTransactionsFeedback()}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    progressPending={transactionsLoading}
                    progressComponent={<TableLoadingSkeleton />}
                    defaultSortFieldId="transactionDate"
                    defaultSortAsc={false}
                />
            </StyledTransactionTableWrapper>
        )
    }

    return (
        <StyledContainer aria-labelledby={pageId}>
            <Helmet>
                <title>{intl.formatMessage(messages.TransactionsMenuOption)}</title>
            </Helmet>
            {renderAlerts()}
            <StyledHeaderSection>
                <H1 id={pageId}>{intl.formatMessage(messages.Transactions)}</H1>
                <P>{intl.formatMessage(messages.TransactionsDescription)}</P>
                <StyledMetadataSection>
                    {intl.formatMessage(messages.LastUpdated)}: {lastUpdated || "-"}
                </StyledMetadataSection>
            </StyledHeaderSection>
            <StyledMainSection>{renderTransactionTable()}</StyledMainSection>
        </StyledContainer>
    )
}
