import React from "react"
import styled from "styled-components"
import { Skeleton } from "@mui/material"
import { SROnlyParagraph } from "../../../../styles/styledComponents"

const StyledSkeletonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    margin: 24px;
`

const StyledSkeleton = styled(Skeleton)`
    margin-right: 50px;
    border-radius: 10px;
`

export const LabelBoxLoadingSkeleton = () => {
    const renderSkeletonRow = () => {
        return (
            <>
                <StyledSkeletonWrapper aria-hidden="true">
                    <StyledSkeleton variant="rectangular" width={270} height={300} />
                    <StyledSkeleton variant="rectangular" width={270} height={300} />
                </StyledSkeletonWrapper>
                <SROnlyParagraph aria-live="polite">Loading...</SROnlyParagraph>
            </>
        )
    }

    return <>{renderSkeletonRow()}</>
}
