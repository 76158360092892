import React from "react"

export const ExternalLink = () => {
    return (
        <svg role="img" style={{ height: "20px", width: "10px" }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <title>External link indicator</title>
            <polygon fill="#0052cc" points="400 464 48 464 48 104 240 104 240 72 16 72 16 496 432 496 432 272 400 272 400 464" className="ci-primary" />
            <polygon
                fill="#0052cc"
                points="304 16 304 48 441.373 48 188.687 300.687 211.313 323.313 464 70.627 464 208 496 208 496 16 304 16"
                className="ci-primary"
            />
        </svg>
    )
}
