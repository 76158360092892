import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { StyledContainer, StyledHeaderSection, StyledListItem, StyledMainSection } from '../../styles/styledComponents'
import { Colors } from '../../styles/Colors'
import messages from '../../translations/messages'

import { setActiveLink } from '../../state/actions'
import { useStore } from '../../state/store'
import { getMenuOptions, getPageIdByMenuItemIndex, menuItemsIndex } from '../../utils/menuUtil'
import { H1 } from '../../styles/Typography'

export const Sitemap = () => {
	const intl = useIntl()
	const { dispatch, state } = useStore()
	const pageId = getPageIdByMenuItemIndex(menuItemsIndex.SITEMAP_PAGE)

	return (
		<StyledContainer aria-labelledby={pageId} $isLoggedIn={state.isAuthenticated}>
			<Helmet>
				<title>{intl.formatMessage(messages.Sitemap)}</title>
			</Helmet>
			<StyledHeaderSection>
				<H1 id={pageId}>{intl.formatMessage(messages.Sitemap)}</H1>
			</StyledHeaderSection>
			<StyledMainSection>
				<nav aria-labelledby={pageId}>
					<ul>
						{getMenuOptions(intl)?.map((menuOption, index) => {
							return (
								<StyledListItem key={index} style={{ color: Colors.aeraLinkColor }}>
									<Link id={index} style={{ color: Colors.aeraLinkColor }} to={menuOption.link} onClick={() => dispatch(setActiveLink(index))}>
										{menuOption.label}
									</Link>
								</StyledListItem>
							)
						})}
					</ul>
				</nav>
			</StyledMainSection>
		</StyledContainer>
	)
}
