import styled from 'styled-components'
import { Tooltip } from 'react-tooltip'
import React from 'react'
import { StatusBadge } from '../StatusBadge'
import { STORAGE_LOCALE } from '../../../utils/storageUtil'

export const StyledTableText = styled.p`
	font-size: 0.8rem;
`

const StyledStatusBadgeWrapper = styled.div`
	margin-top: 20px;
`

export const renderStatusBadgeCell = cell => {
	return (
		<StyledStatusBadgeWrapper>
			<StatusBadge status={cell} />
		</StyledStatusBadgeWrapper>
	)
}

export const renderDataTipCell = (cell, maxLength = 300) => {
	return cell ? (
		<>
			<Tooltip id={`status-bar-tooltip-${cell}`} />
			<div data-tooltip-id={`status-bar-tooltip-${cell}`} data-tooltip-content={cell.length > maxLength ? cell : null}>
				<StyledTableText>
					{cell.substring(0, maxLength)}
					{cell.length > maxLength && '...'}
				</StyledTableText>
			</div>
		</>
	) : (
		<StyledTableText>-</StyledTableText>
	)
}

export const formatStringToDate = string => {
	return new Date(string).toLocaleString()
}

export const renderFormattedDateCell = cell => {
	return cell ? <StyledTableText>{formatStringToDate(cell)}</StyledTableText> : <StyledTableText>-</StyledTableText>
}

export const formatStringToDateTime = string => {
	const date = string.substring(0, 10)
	const time = string.substring(11, 19)
	return date + ' ' + time
}

export const renderFormattedDateTimeCell = cell => {
	return cell ? <StyledTableText>{formatStringToDateTime(cell)}</StyledTableText> : <StyledTableText>-</StyledTableText>
}

const isDecimalNumber = number => {
	return number % 1 !== 0
}

const hasOneDigitAfterComma = number => {
	if (isDecimalNumber(number)) {
		const numberArray = number.toString().split('.')
		const afterComma = numberArray[1]
		if (afterComma.length === 1) {
			return true
		}
	}
}

const appendZeroToString = number => {
	return number.toString() + '0'
}

const roundToTwoDecimals = number => {
	return (Math.round(number * 100) / 100).toFixed(2)
}

const getTransactionAmount = cell => {
	const number = parseFloat(cell)
	if (hasOneDigitAfterComma(number)) {
		return appendZeroToString(number)
	}
	return roundToTwoDecimals(cell)
}

export const renderFormattedTransactionAmountCell = cell => {
	const language = JSON.parse(localStorage.getItem(STORAGE_LOCALE))

	return cell ? (
		language === 'en' ? (
			<StyledTableText>{getTransactionAmount(cell).replace(',', '.')}</StyledTableText>
		) : (
			<StyledTableText>{getTransactionAmount(cell).replace('.', ',')}</StyledTableText>
		)
	) : (
		<StyledTableText>-</StyledTableText>
	)
}

export const addIdToArray = array => {
	let index = 0
	return array.map(object => {
		index++
		return { ...object, id: index }
	})
}
