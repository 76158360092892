import {
    SET_LOCALE,
    SET_AUTHENTICATED,
    SET_LOGIN_STATUS_ID,
    SET_AUTH_TOKEN,
    SET_REFRESH_TOKEN,
    TOGGLE_MENU,
    SET_AUTHORIZING,
    SET_ACTIVE_LINK,
    TOGGLE_AUTHENTICATED,
    SET_PATH,
    SET_CODE_VERIFIER,
    SET_LOGIN_URL,
    SET_LOGIN_FROM_TIMEOUT_URL,
    SET_OLD_CODE_VERIFIER,
    REMOVE_PERSONAL_DATA
} from "./types"

export const setLocale = (language) => ({
    type: SET_LOCALE,
    value: language
})

export const setAuthenticated = (isAuthenticated) => ({
    type: SET_AUTHENTICATED,
    value: isAuthenticated
})

export const toggleAuthenticated = () => ({
    type: TOGGLE_AUTHENTICATED
})

export const setLoginStatusId = (loginStatusId) => ({
    type: SET_LOGIN_STATUS_ID,
    value: loginStatusId
})

export const setAuthToken = (authToken) => ({
    type: SET_AUTH_TOKEN,
    value: authToken
})

export const setRefreshToken = (refreshToken) => ({
    type: SET_REFRESH_TOKEN,
    value: refreshToken
})

export const setAuthorizing = (isAuthorizing) => ({
    type: SET_AUTHORIZING,
    value: isAuthorizing
})

export const toggleMenu = () => ({
    type: TOGGLE_MENU
})

export const setActiveLink = (linkId) => ({
    type: SET_ACTIVE_LINK,
    value: linkId
})

export const setCodeVerifier = (codeVerifier) => ({
    type: SET_CODE_VERIFIER,
    value: codeVerifier
})

export const setPath = (path) => ({
    type: SET_PATH,
    value: path
})
export const setFetchedLoginUrl = (url) => ({
    type: SET_LOGIN_URL,
    value: url
})
export const setFetchedLoginFromTimeoutUrl = (url) => ({
    type: SET_LOGIN_FROM_TIMEOUT_URL,
    value: url
})
export const setOldCodeVerifier = (verifier) => ({
    type: SET_OLD_CODE_VERIFIER,
    value: verifier
})

export const removePersonalData = () => ({
    type: REMOVE_PERSONAL_DATA
})
