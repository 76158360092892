import React, { useState, useRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { Colors } from '../../../styles/Colors'
import { setActiveLink, toggleMenu } from '../../../state/actions'
import { useStore } from '../../../state/store'
import { breakpoints } from '../../../utils/menuUtil'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useClickOutsideHandler } from '../../../hooks/useOutsideAlerter'
import { focusVisibleBaseStyles } from '../../../styles/styledComponents'
import { P } from '../../../styles/Typography'

const baseFocusHoverStyle = css`
	position: relative;
	font-weight: 500;
	background-color: ${Colors.aeraPrimaryLightestGrey};
`

const baseRowFocusHoverStyle = css`
	${baseFocusHoverStyle};
	border-top: 4px solid ${Colors.aeraPrimaryDarkGreen};
	border-left: none;
`

export const baseColumnFocusHoverStyle = css`
	${baseFocusHoverStyle};
	border-left: 4px solid ${Colors.aeraPrimaryDarkGreen};
`

const baseMenuOptionLabelStyle = css`
	display: inline-block;
	letter-spacing: 0.4px;
	width: 100%;
	text-align: left;
	${breakpoints.extraLargeDevice} {
		width: auto;
		text-align: center;
	}
`

export const StyledListItem = styled.li`
	width: 100%;
	${breakpoints.extraLargeDevice} {
		width: auto;
	}
`

const StyledSubMenuList = styled.ul`
	list-style: none;
	display: none;
	width: 100%;
	background-color: ${Colors.aeraPrimaryLightestGrey};
	${props =>
		props.$clicked &&
		`
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
    `};
	${breakpoints.extraLargeDevice} {
		width: 260px;
		position: absolute;
		background-color: ${Colors.aeraPrimaryLightGrey};
		box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
		${props =>
			props.$clicked &&
			`
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: start;
        `};
	}
`

const StyledSubMenuListItem = styled.li`
	width: inherit;
`

const StyledLink = styled(Link)`
	text-decoration: none;
    :focus-visible {
        ${baseColumnFocusHoverStyle};
		${focusVisibleBaseStyles};
        ${breakpoints.extraLargeDevice} {
            ${baseRowFocusHoverStyle};
            ${focusVisibleBaseStyles};
        }
    };
    :focus:not(:focus-visible) {
        ${baseColumnFocusHoverStyle};
        ${breakpoints.extraLargeDevice} {
            ${baseRowFocusHoverStyle};
        }
    };
    :hover {
        ${baseColumnFocusHoverStyle};
		background-color: ${Colors.aeraPrimaryLightGrey};
		${breakpoints.extraLargeDevice} {
			${baseRowFocusHoverStyle};
		}
        ${breakpoints.extraLargeDevice} {
            background-color: ${Colors.aeraPrimaryLightGrey};
            ${baseRowFocusHoverStyle};
    };
`

const StyledSubMenuListLink = styled(Link)`
	text-decoration: none;
	:hover {
		${baseColumnFocusHoverStyle};
		background-color: ${Colors.aeraPrimaryLightGrey};
	}

	${breakpoints.extraLargeDevice} {
	}
`

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
	background: transparent;
	border: none !important;
	vertical-align: middle;
	margin-left: 6px;
	margin-right: -8px;
	${props => props.$clicked && `transform: rotate(180deg);`}
`

const StyledMenuOptionLabel = styled(P)`
	${props => props.$currentpage === 'page' && `background-color: ${Colors.aeraPrimaryLightestGrey};`};
	padding: 46px 40px 51px 40px;
	${breakpoints.extraLargeDevice} {
		padding-bottom: 40px;
		padding-right: 20px;
		padding-left: 20px;
	}
`

const StyledSubMenuOptionLabel = styled(StyledMenuOptionLabel)`
	padding: 32px 0 32px 60px;
	${props => props.$currentpage === 'page' && `color: ${Colors.aeraPrimaryDarkGreen};`};
	${StyledSubMenuListItem}:hover & {
		color: ${Colors.aeraPrimaryDarkGreen};
	}
	${breakpoints.extraLargeDevice} {
		padding: 12px 0 12px 30px;
		${StyledSubMenuListItem}:hover & {
			background: ${Colors.aeraPrimaryLightestGrey};
		}
	}
`

export const MenuOption = ({ label, link, id, subMenu, subMenuItems }) => {
	const { state, dispatch } = useStore()

	const [firstSubMenuItemIsClicked, setFirstSubMenuItemIsClicked] = useState(false)

	const subMenuLength = subMenuItems?.length
	const firstSubMenuItemId = id + '-' + 0
	const secondSubMenuItemId = id + '-' + 1

	const wrapperRef = useRef(null)
	const handleClickOutside = () => {
		setFirstSubMenuItemIsClicked(false)
	}
	useClickOutsideHandler(wrapperRef, handleClickOutside)

	const handleClick = (event, subItemId) => {
		if (subItemId === firstSubMenuItemId) {
			event.preventDefault()
			setFirstSubMenuItemIsClicked(!firstSubMenuItemIsClicked)
		} else {
			dispatch(toggleMenu())
			setFirstSubMenuItemIsClicked(false)
			dispatch(setActiveLink(subItemId))
		}
	}

	//https://www.w3.org/WAI/tutorials/menus/flyout/#flyoutnavmousefixed
	const renderSubMenu = () => {
		return (
			<StyledSubMenuList $clicked={firstSubMenuItemIsClicked} aria-hidden={!firstSubMenuItemIsClicked && 'true'} aria-pressed={firstSubMenuItemIsClicked}>
				{subMenuItems?.map((item, index) => {
					const subItemId = id + '-' + index
					return (
						<StyledSubMenuListItem key={index}>
							<StyledSubMenuListLink
								$first={index === 0}
								$second={index === 1}
								id={subItemId}
								to={item.link}
								onClick={() => handleClick(subItemId)}
								className={state.activeLink == subItemId && 'active-sub-link active'}
								aria-current={state.activeLink == subItemId ? 'page' : 'false'}
								$currentpage={state.activeLink == subItemId ? 'page' : 'false'}
							>
								<StyledSubMenuOptionLabel $currentpage={state.activeLink == subItemId ? 'page' : 'false'}>{item.label}</StyledSubMenuOptionLabel>
							</StyledSubMenuListLink>
						</StyledSubMenuListItem>
					)
				})}
			</StyledSubMenuList>
		)
	}

	const activeLinkIsSubMenuLink = activeLink => {
		if (!subMenuItems || subMenuLength === 0) return

		switch (activeLink) {
			case firstSubMenuItemId:
				return true
			case secondSubMenuItemId:
				return true
			default:
				return false
		}
	}

	return (
		<StyledListItem id={id} ref={wrapperRef}>
			<StyledLink
				$submenu={subMenu}
				id={subMenu ? `subMenu-${id}` : `link-${id}`}
				to={link}
				aria-expanded={subMenu && firstSubMenuItemIsClicked ? 'true' : subMenu ? 'false' : null}
				onClick={event => handleClick(event, subMenu ? firstSubMenuItemId : id)}
				aria-current={state.activeLink == id ? 'page' : 'false'}
			>
				<StyledMenuOptionLabel
					className={(state.activeLink == id || activeLinkIsSubMenuLink(state.activeLink)) && 'active-link active'}
					$currentpage={state.activeLink == id || activeLinkIsSubMenuLink(state.activeLink) ? 'page' : 'false'}
				>
					{label} {subMenu && <StyledArrowDropDownIcon aria-hidden="true" fontSize="large" $clicked={firstSubMenuItemIsClicked} />}
				</StyledMenuOptionLabel>
			</StyledLink>
			{subMenu && renderSubMenu()}
		</StyledListItem>
	)
}

MenuOption.propTypes = {
	label: PropTypes.string.isRequired,
	link: PropTypes.string.isRequired,
	id: PropTypes.number.isRequired,
	subMenu: PropTypes.bool,
	subMenuItems: PropTypes.array
}
