import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { Colors } from "../../../styles/Colors"

const StyledButton = styled.button`
    background-color: white;
    border: none;
    min-width: 44px;
    min-height: 44px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    :hover {
        background-color: ${Colors.aeraPrimaryLightGrey};
        cursor: pointer;
        font-weight: 600;
    }
    :focus {
        background-color: ${Colors.aeraPrimaryLightGrey};
    }
    margin-left: 8px;
    font-weight: 450;
`

export const ActionButton = ({ onClick, ariaLabel, Icon, ...props }) => {
    return (
        <StyledButton aria-label={ariaLabel} onClick={() => onClick()} {...props}>
            {Icon}
        </StyledButton>
    )
}

ActionButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    Icon: PropTypes.object.isRequired,
    ariaLabel: PropTypes.string.isRequired
}
