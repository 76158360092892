export const statusCodes = {
    UNAUTHORIZED: 401,
    NOT_FOUND: 404,
    FORBIDDEN: 403,
    UNSUPPORTED_MEDIA_TYPE: 415
}

export const responseCodes = {
    ID_TOKEN_EXPIRED: "40004-01"
}
