import { useEffect } from 'react'

// Call a given function if a mouse click occurs outside the passed ref
export const useClickOutsideHandler = (ref, handleOutside) => {
	useEffect(() => {
		const handleClickOutside = event => {
			if (ref.current && !ref.current.contains(event.target)) {
				handleOutside()
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref])
}
