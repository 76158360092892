import {
	SET_AUTHENTICATED,
	REMOVE_PERSONAL_DATA,
	SET_LOGIN_STATUS_ID,
	SET_AUTH_TOKEN,
	SET_LOCALE,
	SET_REFRESH_TOKEN,
	SET_AUTHORIZING,
	TOGGLE_MENU,
	SET_ACTIVE_LINK,
	TOGGLE_AUTHENTICATED,
	SET_CODE_VERIFIER,
	SET_PATH,
	SET_LOGIN_URL,
	SET_LOGIN_FROM_TIMEOUT_URL,
	SET_OLD_CODE_VERIFIER
} from '../actions/types'

import {
	STORAGE_LOCALE,
	STORAGE_REFRESH_TOKEN,
	STORAGE_AUTH_TOKEN,
	STORAGE_AUTHENTICATED,
	STORAGE_IS_AUTHORIZING,
	STORAGE_LOGIN_STATUS_ID,
	STORAGE_ACTIVE_LINK,
	STORAGE_MENU_IS_OPEN,
	STORAGE_PATH_LOGIN,
	STORAGE_CODE_VERIFIER,
	STORAGE_LOGIN_URL,
	STORAGE_LOGIN_FROM_TIMEOUT_URL,
	STORAGE_PROFILE_USER,
	STORAGE_TRANSACTIONS,
	STORAGE_CONSENT,
	STORAGE_PROFILE_LAST_UPDATED,
	STORAGE_TRANSACTIONS_LAST_UPDATED,
	STORAGE_CONSENT_LAST_UPDATED,
	STORAGE_CONTACT_LOG,
	STORAGE_DATA_PROCESSOR_REPORT,
	STORAGE_CONTACT_DETAILS_COMMENTS,
	STORAGE_CONTACT_DETAILS_TICKET,
	STORAGE_CONTACT_DETAILS_ATTACHMENTS
} from '../../utils/storageUtil'

const INITIAL_STATE = []

export const globalReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case REMOVE_PERSONAL_DATA:
			sessionStorage.removeItem(STORAGE_PROFILE_USER)
			sessionStorage.removeItem(STORAGE_PROFILE_LAST_UPDATED)
			sessionStorage.removeItem(STORAGE_TRANSACTIONS)
			sessionStorage.removeItem(STORAGE_TRANSACTIONS_LAST_UPDATED)
			sessionStorage.removeItem(STORAGE_CONSENT)
			sessionStorage.removeItem(STORAGE_CONSENT_LAST_UPDATED)
			sessionStorage.removeItem(STORAGE_CONTACT_LOG)
			sessionStorage.removeItem(STORAGE_CONTACT_DETAILS_TICKET)
			sessionStorage.removeItem(STORAGE_CONTACT_DETAILS_COMMENTS)
			sessionStorage.removeItem(STORAGE_CONTACT_DETAILS_ATTACHMENTS)
			sessionStorage.removeItem(STORAGE_DATA_PROCESSOR_REPORT)
			return {
				...state
			}
		case SET_AUTHENTICATED:
			localStorage.setItem(STORAGE_AUTHENTICATED, JSON.stringify(action.value))
			return {
				...state,
				isAuthenticated: action.value
			}
		case TOGGLE_AUTHENTICATED:
			localStorage.setItem(STORAGE_AUTHENTICATED, JSON.stringify(!state.isAuthenticated))
			return {
				...state,
				isAuthenticated: !state.isAuthenticated
			}
		case SET_LOGIN_STATUS_ID:
			localStorage.setItem(STORAGE_LOGIN_STATUS_ID, JSON.stringify(action.value))
			return {
				...state,
				loginStatusId: action.value
			}
		case SET_AUTH_TOKEN:
			let authToken = action.value ? action.value : null
			localStorage.setItem(STORAGE_AUTH_TOKEN, JSON.stringify(authToken))
			return {
				...state,
				authToken: action.value
			}
		case SET_REFRESH_TOKEN:
			let refreshToken = action.value ? action.value : null
			localStorage.setItem(STORAGE_REFRESH_TOKEN, JSON.stringify(refreshToken))
			state.refreshToken = action.value
			return {
				...state,
				refreshToken: action.value
			}
		case SET_LOCALE:
			localStorage.setItem(STORAGE_LOCALE, JSON.stringify(action.value))
			return {
				...state,
				locale: action.value
			}
		case SET_AUTHORIZING:
			localStorage.setItem(STORAGE_IS_AUTHORIZING, JSON.stringify(action.value))
			return {
				isAuthorizing: action.value
			}
		case TOGGLE_MENU:
			sessionStorage.setItem(STORAGE_MENU_IS_OPEN, JSON.stringify(!state.menuIsOpen))
			return {
				...state,
				menuIsOpen: !state.menuIsOpen
			}
		case SET_ACTIVE_LINK:
			sessionStorage.setItem(STORAGE_ACTIVE_LINK, JSON.stringify(action.value))
			return {
				...state,
				activeLink: action.value
			}
		case SET_CODE_VERIFIER:
			localStorage.setItem(STORAGE_CODE_VERIFIER, JSON.stringify(action.value))
			return {
				...state,
				codeVerifier: action.value
			}
		case SET_PATH:
			sessionStorage.setItem(STORAGE_PATH_LOGIN, JSON.stringify(action.value))
			return {
				...state,
				getPath: action.value
			}
		case SET_LOGIN_URL:
			sessionStorage.setItem(STORAGE_LOGIN_URL, JSON.stringify(action.value))
			return {
				...state,
				fetchLoginUrl: action.value
			}
		case SET_LOGIN_FROM_TIMEOUT_URL:
			sessionStorage.setItem(STORAGE_LOGIN_FROM_TIMEOUT_URL, JSON.stringify(action.value))
			return {
				...state,
				fetchLoginUrlFromTimeout: action.value
			}
		case SET_OLD_CODE_VERIFIER:
			return {
				...state,
				oldCodeVerifier: action.value
			}
		default:
			return state
	}
}
