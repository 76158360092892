import React, { useState, useEffect } from "react"
import { useIntl } from "react-intl"
import { Helmet } from "react-helmet-async"
import styled from "styled-components"
import { useNavigate } from "react-router-dom"

import { fetchLoginUrlFromTimeout, useStore } from "../../../state/store"
import messages from "../../../translations/messages"
import { StyledAlertSection, StyledContainer, StyledHeaderSection, StyledMainSection, StyledTableWrapper } from "../../../styles/styledComponents"
import { getPageIdByMenuItemIndex, menuItemsIndex, breakpointSizes } from "../../../utils/menuUtil"
import { CONTACT_PATH, CONTACT_PATH_LOG, CONTACT_PATH_LOG_LIST } from "../../../containers/Header/Menu/routes"
import { setActiveLink, setCodeVerifier, setFetchedLoginFromTimeoutUrl, setPath } from "../../../state/actions"
import EmptyInboxGreen from "../../../assets/images/ManyPixels/inbox/EmptyInboxGreen.png"
import DataTable from "react-data-table-component"
import { TableLoadingSkeleton } from "../../common/Table/TableLoadingSkeleton"
import { renderStatusBadgeCell } from "../../common/Table/formatterUtil"
import { backend } from "../../../api/backend"
import { Button } from "../../common/Button"
import { FlatFeedback } from "../../common/Feedback/FlatFeedback"
import { generateCodeVerifier, getLoginUrl } from "../../../utils/loginUtil"
import { renderFormattedDateCell } from "../../common/Table/formatterUtil"
import { statusCodes } from "../../../utils/networkUtil"
import { renderErrorFlatFeedback, renderNetworkError, renderTimeoutError } from "../../../utils/feedbackUtil"
import { STORAGE_CONTACT_LOG } from "../../../utils/storageUtil"
import { H1, P } from "../../../styles/Typography"

const StyledTransactionTableWrapper = styled(StyledTableWrapper)`
    margin-top: -60px;
`

export const ContactLogList = () => {
    const intl = useIntl()
    const { dispatch, state } = useStore()
    const navigate = useNavigate()

    const pageId = getPageIdByMenuItemIndex(menuItemsIndex.CONTACT_PAGE_LOG)

    const getDataFromSessionStorage = () => {
        return JSON.parse(sessionStorage.getItem(STORAGE_CONTACT_LOG))
    }

    const saveDataInSessionStorage = (user) => {
        sessionStorage.setItem(STORAGE_CONTACT_LOG, JSON.stringify(user))
    }

    const [contactLogLoading, setContactLogLoading] = useState(false)
    const [tickets, setTickets] = useState(getDataFromSessionStorage() || [])

    const [networkError, setNetworkError] = useState(false)
    const [tokenExpired, setTokenExpired] = useState(false)
    const [verifier, setVerifier] = useState(null)

    useEffect(() => {
        dispatch(setPath(CONTACT_PATH_LOG_LIST))
        dispatch(setActiveLink(menuItemsIndex.CONTACT_PAGE_LOG))
    }, [])

    useEffect(() => {
        if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
            const oldVerifier = state.oldCodeVerifier
            let verifier = generateCodeVerifier()
            if (verifier === oldVerifier) {
                verifier = generateCodeVerifier()
            }
            setVerifier(verifier)
            dispatch(setCodeVerifier(verifier))
        }
    }, [tokenExpired])

    useEffect(() => {
        if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
            const fetchData = async () => {
                const url = await getLoginUrl(verifier)
                dispatch(setPath(CONTACT_PATH_LOG_LIST))
                dispatch(setActiveLink(menuItemsIndex.CONTACT_PAGE_LOG))
                dispatch(setFetchedLoginFromTimeoutUrl(url))
            }
            fetchData()
        }
    }, [verifier])

    useEffect(() => {
        setContactLogLoading(true)
        const getContactLog = async () => {
            backend
                .get("/tickets")
                .then((response) => {
                    const tickets = response.data.tickets
                    setTickets(tickets)
                    saveDataInSessionStorage(tickets)
                    setContactLogLoading(false)
                })
                .catch((error) => {
                    const { response = {} } = error
                    console.warn("Error retrieving contact log: ", response)
                    if (response?.status === statusCodes.UNAUTHORIZED) {
                        setTokenExpired(true)
                    } else {
                        setNetworkError(true)
                    }
                    setContactLogLoading(false)
                    //setTokenExpired
                })
        }
        getContactLog()
    }, [])

    const paginationComponentOptions = {
        rangeSeparatorText: intl.formatMessage(messages.Of),
        noRowsPerPage: true
    }

    const renderNoContactLogFeedback = () => {
        return (
            <FlatFeedback
                headerText={intl.formatMessage(messages.ContactLogNoInquiriesTitle)}
                image={{
                    imageSrc: EmptyInboxGreen,
                    imageAlt: "Empty inbox"
                }}
                link={{
                    linkTo: CONTACT_PATH,
                    linkText: intl.formatMessage(messages.ContactLogNoInquiriesCreateNewLinkLabel),
                    handleClick: () => dispatch(setActiveLink(menuItemsIndex.CONTACT_PAGE))
                }}
            />
        )
    }

    const goToContactLogDetails = (row) => {
        navigate(CONTACT_PATH_LOG + `/${row.id}`, { state: { id: row.id, row: row } })
    }

    const renderButton = (row) => {
        return (
            <Button small onClick={() => goToContactLogDetails(row)}>
                {intl.formatMessage(messages.SeeDetails)}
            </Button>
        )
    }

    const columns = [
        {
            id: "subject",
            name: intl.formatMessage(messages.Summary),
            selector: (row) => row.subject,
            wrap: true
        },
        {
            id: "updatedDate",
            name: intl.formatMessage(messages.LastUpdated),
            selector: (row) => row.updatedDate,
            cell: (row) => renderFormattedDateCell(row.updatedDate),
            hide: breakpointSizes.mediumDeviceBreakpointSize
        },
        {
            id: "status",
            name: "Status",
            selector: (row) => row.status,
            cell: (row) => renderStatusBadgeCell(row.status),
            hide: breakpointSizes.smallDeviceBreakpointSize
        },
        {
            id: "action",
            name: "",
            cell: (row) => renderButton(row),
            right: true
        }
    ]

    const renderContactLog = () => {
        if (!contactLogLoading && (networkError || tokenExpired)) {
            return renderErrorFlatFeedback(intl)
        }
        if (!contactLogLoading && (!tickets || tickets.length === 0)) {
            return renderNoContactLogFeedback()
        }
        return (
            <StyledTransactionTableWrapper>
                <DataTable
                    columns={columns}
                    keyField="id"
                    data={tickets || []}
                    noDataComponent={renderNoContactLogFeedback()}
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    progressPending={contactLogLoading}
                    progressComponent={<TableLoadingSkeleton />}
                    defaultSortFieldId="updatedDate"
                    defaultSortAsc={false}
                />
            </StyledTransactionTableWrapper>
        )
    }

    const renderAlerts = () => {
        return (
            <StyledAlertSection>
                {tokenExpired && renderTimeoutError(() => setTokenExpired(false), null, intl)}
                {networkError && renderNetworkError(() => setNetworkError(false), null, intl, true)}
            </StyledAlertSection>
        )
    }

    return (
        <StyledContainer aria-labelledby={pageId}>
            <Helmet>
                <title>{intl.formatMessage(messages.ContactMenuMyInquiriesSubOption)}</title>
            </Helmet>
            {renderAlerts()}
            <StyledHeaderSection>
                <H1 id={pageId}>{intl.formatMessage(messages.ContactLogTitle)}</H1>
                <P>{intl.formatMessage(messages.ContactLogDescription)}</P>
            </StyledHeaderSection>
            <StyledMainSection>{renderContactLog()}</StyledMainSection>
        </StyledContainer>
    )
}
