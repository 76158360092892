import React, { useEffect } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"
import { Link } from "react-router-dom"

import { StyledContainer, StyledMainSection } from "../../styles/styledComponents"
import messages from "../../translations/messages"
import { getPageIdByMenuItemIndex, menuItemsIndex, breakpoints } from "../../utils/menuUtil"
import PageNotFoundGreen from "../../assets/images/ManyPixels/PageNotFoundGreen.png"
import { setActiveLink } from "../../state/actions"
import { LOGIN_HOME_PATH, SITEMAP_PATH } from "../../containers/Header/Menu/routes"
import { useStore } from "../../state/store"
import { Colors } from "../../styles/Colors"
import { H2, P } from "../../styles/Typography"

const StyledNotFoundContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
`

const StyledImage = styled.img`
    margin-top: 20px;
    width: 240px;
    margin-bottom: -40px;
    ${breakpoints.mediumDevice} {
        width: 320px;
        margin-bottom: -60px;
    }
    ${breakpoints.largeDevice} {
        width: 380px;
        margin-bottom: -60px;
    }
`

const StyledTextWrapper = styled.div`
    max-width: 600px;
    text-align: center;
`

const StyledHeaderWrapper = styled(H2)`
    ${breakpoints.mediumDevice} {
        line-height: 48px;
        margin-bottom: 20px;
    }
`

export const NotFoundPage = () => {
    const intl = useIntl()

    const pageId = getPageIdByMenuItemIndex(menuItemsIndex.NOT_FOUND_PAGE)

    const { state, dispatch } = useStore()

    useEffect(() => {
        dispatch(setActiveLink(menuItemsIndex.SITEMAP_PAGE))
    }, [])

    const renderSitemapLink = () => {
        return (
            <Link
                id={menuItemsIndex.SITEMAP_PAGE}
                style={{ color: Colors.aeraLinkColor }}
                to={SITEMAP_PATH}
                onClick={() => dispatch(setActiveLink(menuItemsIndex.SITEMAP_PAGE))}
            >
                {intl.formatMessage(messages.TheSitemap)}
            </Link>
        )
    }

    const renderHomePageLink = () => {
        return (
            <Link
                id={menuItemsIndex.LOGIN_PAGE}
                style={{ color: Colors.aeraLinkColor }}
                to={LOGIN_HOME_PATH}
                onClick={() => dispatch(setActiveLink(menuItemsIndex.LOGIN_PAGE))}
            >
                {intl.formatMessage(messages.TheHomepage)}
            </Link>
        )
    }

    return (
        <StyledContainer>
            <StyledMainSection aria-labelledby={pageId}>
                <StyledNotFoundContainer>
                    <StyledImage alt="404" src={PageNotFoundGreen} />
                    <StyledTextWrapper>
                        <StyledHeaderWrapper id={pageId}>{intl.formatMessage(messages.NotFoundTitle)}</StyledHeaderWrapper>
                        {state.isAuthenticated ? (
                            <P>
                                {intl.formatMessage(messages.NotFoundBody, {
                                    theSitemap: renderSitemapLink()
                                })}
                            </P>
                        ) : (
                            <P>
                                {intl.formatMessage(messages.NotFoundBodyLoggedOut, {
                                    theHomepage: renderHomePageLink()
                                })}
                            </P>
                        )}
                    </StyledTextWrapper>
                </StyledNotFoundContainer>
            </StyledMainSection>
        </StyledContainer>
    )
}
