export const STORAGE_LOCALE = "aera_consumer.locale"
export const STORAGE_REFRESH_TOKEN = "aera_consumer.refresh_token"
export const STORAGE_AUTH_TOKEN = "aera_consumer.auth_token"
export const STORAGE_AUTHENTICATED = "aera_consumer.authenticated"
export const STORAGE_IS_AUTHORIZING = "aera_consumer.is_authorizing"
export const STORAGE_LOGIN_STATUS_ID = "aera_consumer.login_status_id"

export const STORAGE_ACTIVE_LINK = "aera_consumer.active_link"
export const STORAGE_MENU_IS_OPEN = "aera_consumer.menu_is_open"

export const STORAGE_CODE_VERIFIER = "aera_consumer.code_verifier"
export const STORAGE_PATH_LOGIN = "aera_consumer.path_login"

export const STORAGE_LOGIN_URL = "aera_consumer.login_url"
export const STORAGE_LOGIN_FROM_TIMEOUT_URL = "aera_consumer.login_from_timeout_url"

export const STORAGE_PROFILE_USER = "aera_consumer.profile.user"
export const STORAGE_PROFILE_LAST_UPDATED = "aera_consumer.profile.last_updated"

export const STORAGE_TRANSACTIONS = "aera_consumer.transactions"
export const STORAGE_TRANSACTIONS_LAST_UPDATED = "aera_consumer.transactions.last_updated"

export const STORAGE_CONSENT = "aera_consumer.consent"
export const STORAGE_CONSENT_LAST_UPDATED = "aera_consumer.consent.last_updated"

export const STORAGE_CONTACT_LOG = "aera_consumer.contact_log"
export const STORAGE_CONTACT_DETAILS_TICKET = "aera_consumer.contact_details.ticket"
export const STORAGE_CONTACT_DETAILS_COMMENTS = "aera_consumer.contact_details.comments"
export const STORAGE_CONTACT_DETAILS_ATTACHMENTS = "aera_consumer.contact_details.attachments"

export const STORAGE_DATA_PROCESSOR_REPORT = "aera_consumer.data_processor_report"
