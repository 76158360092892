export const Colors = {
    aeraPrimaryGreen: "#00AF41",
    aeraPrimaryDarkGreen: "#007B3C",
    aeraPrimaryDarkGreenHover: "#006631",
    aeraPrimaryDarkGrey: "#404040",
    aeraPrimaryGrey: "#808285",
    aeraPrimaryLightGrey: "#f5f5f5",
    aeraPrimaryLightestGrey: "#FAFAFA",
    aeraSecondaryLightGreen: "#D6FBDB",
    aeraSecondaryDarkBlue: "#010023",
    aeraSecondaryLightBlue: "#94E0FF",
    aeraSecondaryLightestBlue: "#effafc",
    aeraSecondaryYellow: "#FFD400",
    aeraLinkColor: "#0052cc",
    aeraBlack: "#222",
    aeraWhite: "#FFFFFF",
    aeraError: "#B30000",
    aeraRed: "#B30000",
    aeraRedHover: "#990000",
    aeraDarkRed: "#8A0000",
    aeraFocusBorder: "#524dff",
    aeraDisabledButtonLink: "#6E7272",
    customerCoopColor: "#013365",
    customerTrumfColor: "#0176df",

    info: "#007CAD",
    infoBg: "#e6f8ff",
    success: "#1C873A",
    warning: "#473E00",
    warningBg: "#ffea80",
    error: "#EB0000",
    errorBg: "#ffe6e6",
    black: "#000000",

    // Do not use yet! New graphic profile. Might change.
    newProfile1: "#f7eee0",
    newProfile2: "#edd3c2",
    newProfile3: "#d9d9d9",
    newProfile4: "#0d472e",
    newProfile5: "#027b3f",
    newProfile6: "#edffec",
    newProfile7: "#fcf8f2",
    newProfile8: "#92ffc8"
}
