import { defineMessages } from "react-intl"

export const scope = "common"
export const form_scope = scope + ".form"

export default defineMessages({
    DefaultHelmet: {
        id: `${scope}.DefaultHelmet`,
        defaultMessage: "Aera Forbrukerportal"
    },
    TextRegexError: {
        id: `${scope}.TextRegexError`,
        defaultMessage: "Ugyldige karakterer brukt. Vennligst fjern noen spesialkarakterer fra teksten."
    },
    CouldNotRetrieveDataHeader: {
        id: `${scope}.CouldNotRetrieveDataHeader`,
        defaultMessage: "Her var det øde!"
    },
    CouldNotRetrieveDataParagraph: {
        id: `${scope}.CouldNotRetrieveDataParagraph`,
        defaultMessage: "Vi har dessverre problemer med å finne frem innholdet"
    },

    Yes: {
        id: `${scope}.Yes`,
        defaultMessage: "Ja"
    },
    No: {
        id: `${scope}.No`,
        defaultMessage: "Nei"
    },
    Created: {
        id: `${scope}.Created`,
        defaultMessage: "Opprettet"
    },
    Max: {
        id: `${scope}.Max`,
        defaultMessage: "maks"
    },
    MaxCharacters: {
        id: `${scope}.MaxCharacters`,
        defaultMessage: "maks tegn"
    },
    GoBack: {
        id: `${scope}.GoBack`,
        defaultMessage: "Gå tilbake til {page}"
    },
    LastUpdated: {
        id: `${scope}.LastUpdated`,
        defaultMessage: "Sist oppdatert"
    },
    SeeDetails: {
        id: `${scope}.SeeDetails`,
        defaultMessage: "See details"
    },
    Details: {
        id: `${scope}.Details`,
        defaultMessage: "Detaljer"
    },
    Action: {
        id: `${scope}.Action`,
        defaultMessage: "Handling"
    },

    Merchant: {
        id: `${scope}.Merchant`,
        defaultMessage: "Butikk"
    },

    Menu: {
        id: `${scope}.Menu`,
        defaultMessage: "Meny"
    },
    Close: {
        id: `${scope}.Close`,
        defaultMessage: "Lukk"
    },
    LogIn: {
        id: `${scope}.LogIn`,
        defaultMessage: "Logg inn"
    },
    LogOut: {
        id: `${scope}.LogOut`,
        defaultMessage: "Logg ut"
    },
    BypassBlockBody: {
        id: `${scope}.BypassBlockBody`,
        defaultMessage: "Hopp til hovedinnhold"
    },

    NotFoundTitle: {
        id: `${scope}.NotFoundTitle`,
        defaultMessage: "Beklager, siden eksisterer ikke"
    },
    NotFoundBody: {
        id: `${scope}.NotFoundBody`,
        defaultMessage: "Gå til {yourProfile} eller {theSitemap} for oversikt over alt vi kan tilby"
    },
    NotFoundBodyLoggedOut: {
        id: `${scope}.NotFoundBodyLoggedOut`,
        defaultMessage: "Gå til {theHomepage} for innlogging"
    },
    YourProfile: {
        id: `${scope}.YourProfile`,
        defaultMessage: "din profil"
    },
    TheSitemap: {
        id: `${scope}.TheSitemap`,
        defaultMessage: "nettstedskartet"
    },
    TheHomepage: {
        id: `${scope}.TheHomepage`,
        defaultMessage: "forsiden"
    },

    BeenLoggedOut: {
        id: `${scope}.BeenLoggedOut`,
        defaultMessage: "Du var stille en stund og har blitt logget ut"
    },
    LoggedOut: {
        id: `${scope}.LoggedOut`,
        defaultMessage: "Utlogget"
    },
    ClickToReLogIn: {
        id: `${scope}.ClickToReLogIn`,
        defaultMessage: "Trykk her for å logge deg inn på nytt"
    },

    FeedbackNotCustomerWarning: {
        id: `${scope}.FeedbackNotCustomerWarning`,
        defaultMessage: "Vi kan ikke se at du er innrullert til Aera Kontobetaling. Du kan derfor ikke logge inn i Forbrukerportalen."
    },
    FeedbackTimeoutWarning: {
        id: `${scope}.FeedbackTimeoutWarning`,
        defaultMessage: "Ny informasjon kunne ikke innhentes. For å se oppdatert informasjon, vennligst "
    },
    FeedbackNoInformationInfo: {
        id: `${scope}.FeedbackNoInformationInfo`,
        defaultMessage: "Vi kunne ikke finne noen registrerte transaksjoner tilknyttet din bruker"
    },
    FeedbackLoginNetworkError: {
        id: `${scope}.FeedbackLoginNetworkError`,
        defaultMessage: "En feil oppstod under inlogging. Prøv igjen senere. Om problemet vedvarer, vennligst kontakt support på support@aera.id"
    },
    FeedbackUserDeletionStarted: {
        id: `${scope}.FeedbackUserDeletionStarted`,
        defaultMessage: "Sletting av all brukerdata i forbrukerportal er påstartet. Du er blitt logget ut."
    },
    FeedbackErrorDuringUserDeletion: {
        id: `${scope}.FeedbackErrorDuringUserDeletion`,
        defaultMessage: "Brukerdata kunne ikke slettes. Vennligst prøv igjen senere. Om problemet vedvarer, vennligst {callToAction}"
    },
    FeedbackErrorDuringRevokeConsent: {
        id: `${scope}.FeedbackErrorDuringRevokeConsent`,
        defaultMessage: "Samtykke kunne ikke trekkes tilbake. Vennligst prøv igjen senere. Om problemet vedvarer, vennligst {callToAction}"
    },
    FeedbackRevokeConsentSuccess: {
        id: `${scope}.FeedbackRevokeConsentSuccess`,
        defaultMessage: "Samtykket er nå trukket."
    },
    FeedbackNetworkError: {
        id: `${scope}.FeedbackNetworkError`,
        defaultMessage: "Informasjonen kunne ikke innhentes. Vennligst prøv igjen senere. Om problemet vedvarer, vennligst {callToAction}"
    },
    FeedbackReAuthenticate: {
        id: `${scope}.FeedbackReAuthenticate`,
        defaultMessage: "logg inn på nytt"
    },
    FeedbackContactSupport: {
        id: `${scope}.FeedbackContactSupport`,
        defaultMessage: "kontakt support"
    },
    FeedbackNewInquiryCommentSuccess: {
        id: `${scope}.FeedbackNewInquiryCommentSuccess`,
        defaultMessage: "Kommentaren ble sendt til support! Oppdater siden for å se kommentaren"
    },
    FeedbackNewAttachmentSuccess: {
        id: `${scope}.FeedbackNewAttachmentSuccess`,
        defaultMessage: "Vedlegg er blitt lastet opp"
    },
    FilterTableHeader: {
        id: `${scope}.FilterTableHeader`,
        defaultMessage: "Filtrer resultater"
    },
    FilterTableLabel: {
        id: `${scope}.FilterTableLabel`,
        defaultMessage: "Filtrer basert på celleverdi, i.e. dato"
    },

    ContactMenuOption: {
        id: `${scope}.ContactMenuOption`,
        defaultMessage: "Kontakt"
    },
    ContactMenuNewInquirySubOption: {
        id: `${scope}.ContactMenuNewInquirySubOption`,
        defaultMessage: "Ny henvendelse"
    },
    Inquiry: {
        id: `${scope}.Inquiry`,
        defaultMessage: "Henvendelse"
    },
    ContactMenuMyInquiriesSubOption: {
        id: `${scope}.ContactMenuMyInquiriesSubOption`,
        defaultMessage: "Mine henvendelser"
    },
    ProfileMenuOption: {
        id: `${scope}.ProfileMenuOption`,
        defaultMessage: "Personopplysninger"
    },
    TransactionsMenuOption: {
        id: `${scope}.TransactionsMenuOption`,
        defaultMessage: "Betalinger"
    },
    ConsentsMenuOption: {
        id: `${scope}.ConsentsMenuOption`,
        defaultMessage: "Samtykker"
    },
    ProcessingMenuOption: {
        id: `${scope}.ProcessingMenuOption`,
        defaultMessage: "Databehandling"
    },

    Profile: {
        id: `${scope}.Profile`,
        defaultMessage: "Registrerte personopplysninger"
    },
    RetrieveUserInfoError: {
        id: `${scope}.RetrieveUserInfoError`,
        defaultMessage: "Brukerinformasjon kunne ikke innhentes. Vennligst prøv igjen senere."
    },
    LastChanged: {
        id: `${scope}.LastChanged`,
        defaultMessage: "Sist endret"
    },
    ProfileBody: {
        id: `${scope}.ProfileBody`,
        defaultMessage: "Aera har registrert følgende informasjon om deg."
    },
    ProfileNotFoundBody: {
        id: `${scope}.ProfileNotFoundBody`,
        defaultMessage: "Aera har ikke registrert informasjon om deg."
    },
    ProfileContactIfIncorrectInfo: {
        id: `${scope}.ProfileContactIfIncorrectInfo`,
        defaultMessage:
            "Endringer i e-post og mobilnummer kan gjøres i app hvor du bruker tjenesten. Dersom bostedsinformasjon ikke stemmer, eller har endret seg, ber vi deg kontakte Skatteetaten for å oppdatere opplysningene."
    },
    ProfileInputFieldName: {
        id: `${scope}.ProfileInputFieldName`,
        defaultMessage: "Navn"
    },
    ProfileInputFieldAddress: {
        id: `${scope}.ProfileInputFieldAddress`,
        defaultMessage: "Adresse"
    },
    ProfileInputFieldPostalCode: {
        id: `${scope}.ProfileInputFieldPostalCode`,
        defaultMessage: "Poststed"
    },
    ProfileInputFieldEmail: {
        id: `${scope}.ProfileInputFieldEmail`,
        defaultMessage: "E-post"
    },
    ProfileInputFieldPhone: {
        id: `${scope}.ProfileInputFieldPhone`,
        defaultMessage: "Mobilnummer"
    },
    Information: {
        id: `${scope}.Information`,
        defaultMessage: "Informasjon"
    },
    NoInformationAvailable: {
        id: `${scope}.NoInformationAvailable`,
        defaultMessage: "Kunne ikke vise frem {data}"
    },

    PepTitle: {
        id: `${scope}.PepTitle`,
        defaultMessage: "Lovpålagte anti-hvitvaskingsspørsmål"
    },
    PepIsPepLabel: {
        id: `${scope}.PepIsPepLabel`,
        defaultMessage:
            "Er du, eller har du siste 12 måneder vært, en politisk eksponert person (PEP), eller har et nært familiemedlem eller kjent medarbeider som er en slik person?"
    },
    PepWillBuyOverLabel: {
        id: `${scope}.PepWillBuyOverLabel`,
        defaultMessage: "Kommer du til å handle for mer enn 100.000,00 NOK per kjøp?"
    },
    HelpForIsBuyingOverButton: {
        id: `${scope}.HelpForIsBuyingOverButton`,
        defaultMessage: "Vis hjelpetekst"
    },
    PepHelpModalTitle: {
        id: `${scope}.PepHelpModalTitle`,
        defaultMessage: "Begrepsdefinisjoner"
    },
    PepHelpModalIntroduction: {
        id: `${scope}.PepHelpModalIntroduction`,
        defaultMessage: "The definitions politically exposed person, close family member and known co-worker of said person is described below."
    },
    PepHelpModalPepTitle: {
        id: `${scope}.PepHelpModalPepTitle`,
        defaultMessage: "Politically exposed person"
    },
    PepHelpModalPepSubTitle1: {
        id: `${scope}.PepHelpModalPepSubTitle1`,
        defaultMessage: "Head of State, head of government, minister or assistant minister"
    },
    PepHelpModalPepSubTitle2: {
        id: `${scope}.PepHelpModalPepSubTitle2`,
        defaultMessage: "Member of parliament"
    },
    PepHelpModalPepSubTitle3: {
        id: `${scope}.PepHelpModalPepSubTitle3`,
        defaultMessage: "Member of governing body of a political party"
    },
    PepHelpModalPepSubTitle4: {
        id: `${scope}.PepHelpModalPepSubTitle4`,
        defaultMessage: "Member of higher court that makes a decision that can not or only by exception be appealed"
    },
    PepHelpModalPepSubTitle5: {
        id: `${scope}.PepHelpModalPepSubTitle5`,
        defaultMessage: "Member of board of a National Audit Board"
    },
    PepHelpModalPepSubTitle6: {
        id: `${scope}.PepHelpModalPepSubTitle6`,
        defaultMessage: "Ambassador, chargé d’affaires or high-ranking officer in the armed forces"
    },
    PepHelpModalPepSubTitle7: {
        id: `${scope}.PepHelpModalPepSubTitle7`,
        defaultMessage: "Member of the administrative, management or supervisory body of State-owned enterprise"
    },
    PepHelpModalPepSubTitle8: {
        id: `${scope}.PepHelpModalPepSubTitle8`,
        defaultMessage: "Director, board member or other person in senior management in an international organization"
    },
    PepFamilyMemberHelpModalTitle: {
        id: `${scope}.PepFamilyMemberHelpModalTitle`,
        defaultMessage: "Close family member"
    },
    PepFamilyMemberHelpModalSubtitle: {
        id: `${scope}.PepFamilyMemberHelpModalSubtitle`,
        defaultMessage: "Parent, spouse, registered partner, cohabitant and children, as well as children's spouse, registered partner or cohabitant"
    },
    PepAssociateHelpModalTitle: {
        id: `${scope}.PepAssociateHelpModalTitle`,
        defaultMessage: "Known co-worker"
    },
    PepAssociateHelpModalIntro: {
        id: `${scope}.PepAssociateHelpModalIntro`,
        defaultMessage: "Physical person known to"
    },
    PepAssociateHelpModalSubtitle1: {
        id: `${scope}.PepAssociateHelpModalSubtitle1`,
        defaultMessage: "Be a licensee, association or registered legal entity in partnership with a politically exposed person"
    },
    PepAssociateHelpModalSubtitle2: {
        id: `${scope}.PepAssociateHelpModalSubtitle2`,
        defaultMessage: "Have a close business connection with a politically exposed person"
    },
    PepAssociateHelpModalSubtitle3: {
        id: `${scope}.PepAssociateHelpModalSubtitle3`,
        defaultMessage: "Be sole licensee, association or registered legal entity that is in fact established to favor a politically exposed person"
    },
    PepRole: {
        id: `${scope}.PepRole`,
        defaultMessage: "Rolle"
    },
    PepPosition: {
        id: `${scope}.PepPosition`,
        defaultMessage: "Stilling"
    },
    CloseFamilyPepPosition: {
        id: `${scope}.CloseFamilyPepPosition`,
        defaultMessage: "Familiemedlems stilling"
    },
    CoworkerPepPosition: {
        id: `${scope}.CoworkerPepPosition`,
        defaultMessage: "Medarbeiders stilling"
    },
    PepFullName: {
        id: `${scope}.PepFullName`,
        defaultMessage: "Fullt navn"
    },
    CoworkerPepFullName: {
        id: `${scope}.CoworkerPepFullName`,
        defaultMessage: "Medarbeiders fulle navn"
    },
    FamilyMemberPepFullName: {
        id: `${scope}.FamilyMemberPepFullName`,
        defaultMessage: "Familiemedlems fulle navn"
    },
    PepCountryOfPosition: {
        id: `${scope}.PepCountryOfPosition`,
        defaultMessage: "Stillingsland"
    },
    PepOccupation: {
        id: `${scope}.PepOccupation`,
        defaultMessage: "Yrke"
    },
    PepFixedPayments: {
        id: `${scope}.PepFixedPayments`,
        defaultMessage: "Faste innbetalinger"
    },
    AdditionalPepInfo: {
        id: `${scope}.AdditionalPepInfo`,
        defaultMessage: "Tilleggsinformasjon om PEP"
    },

    PepRolePEP: {
        id: `${scope}.PepRolePEP`,
        defaultMessage: "Politisk eksponert person"
    },
    PepRoleCloseFamily: {
        id: `${scope}.PepRoleCloseFamily`,
        defaultMessage: "Nær familie"
    },
    PepRoleKnownCoworker: {
        id: `${scope}.PepRoleKnownCoworker`,
        defaultMessage: "Kjent medarbeider"
    },
    PepRoleNone: {
        id: `${scope}.PepRoleNone`,
        defaultMessage: "Ingen av delene"
    },

    PepPositionEmployed: {
        id: `${scope}.PepPositionEmployed`,
        defaultMessage: "Ansatt"
    },
    PepPositionStudent: {
        id: `${scope}.PepPositionStudent`,
        defaultMessage: "Student"
    },
    PepPositionSociallySecured: {
        id: `${scope}.PepPositionSociallySecured`,
        defaultMessage: "Trygdet"
    },
    PepPositionOther: {
        id: `${scope}.PepPositionOther`,
        defaultMessage: "Annet"
    },

    PepFixedPaymentsSalary: {
        id: `${scope}.PepFixedPaymentsSalary`,
        defaultMessage: "Lønn"
    },
    PepFixedPaymentsSocialBenefits: {
        id: `${scope}.PepFixedPaymentsSocialBenefits`,
        defaultMessage: "Sosiale ytelser"
    },
    PepFixedPaymentsRentalIncome: {
        id: `${scope}.PepFixedPaymentsRentalIncome`,
        defaultMessage: "Leieinntekter"
    },
    PepFixedPaymentsInheritedMoney: {
        id: `${scope}.PepFixedPaymentsInheritedMoney`,
        defaultMessage: "Arv"
    },
    PepFixedPaymentsNone: {
        id: `${scope}.PepFixedPaymentsNone`,
        defaultMessage: "Ingen faste innbetalinger"
    },

    Transactions: {
        id: `${scope}.Transactions`,
        defaultMessage: "Betalingsoversikt"
    },
    TransactionsSmall: {
        id: `${scope}.TransactionsSmall`,
        defaultMessage: "transaksjoner"
    },
    TransactionsDescription: {
        id: `${scope}.TransactionsDescription`,
        defaultMessage: "Oversikt over dine gjennomførte betalinger"
    },
    SearchTrxDateButtonLabel: {
        id: `${scope}.SearchTrxDateButtonLabel`,
        defaultMessage: "Søk etter transaksjoner"
    },
    SearchByDateTitle: {
        id: `${scope}.SearchByDateTitle`,
        defaultMessage: "Søk på dato"
    },
    Year: {
        id: `${scope}.Year`,
        defaultMessage: "År"
    },
    Month: {
        id: `${scope}.Month`,
        defaultMessage: "Måned"
    },
    RowsPerPageTableLabel: {
        id: `${scope}.RowsPerPageTableLabel`,
        defaultMessage: "Rader per side"
    },
    Of: {
        id: `${scope}.Of`,
        defaultMessage: "av"
    },
    TransactionDate: {
        id: `${scope}.TransactionDate`,
        defaultMessage: "Date of purchase"
    },
    TransactionType: {
        id: `${scope}.TransactionType`,
        defaultMessage: "Transaction type"
    },
    MessageFromBank: {
        id: `${scope}.MessageFromBank`,
        defaultMessage: "Message"
    },
    FromAccountNumber: {
        id: `${scope}.FromAccountNumber`,
        defaultMessage: "Fra kontonummer"
    },
    AccountNumber: {
        id: `${scope}.AccountNumber`,
        defaultMessage: "Kontonummer"
    },
    ToAccountNumber: {
        id: `${scope}.ToAccountNumber`,
        defaultMessage: "Til kontonummer"
    },
    AmountUsed: {
        id: `${scope}.AmountUsed`,
        defaultMessage: "Amount"
    },
    PaymentApp: {
        id: `${scope}.PaymentApp`,
        defaultMessage: "Betalingsapplikasjon"
    },
    NoTransactionsFromSearch: {
        id: `${scope}.NoTransactionsFromSearch`,
        defaultMessage: "Ingen transaksjoner matcher søket"
    },
    NoTransactionsFromFilter: {
        id: `${scope}.NoTransactionsFromFilter`,
        defaultMessage: "Ingen transaksjoner matcher filteret"
    },
    NoTransactionsRegisteredOnUser: {
        id: `${scope}.NoTransactionsRegisteredOnUser`,
        defaultMessage: "Ingen transaksjoner er registrert på din bruker"
    },
    NoTransactionsFound: {
        id: `${scope}.NoTransactionsFound`,
        defaultMessage: "Ingen transaksjoner funnet"
    },
    Payment: {
        id: `${scope}.Payment`,
        defaultMessage: "Payment"
    },
    Refund: {
        id: `${scope}.Refund`,
        defaultMessage: "Refund"
    },
    Reversal: {
        id: `${scope}.Reversal`,
        defaultMessage: "Reversal"
    },

    January: {
        id: `${scope}.January`,
        defaultMessage: "Januar"
    },
    February: {
        id: `${scope}.February`,
        defaultMessage: "Februar"
    },
    March: {
        id: `${scope}.March`,
        defaultMessage: "Mars"
    },
    April: {
        id: `${scope}.April`,
        defaultMessage: "April"
    },
    May: {
        id: `${scope}.May`,
        defaultMessage: "Mai"
    },
    June: {
        id: `${scope}.June`,
        defaultMessage: "Juni"
    },
    July: {
        id: `${scope}.July`,
        defaultMessage: "Juli"
    },
    August: {
        id: `${scope}.August`,
        defaultMessage: "August"
    },
    September: {
        id: `${scope}.September`,
        defaultMessage: "September"
    },
    October: {
        id: `${scope}.October`,
        defaultMessage: "Oktober"
    },
    November: {
        id: `${scope}.November`,
        defaultMessage: "November"
    },
    December: {
        id: `${scope}.December`,
        defaultMessage: "Desember"
    },

    Consents: {
        id: `${scope}.Consents`,
        defaultMessage: "Samtykker"
    },
    ConsentsSmall: {
        id: `${scope}.ConsentsSmall`,
        defaultMessage: "samtykker"
    },
    NoRegistered: {
        id: `${scope}.NoRegistered`,
        defaultMessage: "du har ingen registrerte {data}"
    },
    ConsentDescription: {
        id: `${scope}.ConsentDescription`,
        defaultMessage: "Beskrivelse av samtykker. Du kan også trekke samtykker på samme måte- og i samme app som du ga dem."
    },
    ConsentGiven: {
        id: `${scope}.ConsentGiven`,
        defaultMessage: "Samtykke til bruk gitt"
    },
    WithdrawConsent: {
        id: `${scope}.WithdrawConsent`,
        defaultMessage: "Trekk samtykke"
    },
    CreatedConsent: {
        id: `${scope}.CreatedConsent`,
        defaultMessage: "Consent given"
    },
    LastUpdatedConsent: {
        id: `${scope}.LastUpdatedConsent`,
        defaultMessage: "Last updated consent"
    },
    Status: {
        id: `${scope}.Status`,
        defaultMessage: "Status"
    },
    RevokeConsent: {
        id: `${scope}.RevokeConsent`,
        defaultMessage: "Trekk samtykke"
    },
    RevokeConsentModalTitle: {
        id: `${scope}.RevokeConsentModalTitle`,
        defaultMessage: "Trekk samtykke for {mpaName}"
    },
    RevokeConsentModalSubTitle: {
        id: `${scope}.RevokeConsentModalSubTitle`,
        defaultMessage:
            "Er du sikker på at du vil trekke samtykke for bruk av Aera kontobetaling i denne appen? Merk at dette vil gjøre tjenesten utilgjengelig i appen."
    },
    DeleteMeTitle: {
        id: `${scope}.DeleteMeTitle`,
        defaultMessage: "Slett meg"
    },
    DeleteMeDescription: {
        id: `${scope}.DeleteMeDescription`,
        defaultMessage: "Sletting av din profil vil gjøre tjenesten utilgjengelig i de apper hvor tjenesten brukes. I tillegg vil all data i portalen slettes."
    },
    DeleteMeButtonLabel: {
        id: `${scope}.DeleteMeButtonLabel`,
        defaultMessage: "Slett meg"
    },
    DeleteMeModalTitle: {
        id: `${scope}.DeleteMeModalTitle`,
        defaultMessage: "Slett meg"
    },
    DeleteMeModalSubTitle: {
        id: `${scope}.DeleteMeModalSubTitle`,
        defaultMessage: "Er du sikker på at du vil slette din profil hos Aera? Merk at dette vil slette alle registrerte data i portalen."
    },
    NoCancelLabel: {
        id: `${scope}.NoCancelLabel`,
        defaultMessage: "Nei, avbryt"
    },
    DeleteMeModalAcceptLabel: {
        id: `${scope}.DeleteMeModalAcceptLabel`,
        defaultMessage: "Ja, slett profil"
    },
    RevokeConsentModalAcceptLabel: {
        id: `${scope}.RevokeConsentModalAcceptLabel`,
        defaultMessage: "Ja, trekk samtykke"
    },

    Conditions: {
        id: `${scope}.Conditions`,
        defaultMessage: "Vilkår"
    },
    AcceptsTerms: {
        id: `${scope}.AcceptsTerms`,
        defaultMessage: "Jeg godkjenner vilkårene"
    },
    ConditionsText: {
        id: `${scope}.ConditionsText`,
        defaultMessage:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
    },

    Processing: {
        id: `${scope}.Processing`,
        defaultMessage: "Personopplysninger"
    },
    ReportSmall: {
        id: `${scope}.ReportSmall`,
        defaultMessage: "rapport"
    },
    ProcessingTitle: {
        id: `${scope}.ProcessingTitle`,
        defaultMessage: "Behandling av personopplysninger"
    },
    ProcessingDescription: {
        id: `${scope}.ProcessingDescription`,
        defaultMessage: "Oversikt over persondata vi behandler. For mer informasjon, se vår personvernerklæring på "
    },
    ProcessingPolicyLink: {
        id: `${scope}.ProcessingPolicyLink`,
        defaultMessage: "https://aera.id/no/personvernerklaering/"
    },
    ProcessingNoDataTitle: {
        id: `${scope}.ProcessingNoDataTitle`,
        defaultMessage: "Rapporten kunne ikke hentes ut"
    },
    ProcessingNoDataText: {
        id: `${scope}.ProcessingNoDataText`,
        defaultMessage: "Vennligst prøv igjen senere"
    },
    AerasPrivacyPolicyPage: {
        id: `${scope}.AerasPrivacyPolicyPage`,
        defaultMessage: "Aera sin personvernerklæring"
    },
    DataCategoryHeader: {
        id: `${scope}.DataCategoryHeader`,
        defaultMessage: "Datakategori"
    },
    BusinessServiceHeader: {
        id: `${scope}.BusinessServiceHeader`,
        defaultMessage: "Tjeneste"
    },
    VendorPlatformHeader: {
        id: `${scope}.VendorPlatformHeader`,
        defaultMessage: "Plattform"
    },
    DataCenterLocationHeader: {
        id: `${scope}.DataCenterLocationHeader`,
        defaultMessage: "Storage location"
    },
    PurposeOfUseHeader: {
        id: `${scope}.PurposeOfUseHeader`,
        defaultMessage: "Formål"
    },
    DataProcessorHeader: {
        id: `${scope}.DataProcessorHeader`,
        defaultMessage: "Databehandler"
    },
    RetentionTimeHeader: {
        id: `${scope}.RetentionTimeHeader`,
        defaultMessage: "Storage time"
    },

    HomePageTitle: {
        id: `${scope}.HomePageTitle`,
        defaultMessage: "Velkommen til Aera Forbrukerportal"
    },
    HomePageDescription: {
        id: `${scope}.HomePageDescription`,
        defaultMessage: "Beskrivelse av portalen her"
    },
    HomePageLoginButtonLabel: {
        id: `${scope}.HomePageLoginButtonLabel`,
        defaultMessage: "Logg inn"
    },

    LoginPageTitle1: {
        id: `${scope}.LoginPageTitle1`,
        defaultMessage: "Velkommen til "
    },
    LoginPageTitle2: {
        id: `${scope}.LoginPageTitle2`,
        defaultMessage: " Aera Forbrukerportal"
    },
    LoginPageDescription: {
        id: `${scope}.LoginPageDescription`,
        defaultMessage: "Forbrukerinformasjon for Kontobetaling"
    },
    ProfileTileDescription: {
        id: `${scope}.ProfileTileDescription`,
        defaultMessage: "Se hva våre tredjeparter har samlet inn om deg"
    },
    TransactionTileDescription: {
        id: `${scope}.TransactionTileDescription`,
        defaultMessage: "Se dine siste betalinger tilknyttet kontobetaling"
    },
    ConsentTileDescription: {
        id: `${scope}.ConsentTileDescription`,
        defaultMessage: "Få oversikt over alle gitte samtykker"
    },
    DataProcessingTileDescription: {
        id: `${scope}.DataProcessingTileDescription`,
        defaultMessage: "Få innsikt i vår behandling av dine data"
    },
    ContactTileLabel: {
        id: `${scope}.ContactTileLabel`,
        defaultMessage: "Kontakt oss"
    },
    ContactTileDescription: {
        id: `${scope}.ContactTileDescription`,
        defaultMessage: "Hva kan vi hjelpe deg med?"
    },

    Contact: {
        id: `${scope}.Contact`,
        defaultMessage: "Kontakt"
    },
    ContactTitle: {
        id: `${scope}.ContactTitle`,
        defaultMessage: "Ta gjerne kontakt med oss!"
    },
    ContactDescription: {
        id: `${scope}.ContactDescription`,
        defaultMessage: "Har du spørsmål vi kan hjelpe deg med? Vi får mange henvendelser, men vil prøve å svare deg så fort vi kan."
    },
    ContactInputFieldFirstName: {
        id: `${scope}.ContactInputFieldFirstName`,
        defaultMessage: "Fornavn"
    },
    ContactInputFieldSurname: {
        id: `${scope}.ContactInputFieldSurname`,
        defaultMessage: "Etternavn"
    },
    ContactInputFieldSubjectLine: {
        id: `${scope}.ContactInputFieldSubjectLine`,
        defaultMessage: "Oppsummering"
    },
    Summary: {
        id: `${scope}.Summary`,
        defaultMessage: "Oppsummering"
    },
    Description: {
        id: `${scope}.Description`,
        defaultMessage: "Beskrivelse"
    },
    Yesterday: {
        id: `${scope}.Yesterday`,
        defaultMessage: "I går "
    },
    Today: {
        id: `${scope}.Today`,
        defaultMessage: "I dag "
    },
    ContactInputFieldEmail: {
        id: `${scope}.ContactInputFieldEmail`,
        defaultMessage: "E-post (påkrevd)"
    },
    ContactInputFieldPhone: {
        id: `${scope}.ContactInputFieldPhone`,
        defaultMessage: "Telefon"
    },
    ContactInputFieldFreetext: {
        id: `${scope}.ContactInputFieldFreetext`,
        defaultMessage: "Hva kan vi hjelpe deg med?"
    },
    ContactInputFieldAppendix: {
        id: `${scope}.ContactInputFieldAppendix`,
        defaultMessage: "Vedlegg"
    },
    NoAppendix: {
        id: `${scope}.NoAppendix`,
        defaultMessage: "Ingen vedlegg"
    },
    InformationCouldNotBeSent: {
        id: `${scope}.InformationCouldNotBeSent`,
        defaultMessage: "Informasjonen kunne desverre ikke sendes inn"
    },
    FeedbackAttachmentCouldNotBeSent: {
        id: `${scope}.FeedbackAttachmentCouldNotBeSent`,
        defaultMessage: "Vedlegg kunne desverre ikke sendes inn. Du kan legge ved vedlegg i ettertid på Mine henvendelser"
    },
    AttachmentTooBig: {
        id: `${scope}.AttachmentTooBig`,
        defaultMessage: "Vedlegget er dessverre for stort til å sendes inn. Vennligst velg en fil på mindre enn {size}"
    },
    FileInputFieldChooseFileText: {
        id: `${scope}.FileInputFieldChooseFileText`,
        defaultMessage: "Velg fil"
    },
    FileInputFieldNoFileChosenText: {
        id: `${scope}.FileInputFieldNoFileChosenText`,
        defaultMessage: "Ingen fil valgt"
    },
    FileInputFieldChosenFileText: {
        id: `${scope}.FileInputFieldChosenFileText`,
        defaultMessage: "Fil valgt"
    },
    ContactInputFieldAppendixHelpText: {
        id: `${scope}.ContactInputFieldAppendixHelpText`,
        defaultMessage: "Last opp vedlegg (.pdf, .png eller .jpg/.jpeg). Maks størrelse 10 MB."
    },
    ContactFormButtonLabel: {
        id: `${scope}.ContactFormButtonLabel`,
        defaultMessage: "Send inn"
    },
    UploadAdditionalAttachments: {
        id: `${scope}.UploadAdditionalAttachments`,
        defaultMessage: "Last opp flere vedlegg"
    },
    UploadAttachments: {
        id: `${scope}.UploadAttachments`,
        defaultMessage: "Last opp {number} vedlegg"
    },
    UploadAttachment: {
        id: `${scope}.UploadAttachment`,
        defaultMessage: "Last opp vedlegg"
    },
    ContactFormSuccessLabel: {
        id: `${scope}.ContactFormSuccessLabel`,
        defaultMessage: "Your message was sent successfully!"
    },
    ContactFormSuccessText: {
        id: `${scope}.ContactFormSuccessText`,
        defaultMessage: "You can see all future correspondance under"
    },
    ContactLogTitle: {
        id: `${scope}.ContactLogTitle`,
        defaultMessage: "Mine henvendelser"
    },
    ContactLogDescription: {
        id: `${scope}.ContactLogDescription`,
        defaultMessage: "Alle tidligere henvendelser til Aera support."
    },
    ContactLogNoInquiriesTitle: {
        id: `${scope}.ContactLogNoInquiriesTitle`,
        defaultMessage: "Du har ingen tidligere henvendelser"
    },
    ContactLogNoInquiriesCreateNewLinkLabel: {
        id: `${scope}.ContactLogNoInquiriesCreateNewLinkLabel`,
        defaultMessage: "Opprett ny henvendelse"
    },
    Comments: {
        id: `${scope}.Comments`,
        defaultMessage: "Kommentarer"
    },
    Comment: {
        id: `${scope}.Comment`,
        defaultMessage: "Kommentar"
    },
    NoCommentsYet: {
        id: `${scope}.NoCommentsYet`,
        defaultMessage: "Denne henvendelsen har ingen kommentarer enda"
    },
    You: {
        id: `${scope}.You`,
        defaultMessage: "Du"
    },
    Commented: {
        id: `${scope}.Commented`,
        defaultMessage: "{sender} kommenterte "
    },
    NewComment: {
        id: `${scope}.NewComment`,
        defaultMessage: "Ny kommentar"
    },
    AddNewComment: {
        id: `${scope}.AddNewComment`,
        defaultMessage: "Legg til ny kommentar"
    },

    FileInputButtonLabel: {
        id: `${scope}.FileInputButtonLabel`,
        defaultMessage: "Velg fil"
    },
    FileInputChosenFile: {
        id: `${scope}.FileInputChosenFile`,
        defaultMessage: "valgt fil"
    },
    FileInputChosenFiles: {
        id: `${scope}.FileInputChosenFiles`,
        defaultMessage: "valgte filer"
    },
    FileInputNoFileChosen: {
        id: `${scope}.FileInputNoFileChosen`,
        defaultMessage: "Ingen fil valgt"
    },
    RequiredFieldError: {
        id: `${scope}.RequiredFieldError`,
        defaultMessage: "{field} er et påkrevd felt. Vennligst fyll inn feltet."
    },
    ContactInputFieldDescription: {
        id: `${scope}.ContactInputFieldDescription`,
        defaultMessage: "Beskrivelse (påkrevd)"
    },

    en: {
        id: `${scope}.en`,
        defaultMessage: "English"
    },
    nb: {
        id: `${scope}.nb`,
        defaultMessage: "Norsk"
    },

    Footer: {
        id: `${scope}.Footer`,
        defaultMessage: "© 2022 Aera Payment & Identification AS – Askekroken 11, 0277 Oslo - Telefon +47 22 55 25 00"
    },
    aeraURL: {
        id: `${scope}.aeraURL`,
        defaultMessage: "www.aera.id"
    },
    Sitemap: {
        id: `${scope}.Sitemap`,
        defaultMessage: "Nettstedkart"
    },
    Support: {
        id: `${scope}.Support`,
        defaultMessage: "Støtte"
    },
    Languages: {
        id: `${scope}.Languages`,
        defaultMessage: "Språk"
    },

    Optional: {
        id: `${form_scope}.Optional`,
        defaultMessage: "valgfri"
    },
    Required: {
        id: `${form_scope}.Required`,
        defaultMessage: "påkrevd"
    }
})
