/*
 *
 * LanguageProvider
 *
 * this component connects the custom state language locale to the
 * IntlProvider component and i18n messages (loaded from `app/translations`)
 */

import React from "react"
import PropTypes from "prop-types"
import { IntlProvider } from "react-intl"

import { useStore } from "../../state/store"

export default function LanguageProvider(props) {
    const { state } = useStore()
    const currentLocale = state.locale
    return (
        <IntlProvider locale={currentLocale} key={currentLocale} messages={props.messages[currentLocale]}>
            {React.Children.only(props.children)}
        </IntlProvider>
    )
}

LanguageProvider.propTypes = {
    messages: PropTypes.object,
    children: PropTypes.element.isRequired
}
