import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

import { Colors } from "../../../../styles/Colors"
import { H2, P } from "../../../../styles/Typography"

const StyledWrapper = styled.div`
    min-height: 30vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const StyledImage = styled.img`
    width: 240px;
    margin-bottom: 16px;
    margin-top: -16px;
`

const StyledText = styled(H2)`
    margin-bottom: 8px;
    color: #222;
`

const StyledTextWrapper = styled.div`
    max-width: 600px;
    text-align: center;
`

export const FlatFeedback = ({ image, headerText, paragraphText, link }) => {
    const { linkTo, linkText, handleClick } = link || {}
    const { imageSrc, imageAlt } = image || {}

    return (
        <StyledWrapper aria-live="polite">
            {image && <StyledImage src={imageSrc} alt={imageAlt} />}
            <StyledTextWrapper>
                {headerText && <StyledText>{headerText}</StyledText>}
                {paragraphText && <P>{paragraphText}</P>}
                {link && (
                    <Link style={{ color: Colors.aeraLinkColor }} to={linkTo} onClick={() => handleClick()}>
                        {linkText}
                    </Link>
                )}
            </StyledTextWrapper>
        </StyledWrapper>
    )
}

FlatFeedback.propTypes = {
    headerText: PropTypes.string,
    paragraphText: PropTypes.string,
    image: PropTypes.object,
    link: PropTypes.object
}
