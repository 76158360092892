import React, { useState, useEffect } from "react"
import LanguageIcon from "@mui/icons-material/Language"
import { useIntl } from "react-intl"
import styled from "styled-components"

import { Colors } from "../../../styles/Colors"
import { appLocales } from "../../../i18n"
import messages from "../../../translations/messages"
import { useStore } from "../../../state/store"
import { setLocale } from "../../../state/actions"
import { breakpoints, breakpointSizes } from "../../../utils/menuUtil"
import { STORAGE_LOCALE } from "../../../utils/storageUtil"
import { P } from "../../../styles/Typography"

const StyledLocaleButton = styled.button`
    height: 120px;
    min-width: 90px;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    :hover {
        cursor: pointer;
        //background: ${Colors.aeraPrimaryLightestGrey};
    }
    margin-right: 30px;

    position: absolute;
    right: 110px; //0; // 140px

    ${breakpoints.smallDevice} {
        //width: 20px;
        right: 140px;
    }
    ${breakpoints.mediumDevice} {
        width: 100px;
    }
    @media (max-width: 344px) {
        display: none;
    }
`

const StyledLanguageIcon = styled(LanguageIcon)`
    margin-right: 5px;
`

const StyledLanguageParagraph = styled(P)`
    ${StyledLocaleButton}:hover & {
        font-weight: 500;
    }
`

export const LanguageSelector = () => {
    const intl = useIntl()
    const { state, dispatch } = useStore()
    const currentLocale = state.locale

    const getWindowSize = () => {
        const { innerWidth, innerHeight } = window
        return { innerWidth, innerHeight }
    }

    const [windowSize, setWindowSize] = useState(getWindowSize())

    useEffect(() => {
        const locale = localStorage.getItem(STORAGE_LOCALE)
        if (!locale) {
            dispatch(setLocale("nb"))
        }
    }, [])

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize())
        }
        window.addEventListener("resize", handleWindowResize)
        return () => {
            window.removeEventListener("resize", handleWindowResize)
        }
    }, [])

    const handleClick = (event, language) => {
        dispatch(setLocale(language))
    }

    return appLocales
        .filter((availableLanguage) => availableLanguage !== currentLocale)
        .map((availableLanguage) => {
            return (
                <StyledLocaleButton
                    tabIndex={windowSize?.innerWidth <= breakpointSizes.extraLargeDeviceBreakpointSize ? 3 : null}
                    aria-label={`Switch to language: ` + intl.formatMessage(messages[availableLanguage])}
                    key={availableLanguage}
                    onClick={(e) => handleClick(e, availableLanguage)}
                >
                    <StyledLanguageIcon style={{ fill: Colors.aeraPrimaryDarkGrey }} fontSize="small" aria-hidden="true" />
                    <StyledLanguageParagraph aria-hidden="true">{intl.formatMessage(messages[availableLanguage])}</StyledLanguageParagraph>
                </StyledLocaleButton>
            )
        })
}
