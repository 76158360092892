import React, { useEffect } from "react"
import { setActiveLink, setCodeVerifier, setPath } from "../../state/actions"
import { menuItemsIndex } from "../../utils/menuUtil"
import { PROFILE_PATH } from "../../containers/Header/Menu/routes"
import { AeraSpinner } from "../common/AeraSpinner"
import { SROnlyParagraph, StyledContainer } from "../../styles/styledComponents"
import styled from "styled-components"
import { generateCodeVerifier, getLoginUrl } from "../../utils/loginUtil"
import { useStore } from "../../state/store"

const StyledWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-top: 60px;
    padding-bottom: 120px;
`

const StyledHeaderWrapper = styled.div`
    margin-bottom: -40px;
    height: 40vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`

/*
 * Component used for initiating oidc flow:
 * Shortcut to login, i.e. when redirected from another page
 * Redirects user to Aera IDP for authentication
 * */
export const DirectLogin = () => {
    const { state, dispatch } = useStore()

    useEffect(() => {
        const handleLoginFromURLParam = async () => {
            const oldVerifier = state.oldCodeVerifier
            let verifier = generateCodeVerifier()
            if (verifier === oldVerifier) {
                verifier = generateCodeVerifier()
            }
            dispatch(setCodeVerifier(verifier))
            const url = await getLoginUrl(verifier)
            if (url) {
                dispatch(setActiveLink(menuItemsIndex.PROFILE_PAGE))
                dispatch(setPath(PROFILE_PATH))
                window.location.href = url
            }
        }
        handleLoginFromURLParam()
    }, [])

    return (
        <StyledContainer>
            <StyledWrapper>
                <StyledHeaderWrapper>
                    <div aria-hidden="true">
                        <AeraSpinner />
                    </div>
                    <SROnlyParagraph aria-live="polite">Loading...</SROnlyParagraph>
                </StyledHeaderWrapper>
            </StyledWrapper>
        </StyledContainer>
    )
}
