import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useNavigate, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import styled from 'styled-components'

import messages from '../../../../translations/messages'
import {
	StyledAlertSection,
	StyledAppendiceImagesWrapper,
	StyledAppendiceImageWrapper,
	StyledColumn,
	StyledContainer,
	StyledMainSection,
	StyledMetadataSection,
	StyledRow
} from '../../../../styles/styledComponents'
import { Colors } from '../../../../styles/Colors'
import { Button } from '../../../common/Button'
import { CONTACT_PATH_LOG, CONTACT_PATH_LOG_LIST, LOGIN_HOME_PATH, PROFILE_PATH } from '../../../../containers/Header/Menu/routes'
import { fetchLoginUrlFromTimeout, useStore } from '../../../../state/store'
import { generateCodeVerifier, getLoginUrl } from '../../../../utils/loginUtil'
import { setActiveLink, setCodeVerifier, setFetchedLoginFromTimeoutUrl, setPath } from '../../../../state/actions'
import { breakpoints, getMenuItemIndexByPath, getPageIdByMenuItemIndex, menuItemsIndex } from '../../../../utils/menuUtil'
import { backend } from '../../../../api/backend'
import { StatusBadge } from '../../../common/StatusBadge'
import { Placeholder } from '../../../common/Form/Placeholder'
import { TextAreaField } from '../../../common/Form/TextAreaField'
import { Comment } from '../../../common/Form/Comment'
import AddCommentIcon from '@mui/icons-material/AddComment'
import { convertTimeStampToLocalTimeZone } from '../../../../utils/formatUtil'
import { renderNetworkError, renderTimeoutError } from '../../../../utils/feedbackUtil'
import { statusCodes } from '../../../../utils/networkUtil'
import { supportInquiryStatuses } from '../../../../utils/statusUtil'
import { fileIsOverMaxSize, isTextValid } from '../../../../utils/validationUtil'
import { STORAGE_CONTACT_DETAILS_ATTACHMENTS, STORAGE_CONTACT_DETAILS_COMMENTS, STORAGE_CONTACT_DETAILS_TICKET } from '../../../../utils/storageUtil'
import { FileInputField } from '../../../common/Form/FileInputField'
import { AeraAlert } from '../../../common/Feedback/AeraAlert'
import { H1, H2, H3, P } from '../../../../styles/Typography'

const ContactLogStyledColumn = styled(StyledColumn)`
	margin-bottom: 12px;
`

const StyledSummary = styled.summary`
	font-size: 1.4rem;
	margin-bottom: 14px;
	cursor: pointer;
`

const StyledCommentIcon = styled(AddCommentIcon)`
	margin-right: 8px;
	margin-top: 4px;
`

const StyledDetailWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: start;
	max-width: 1200px;
	flex-wrap: wrap;

	margin-top: -20px;
	${breakpoints.smallDevice} {
		margin-top: -58px;
	}
`

const StyledHeader = styled.div`
	max-width: 100%;
	background: ${Colors.aeraPrimaryLightestGrey};

	margin-bottom: 26px;

	padding: 50px 40px 30px 40px;
	${breakpoints.smallDevice} {
		padding: 60px 80px 40px 80px;
	}
`

const StyledGoBackButton = styled(Button)`
	margin-left: -10px;
	margin-top: -16px;
`

const StyledTitleWrapper = styled.div`
	${breakpoints.smallDevice} {
		max-width: 900px;
	}
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: space-between;
	flex-wrap: wrap;
`

const StyledStatusBadge = styled(StatusBadge)`
	//margin-top: 10px !important;
`

const StyledNewCommentFormWrapper = styled.div`
	margin-top: -16px;
`

const StyledDateSection = styled.div`
	margin-top: -12px;
	margin-bottom: 24px;
`

const StyledCreatedParagraph = styled.p`
	font-size: 0.8rem;
	margin-bottom: -8px;
`

const StyledNoCommentsSection = styled.div`
	margin-top: -16px;
`

const StyledAppendixWrapper = styled.div`
	margin-bottom: 16px;
`

const StyledDetailSection = styled.div`
	margin-right: 90px;
`

const StyledCommentSection = styled.div`
	margin-top: 6px;
`

const StyledAppendiceSection = styled.div`
	margin-bottom: 34px;
`

export const ContactLog = () => {
	const intl = useIntl()
	const location = useLocation()
	const { dispatch, state } = useStore()
	const navigate = useNavigate()

	const pageId = getPageIdByMenuItemIndex(menuItemsIndex.CONTACT_PAGE_LOG_DETAILS)

	const getCommentsFromSessionStorage = () => {
		return JSON.parse(sessionStorage.getItem(STORAGE_CONTACT_DETAILS_COMMENTS))
	}
	const getAttachmentsFromSessionStorage = () => {
		return JSON.parse(sessionStorage.getItem(STORAGE_CONTACT_DETAILS_ATTACHMENTS))
	}
	const getTicketFromSessionStorage = () => {
		return JSON.parse(sessionStorage.getItem(STORAGE_CONTACT_DETAILS_TICKET))
	}

	const saveDataInSessionStorage = (comments, attachments) => {
		sessionStorage.setItem(STORAGE_CONTACT_DETAILS_COMMENTS, JSON.stringify(comments))
		sessionStorage.setItem(STORAGE_CONTACT_DETAILS_ATTACHMENTS, JSON.stringify(attachments))
	}

	const saveTicketInSessionStorage = ticket => {
		sessionStorage.setItem(STORAGE_CONTACT_DETAILS_TICKET, JSON.stringify(ticket))
	}

	const id = location?.state?.id || null
	const row = location?.state?.row || null

	const { subject, description, status, updatedDate, creationDate } = row || {}
	const [ticket, setTicket] = useState(getTicketFromSessionStorage() || null)

	const [comments, setComments] = useState(getCommentsFromSessionStorage() || null)
	const [commentsLoading, setCommentsLoading] = useState(false)
	const [commentSubmitted, setCommentSubmitted] = useState(false)
	const [attachments, setAttachments] = useState(getAttachmentsFromSessionStorage() || [])
	const [attachmentsLoading, setAttachmentsLoading] = useState(false)
	const [imageSources] = useState([])

	const [tokenExpired, setTokenExpired] = useState(false)
	const [networkError, setNetworkError] = useState(false)
	const [commentNetworkError, setCommentNetworkError] = useState(false)
	const [verifier, setVerifier] = useState(null)

	const [newComment, setNewComment] = useState('')

	const [selectedFileNames, setSelectedFileNames] = useState([])
	const [selectedFiles, setSelectedFiles] = useState([])
	const [showAttachmentError, setShowAttachmentError] = useState(false)
	const [showAttachmentTooBigError, setShowAttachmentTooBigError] = useState(false)
	const [hasSubmittedAttachmentSuccessfully, setHasSubmittedAttachmentSuccessfully] = useState(false)

	const [formErrors, setFormErrors] = useState({})

	useEffect(() => {
		dispatch(setPath(CONTACT_PATH_LOG))
		dispatch(setActiveLink(menuItemsIndex.CONTACT_PAGE_LOG_DETAILS))
	}, [])

	useEffect(() => {
		if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
			const oldVerifier = state.oldCodeVerifier
			let verifier = generateCodeVerifier()
			if (verifier === oldVerifier) {
				verifier = generateCodeVerifier()
			}
			setVerifier(verifier)
			dispatch(setCodeVerifier(verifier))
		}
	}, [tokenExpired])

	useEffect(() => {
		if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
			const fetchData = async () => {
				const url = await getLoginUrl(verifier)
				dispatch(setPath(CONTACT_PATH_LOG))
				dispatch(setActiveLink(menuItemsIndex.CONTACT_PAGE_LOG_DETAILS))
				dispatch(setFetchedLoginFromTimeoutUrl(url))
			}
			fetchData()
		}
	}, [verifier])

	const ticketInfoMissingFromRouting = () => {
		return !subject || !description || !status || !updatedDate || !creationDate
	}

	// TODO: Fix! Not working yet.
	/*const getAttachmentData = attachmentId => {
		if (!attachmentId) return

		const config = {
			headers: {
				Accept: 'application/octet-stream'
			}
		}
		backend
			.get(`/ticket/attachment/${id}/${attachmentId}`, config)
			.then(response => {
				const attachment = attachments?.find(attachment => attachment.attachmentId === attachmentId)
				const attachmentType = attachment?.mimeType
				if (!attachment || !attachmentType) return

				const blob = new Blob([response.data], { type: attachmentType })
				const imageUrl = URL.createObjectURL(blob)
				document.getElementById('image').setAttribute('src', URL.createObjectURL(blob))
				//console.log("ImageURL", imageUrl)
				setImageSources(imageSources => [...imageSources, imageUrl])
				//URL.revokeObjectURL(imageUrl)
			})
			.catch(error => {
				const { response = {} } = error
				console.warn('Error retrieving attachment data: ', response)
			})
	}*/

	/*useEffect(() => {
        console.log("Imagesources: ", imageSources)
        console.log("first: ", imageSources[0])
    }, [imageSources])*/

	useEffect(() => {
		setCommentsLoading(true)
		if (ticketInfoMissingFromRouting()) {
			//setTicketIsLoading(true)
		}
		const getContactLogDetails = async () => {
			backend
				.get(`/ticket/${id}`)
				.then(response => {
					const ticket = response.data.ticket
					if (ticketInfoMissingFromRouting()) {
						setTicket(ticket)
						saveTicketInSessionStorage(ticket)
					}
					setComments(ticket?.comments)
					setAttachments(ticket?.attachments)
					// TODO: Fix showing image
					//ticket?.attachments?.map(attachment => getAttachmentData(attachment.attachmentId))

					saveDataInSessionStorage(ticket?.comments, ticket?.attachments)
				})
				.catch(error => {
					const { response = {} } = error
					console.warn('Error retrieving ticket details: ', response)
					if (response?.status === statusCodes.UNAUTHORIZED) {
						setTokenExpired(true)
					} else {
						setNetworkError(true)
					}
				})
				.finally(() => {
					setCommentsLoading(false)
					//setTicketIsLoading(false)
				})
		}
		getContactLogDetails()
	}, [])

	const handleGoBack = fallbackRoute => {
		const hasPreviousPage = window.history.length > 1
		if (hasPreviousPage) {
			navigate(-1)
		} else {
			const fallback = fallbackRoute || (state.isAuthenticated ? PROFILE_PATH : LOGIN_HOME_PATH)
			dispatch(setActiveLink(getMenuItemIndexByPath(fallback, state.isAuthenticated)))
			navigate(fallback)
		}
	}

	const resetFormErrorField = formErrorField => {
		setFormErrors(current => {
			const newObject = { ...current }
			delete newObject[formErrorField]
			return newObject
		})
	}

	const handleSelectedFile = e => {
		resetFormErrorField('appendixErrorMessage')
		setShowAttachmentTooBigError(false)
		const selectedFile = e.target?.files[0]
		if (fileIsOverMaxSize(selectedFile)) {
			setShowAttachmentTooBigError(true)
			return
		}
		if (selectedFile) {
			setSelectedFiles(selectedFiles => [...selectedFiles, selectedFile])
			setSelectedFileNames(selectedFileNames => [...selectedFileNames, selectedFile.name])
		}
	}

	const handleFileInputClick = () => {
		document.getElementById('appendix').click()
	}

	const handleRemoveSelectedFile = selectedFileName => {
		const filteredSelectedFiles = selectedFiles?.filter(file => file.name !== selectedFileName)
		setSelectedFiles(filteredSelectedFiles)
		const filteredSelectedFileNames = selectedFileNames?.filter(fileName => fileName !== selectedFileName)
		setSelectedFileNames(filteredSelectedFileNames)
	}

	/* TODO: Backend delete attachment?
    const handleDeleteAttachment = attachmentId => {
	}*/

	const uploadAttachment = (attachmentFile, index, attachmentsLength) => {
		const formData = new FormData()
		formData.append('file', attachmentFile)

		backend
			.post(`/ticket/${id}/attachment`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(() => {
				setAttachments(attachments => [...attachments, attachmentFile])
				if (index === attachmentsLength - 1) {
					setSelectedFiles([])
					setSelectedFileNames([])
					setHasSubmittedAttachmentSuccessfully(true)
				}
				/*
                TODO Fix image display of attachment
                backend
                    .get(`/ticket/${id}`)
                    .then((response) => {
                        if (response?.data?.ticket?.attachments) {
                            //getAttachmentData(ticket?.attachments?.[0]?.attachmentId)
                        }
                    }).catch(error => console.warn("Could not update attachments: ", error))*/
			})
			.catch(error => {
				const { response = {} } = error
				setShowAttachmentError(true)
				console.warn('Error adding attachment to ticket: ', response)
			})
			.finally(() => {
				setAttachmentsLoading(false)
			})
	}

	const addNewFormErrorMessage = (formError, message) => {
		setFormErrors(formErrors => ({ ...formErrors, [formError]: message }))
	}

	const isAttachmentFormValid = () => {
		let isValid = true
		if (selectedFiles?.length === 0) {
			addNewFormErrorMessage(
				'appendixErrorMessage',
				intl.formatMessage(messages.RequiredFieldError, { field: intl.formatMessage(messages.ContactInputFieldAppendix) })
			)
			isValid = false
		}
		return isValid
	}

	const handleUploadNewAttachment = event => {
		event.preventDefault()
		if (!isAttachmentFormValid()) return
		if (!selectedFiles || selectedFiles?.length === 0) return
		setAttachmentsLoading(true)
		selectedFiles.forEach((selectedFile, index) => uploadAttachment(selectedFile, index, selectedFiles?.length))
	}

	const renderAppendiceImages = () => {
		return (
			<StyledAppendiceImagesWrapper>
				{attachments.map((attachment, index) => (
					<StyledAppendiceImageWrapper key={'attachment-' + index}>
						{imageSources?.length > 0 ? <img alt={'Appendice ' + index} src={imageSources[index]} /> : <P>{attachment.filename || attachment.name || '-'}</P>}
						{/*<ActionButton
                            Icon={<DeleteOutlinedIcon focusable="false" style={{ fill: "red" }} aria-hidden="true" />}
                            ariaLabel="Delete attachment"
                            type="button"
                            onClick={() => handleDeleteAttachment(attachment.attachmentId)}
                        />*/}
					</StyledAppendiceImageWrapper>
				))}
			</StyledAppendiceImagesWrapper>
		)
	}

	const renderAppendices = () => {
		return (
			<StyledAppendiceSection>
				<H3>{intl.formatMessage(messages.ContactInputFieldAppendix)}</H3>
				{/*<img id="image" alt=""/>*/}
				<StyledAppendixWrapper>{attachments?.length > 0 ? renderAppendiceImages() : <p>{intl.formatMessage(messages.NoAppendix)}</p>}</StyledAppendixWrapper>
				<form onSubmit={e => handleUploadNewAttachment(e)}>
					<FileInputField
						autoComplete="off"
						id="appendix"
						label={intl.formatMessage(messages.UploadAdditionalAttachments)}
						selectedFileNames={selectedFileNames}
						handleUpload={() => handleFileInputClick()}
						handleFileChange={e => handleSelectedFile(e)}
						handleRemoveAttachment={selectedFileNames => handleRemoveSelectedFile(selectedFileNames)}
						chooseFileText={intl.formatMessage(messages.FileInputFieldChooseFileText)}
						noFileChosenText={intl.formatMessage(messages.FileInputFieldNoFileChosenText)}
						chosenFileText={intl.formatMessage(messages.FileInputFieldChosenFileText)}
						helpText={intl.formatMessage(messages.ContactInputFieldAppendixHelpText)}
						errorMessage={formErrors?.appendixErrorMessage}
					/>
					<Button
						type="submit"
						isLoading={attachmentsLoading}
						disabled={formErrors?.appendixErrorMessage || status === supportInquiryStatuses.CLOSED_TICKET}
						small
					>
						{!selectedFiles?.length || selectedFiles?.length === 1
							? intl.formatMessage(messages.UploadAttachment)
							: intl.formatMessage(messages.UploadAttachments, { number: selectedFiles?.length ? selectedFiles.length : '' })}
					</Button>
				</form>
			</StyledAppendiceSection>
		)
	}

	const renderSentInForm = () => {
		return (
			<StyledDetailSection>
				<H2>{intl.formatMessage(messages.Details)}</H2>
				<StyledDateSection>
					<StyledCreatedParagraph>
						{intl.formatMessage(messages.Created)}: {getDate(creationDate || ticket?.creationDate || null)}
					</StyledCreatedParagraph>
					<StyledMetadataSection>
						{intl.formatMessage(messages.LastUpdated)}: {getDate(updatedDate || ticket?.updatedDate || null)}
					</StyledMetadataSection>
				</StyledDateSection>
				<ContactLogStyledColumn>
					<Placeholder label={intl.formatMessage(messages.Description)} text={description || ticket?.description || ''} />
				</ContactLogStyledColumn>
				{renderAppendices()}
			</StyledDetailSection>
		)
	}

	const isNewCommentFormValid = () => {
		let isValid = true
		if (!newComment) {
			addNewFormErrorMessage('newCommentErrorMessage', intl.formatMessage(messages.RequiredFieldError, { field: intl.formatMessage(messages.NewComment) }))
			isValid = false
		}
		return isValid
	}

	const handleSubmitComment = event => {
		event.preventDefault()
		if (!isNewCommentFormValid()) return

		setCommentsLoading(true)
		const request = {
			comment: {
				description: newComment || undefined
			}
		}
		backend
			.post(`/ticket/${id}/comment`, request)
			.then(() => {
				setComments(comments => [
					...comments,
					{
						description: newComment,
						creationDate: new Date(),
						author: 'Consumer'
					}
				])
				setCommentSubmitted(true)
			})
			.catch(error => {
				const { response = {} } = error
				console.warn('Error posting new comment to Jira: ', response)
				setCommentNetworkError(true)
			})
			.finally(() => {
				setCommentsLoading(false)
			})
		setNewComment('')
	}

	const handleChange = event => {
		setNewComment(event.target.value)
		if (!isTextValid(event.target.value)) {
			addNewFormErrorMessage('newCommentErrorMessage', intl.formatMessage(messages.TextRegexError))
		} else {
			resetFormErrorField('newCommentErrorMessage')
		}
	}

	const renderNewCommentForm = () => {
		return (
			<StyledNewCommentFormWrapper>
				<form onSubmit={handleSubmitComment}>
					<StyledRow>
						<StyledCommentIcon aria-hidden="true" />
						<StyledColumn>
							<TextAreaField
								rows={3}
								autoComplete="off"
								key="newComment"
								label={intl.formatMessage(messages.NewComment)}
								id="newComment"
								value={newComment}
								onChange={event => handleChange(event)}
								disabled={status === supportInquiryStatuses.CLOSED_TICKET}
								required={true}
								errorMessage={formErrors?.newCommentErrorMessage}
								helpText={`${newComment?.length ? newComment.length : 0} ${intl.formatMessage(messages.Of)} 300 ${intl.formatMessage(messages.MaxCharacters)}`}
							/>
							<Button isLoading={commentsLoading} disabled={formErrors?.newCommentErrorMessage || status === supportInquiryStatuses.CLOSED_TICKET} small>
								{intl.formatMessage(messages.AddNewComment)}
							</Button>
						</StyledColumn>
					</StyledRow>
				</form>
			</StyledNewCommentFormWrapper>
		)
	}

	const renderNewComments = () => {
		return (
			<div>
				<details open>
					<StyledSummary>{intl.formatMessage(messages.Comments)}</StyledSummary>
					<ContactLogStyledColumn>
						{commentsLoading ? (
							<>
								<Comment from="" commentText="" date="" loading={true} />
								<Comment from="" commentText="" date="" loading={true} />
							</>
						) : comments?.length ? (
							comments?.map((comment, index) => (
								<Comment key={index} from={comment.author} commentText={comment.description} date={comment.creationDate} updatedDate={comment.updatedDate} />
							))
						) : (
							<StyledNoCommentsSection>
								<P>{intl.formatMessage(messages.NoCommentsYet)}</P>
							</StyledNoCommentsSection>
						)}
					</ContactLogStyledColumn>
				</details>
			</div>
		)
	}

	const renderComments = () => {
		return (
			<StyledCommentSection>
				{renderNewComments()}
				{renderNewCommentForm()}
			</StyledCommentSection>
		)
	}

	const getDate = date => (date ? convertTimeStampToLocalTimeZone(date) : '-')

	const renderAlerts = () => {
		return (
			<StyledAlertSection>
				{tokenExpired && renderTimeoutError(() => setTokenExpired(false), null, intl)}
				{networkError && renderNetworkError(() => setNetworkError(false), null, intl, true)}
				{commentNetworkError && renderNetworkError(() => setCommentNetworkError(false), null, intl, true)}

				{commentSubmitted && (
					<AeraAlert severity="success" handleClose={() => setCommentSubmitted(false)}>
						{intl.formatMessage(messages.FeedbackNewInquiryCommentSuccess)}
					</AeraAlert>
				)}
				{hasSubmittedAttachmentSuccessfully && (
					<AeraAlert severity="success" handleClose={() => setHasSubmittedAttachmentSuccessfully(false)}>
						{intl.formatMessage(messages.FeedbackNewAttachmentSuccess)}
					</AeraAlert>
				)}
				{showAttachmentError && (
					<AeraAlert severity="error" handleClose={() => setShowAttachmentError(false)}>
						{intl.formatMessage(messages.InformationCouldNotBeSent)}
					</AeraAlert>
				)}
				{showAttachmentTooBigError && (
					<AeraAlert severity="error" handleClose={() => setShowAttachmentTooBigError(false)}>
						{intl.formatMessage(messages.AttachmentTooBig, { size: '10 MB' })}
					</AeraAlert>
				)}
			</StyledAlertSection>
		)
	}

	return (
		<StyledContainer>
			<Helmet>
				<title>
					{intl.formatMessage(messages.Inquiry)} {id}
				</title>
			</Helmet>
			{renderAlerts()}
			<StyledHeader>
				<StyledGoBackButton small back onClick={() => handleGoBack(CONTACT_PATH_LOG_LIST)}>
					{intl.formatMessage(messages.GoBack, { page: intl.formatMessage(messages.ContactMenuMyInquiriesSubOption) })}
				</StyledGoBackButton>
				<StyledTitleWrapper>
					<H1 id={pageId}>
						{subject ? subject : ticket?.subject ? ticket?.subject : intl.formatMessage(messages.Inquiry) + intl.formatMessage(messages.Details)}
					</H1>
					<StyledStatusBadge status={status} />
				</StyledTitleWrapper>
			</StyledHeader>
			<StyledMainSection>
				<StyledDetailWrapper>
					{renderSentInForm()}
					{renderComments()}
				</StyledDetailWrapper>
			</StyledMainSection>
		</StyledContainer>
	)
}
