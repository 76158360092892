import React, { StrictMode } from "react"
import { createRoot } from "react-dom/client"
import { HashRouter } from "react-router-dom"
import FontFaceObserver from "fontfaceobserver"
import { HelmetProvider } from "react-helmet-async"

import "!file-loader?name=[name].[ext]!./assets/images/cropped-aera-favicon-192x192.png"
import "!!style-loader!css-loader!./assets/styles/inter-fonts.css"

import LanguageProvider from "./containers/LanguageProvider"
import { translationMessages } from "./i18n"
import { StoreProvider } from "./state/store"
import { App } from "./containers/App/"
import { ScrollToTop } from "./utils/scrollUtil"

const interObserver = new FontFaceObserver("Inter", {})
// When Inter is loaded, add a font-family using Inter to the body
interObserver.load().then(() => {
    document.body.classList.add("fontLoaded")
})

const MOUNT_NODE = document.getElementById("root")
const root = createRoot(MOUNT_NODE)

const render = (messages) => {
    root.render(
        <StrictMode>
            <StoreProvider>
                <LanguageProvider messages={messages}>
                    <HashRouter history={history}>
                        <HelmetProvider>
                            <ScrollToTop />
                            <App messages={messages} />
                        </HelmetProvider>
                    </HashRouter>
                </LanguageProvider>
            </StoreProvider>
        </StrictMode>
    )
}

if (module.hot) {
    // Hot reloadable React components and translation json files
    // modules.hot.accept does not accept dynamic dependencies,
    // have to be constants at compile-time
    module.hot.accept(["./i18n"], () => {
        root.unmount()
        render(translationMessages)
    })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
    new Promise((resolve) => {
        resolve(import("intl"))
    })
        .then(() => Promise.all([import("intl/locale-data/jsonp/en.js"), import("intl/locale-data/jsonp/nb.js")]))
        .then(() => render(translationMessages))
        .catch((err) => {
            throw err
        })
} else {
    render(translationMessages)
}

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === "production") {
    require("offline-plugin/runtime").install() // eslint-disable-line global-require
}
