import React from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Skeleton } from '@mui/material'

import { SROnlyParagraph, StyledMainSection } from '../../../styles/styledComponents'
import messages from '../../../translations/messages'
import { StyledInfoWrapper } from '../index'
import { H2, H3, P } from '../../../styles/Typography'

const StyledLoadingSkeletonWrapper = styled.div``

export const PersonalInfo = ({ user, isLoading = false }) => {
	const intl = useIntl()

	const { info, address } = user || {}
	const { given, middle, family, mobile, email } = info || {}
	const { line, city, state, postalCode } = address || {}

	const renderLoadingSkeleton = () => {
		return (
			<>
				<StyledLoadingSkeletonWrapper aria-hidden="true">
					<StyledInfoWrapper>
						<Skeleton variant="text" width={60} />
						<Skeleton variant="text" width={120} />
					</StyledInfoWrapper>
					<StyledInfoWrapper>
						<Skeleton variant="text" width={80} />
						<Skeleton variant="text" width={90} />
					</StyledInfoWrapper>
					<StyledInfoWrapper>
						<Skeleton variant="text" width={100} />
						<Skeleton variant="text" width={80} />
					</StyledInfoWrapper>
					<StyledInfoWrapper>
						<Skeleton variant="text" width={70} />
						<Skeleton variant="text" width={120} />
					</StyledInfoWrapper>
					<StyledInfoWrapper>
						<Skeleton variant="text" width={130} />
						<Skeleton variant="text" width={130} />
					</StyledInfoWrapper>
				</StyledLoadingSkeletonWrapper>
				<SROnlyParagraph aria-live="polite">Loading...</SROnlyParagraph>
			</>
		)
	}

	const formatMobileNumber = mobileNumber => {
		if (mobileNumber.startsWith('+')) {
			const first = mobileNumber.substring(0, 3)
			const after = mobileNumber.substring(3)
			return first + ' ' + after
		}
		return mobileNumber
	}

	return (
		<StyledMainSection>
			<H2>{intl.formatMessage(messages.Information)}</H2>
			{isLoading ? (
				renderLoadingSkeleton()
			) : (
				<>
					<StyledInfoWrapper>
						<H3>{intl.formatMessage(messages.ProfileInputFieldName)}</H3>
						{given || middle || family ? (
							<P>
								{given ? given + ' ' : ''}
								{middle ? middle + ' ' : ''}
								{family || ''}
							</P>
						) : (
							<P>-</P>
						)}
					</StyledInfoWrapper>
					<StyledInfoWrapper>
						<H3>{intl.formatMessage(messages.ProfileInputFieldAddress)}</H3>
						<P>{line || '-'}</P>
					</StyledInfoWrapper>
					<StyledInfoWrapper>
						<H3>{intl.formatMessage(messages.ProfileInputFieldPostalCode)}</H3>
						{city || state || postalCode ? (
							<P>
								{postalCode ? postalCode + ' ' : ''}
								{city && city !== state ? city + ' ' : ''}
								{state || ''}
							</P>
						) : (
							<P>-</P>
						)}
					</StyledInfoWrapper>
					<StyledInfoWrapper>
						<H3>{intl.formatMessage(messages.ProfileInputFieldEmail)}</H3>
						<P>{email || '-'}</P>
					</StyledInfoWrapper>
					<StyledInfoWrapper>
						<H3>{intl.formatMessage(messages.ProfileInputFieldPhone)}</H3>
						<P>{mobile ? formatMobileNumber(mobile) : '-'}</P>
					</StyledInfoWrapper>
				</>
			)}
		</StyledMainSection>
	)
}

PersonalInfo.propTypes = {
	user: PropTypes.object,
	isLoading: PropTypes.bool
}

/*
<InputField type="email" id="email" value={email} name="email" label={intl.formatMessage(messages.ProfileInputFieldEmail)} disabled={true} />
<InputField
    pattern="[0-9]*"
    type="tel"
    id="phoneNumber"
    value={phoneNumber}
    name="phoneNumber"
    label={intl.formatMessage(messages.ProfileInputFieldPhone)}
    disabled={true}
/>
<input type="submit" value="Submit" />
*/
