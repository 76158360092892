import React from "react"
import styled from "styled-components"
import { Skeleton } from "@mui/material"

import { SROnlyParagraph } from "../../../styles/styledComponents"

const StyledSkeletonRow = styled.div`
    max-width: 450px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`

const StyledSkeletonWrapper = styled.div`
    margin-bottom: 46px;
    margin-top: 36px;
`

export const InfoSkeleton = () => {
    return (
        <>
            <StyledSkeletonWrapper aria-hidden="true">
                <StyledSkeletonRow>
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={180} />
                </StyledSkeletonRow>
                <StyledSkeletonRow>
                    <Skeleton variant="text" width={200} />
                    <Skeleton variant="text" width={120} />
                </StyledSkeletonRow>
            </StyledSkeletonWrapper>
            <SROnlyParagraph aria-live="polite">Loading...</SROnlyParagraph>
        </>
    )
}
