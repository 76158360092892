import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { useStore } from "../../../state/store"
import { setActiveLink } from "../../../state/actions"
import AeraLogo from "../../../assets/icons/AeraLogo"
import { breakpoints } from "../../../utils/menuUtil"

const StyledAeraLogo = styled(AeraLogo)`
    ${(props) =>
        props.idPrefix === "Footer"
            ? `align-items: start;`
            : `
                margin-top: 8px;
                margin-right: 40px;
                ${breakpoints.smallDevice} {
                     margin-left: 50px;
                }
            `}
    height: 80px;
    margin-left: 0;
    width: 80px;
    ${breakpoints.smallDevice} {
        height: 90px;
        width: 90px;
    }
`

export const AeraLogoLink = ({ footer }) => {
    const { dispatch } = useStore()

    return (
        <a tabIndex={!footer ? 2 : null} onClick={() => dispatch(setActiveLink(null))} aria-label="Aera Homepage" href="https://www.aera.id">
            {footer ? <StyledAeraLogo idPrefix="Footer" aria-hidden="true" /> : <StyledAeraLogo idPrefix="Header" aria-hidden="true" />}
        </a>
    )
}

AeraLogoLink.propTypes = {
    footer: PropTypes.bool
}
