import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useIntl } from 'react-intl'
import styled from 'styled-components'

import messages from '../../translations/messages'
import { getPageIdByMenuItemIndex, menuItemsIndex } from '../../utils/menuUtil'
import { StyledAlertSection, StyledContainer, StyledHeaderSection, StyledMainSection } from '../../styles/styledComponents'
import { Button } from '../common/Button'
import { StyledFormWrapper } from '../common/Form/formStyles'
import { TextAreaField } from '../common/Form/TextAreaField'
import { InputField } from '../common/Form/InputField'
import { FileInputField } from '../common/Form/FileInputField'
import { FlatFeedback } from '../common/Feedback/FlatFeedback'
import { CONTACT_PATH, CONTACT_PATH_LOG_LIST } from '../../containers/Header/Menu/routes'
import { setActiveLink, setCodeVerifier, setFetchedLoginFromTimeoutUrl, setPath } from '../../state/actions'
import { fetchLoginUrlFromTimeout, useStore } from '../../state/store'
import SentMessagesGreen from '../../assets/images/ManyPixels/inbox/SentMessagesGreen.png'
import { statusCodes } from '../../utils/networkUtil'
import { renderTimeoutError } from '../../utils/feedbackUtil'
import { backend } from '../../api/backend'
import { fileIsOverMaxSize, isTextValid } from '../../utils/validationUtil'
import { generateCodeVerifier, getLoginUrl } from '../../utils/loginUtil'
import { AeraAlert } from '../common/Feedback/AeraAlert'
import { H1, P } from '../../styles/Typography'

const StyledButton = styled(Button)`
	margin-top: 12px;
`

export const ContactPage = props => {
	const intl = useIntl()
	const { dispatch, state } = useStore()

	const pageId = getPageIdByMenuItemIndex(menuItemsIndex.CONTACT_PAGE)
	const [subjectLine, setSubjectLine] = useState(undefined)
	const [description, setDescription] = useState(undefined)

	const [selectedFileNames, setSelectedFileNames] = useState([])
	const [selectedFiles, setSelectedFiles] = useState([])

	const [formErrors, setFormErrors] = useState({})
	const [hasSubmittedSuccessfully, setHasSubmittedSuccessfully] = useState(false)
	const [inquiryIsBeingSubmitted, setInquiryIsBeingSubmitted] = useState(false)

	const [showAttachmentError, setShowAttachmentError] = useState(false)
	const [showAttachmentTooBigError, setShowAttachmentTooBigError] = useState(false)
	const [noUserInfo, setNoUserInfo] = useState(false)
	const [networkError, setNetworkError] = useState(false)
	const [tokenExpired, setTokenExpired] = useState(false)
	const [verifier, setVerifier] = useState(null)

	useEffect(() => {
		dispatch(setPath(CONTACT_PATH))
		dispatch(setActiveLink(menuItemsIndex.CONTACT_PAGE))
	}, [])

	useEffect(() => {
		if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
			const oldVerifier = state.oldCodeVerifier
			let verifier = generateCodeVerifier()
			if (verifier === oldVerifier) {
				verifier = generateCodeVerifier()
			}
			setVerifier(verifier)
			dispatch(setCodeVerifier(verifier))
		}
	}, [tokenExpired])

	useEffect(() => {
		if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
			const fetchData = async () => {
				const url = await getLoginUrl(verifier)
				dispatch(setPath(CONTACT_PATH))
				dispatch(setActiveLink(menuItemsIndex.CONTACT_PAGE))
				dispatch(setFetchedLoginFromTimeoutUrl(url))
			}
			fetchData()
		}
	}, [verifier])

	const hasValue = formElement => {
		return !!formElement
	}

	const addNewFormErrorMessage = (formError, message) => {
		setFormErrors(formErrors => ({ ...formErrors, [formError]: message }))
	}

	const isFormValid = (subjectLine, description) => {
		let isValid = true
		if (!hasValue(subjectLine)) {
			addNewFormErrorMessage(
				'subjectLineErrorMessage',
				intl.formatMessage(messages.RequiredFieldError, { field: intl.formatMessage(messages.ContactInputFieldSubjectLine) })
			)
			isValid = false
		}
		if (!hasValue(description)) {
			addNewFormErrorMessage(
				'descriptionErrorMessage',
				intl.formatMessage(messages.RequiredFieldError, { field: intl.formatMessage(messages.ContactInputFieldDescription) })
			)
			isValid = false
		}
		return isValid
	}

	const resetFormErrorField = formErrorField => {
		setFormErrors(current => {
			const newObject = { ...current }
			delete newObject[formErrorField]
			return newObject
		})
	}

	const uploadAttachment = (selectedFile, ticketId) => {
		const formData = new FormData()
		formData.append('file', selectedFile)
		backend
			.post(`/ticket/${ticketId}/attachment`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' }
			})
			.then(response => console.debug('Attachment response: ', response.data))
			.catch(error => {
				const { response = {} } = error
				setShowAttachmentError(true)
				console.warn('Error adding attachment to ticket: ', response)
			})
	}

	const handleSubmitContactForm = event => {
		event.preventDefault()

		if (!isFormValid(subjectLine, description)) return
		const request = {
			ticket: {
				subject: subjectLine || undefined,
				description: description || undefined
			}
		}
		setInquiryIsBeingSubmitted(true)
		backend
			.post('/ticket', request)
			.then(response => {
				setInquiryIsBeingSubmitted(false)
				setHasSubmittedSuccessfully(true)
				const ticketId = response.data.ticket?.id
				if (!selectedFiles || selectedFiles?.length === 0) {
					return
				}
				selectedFiles.forEach(selectedFile => uploadAttachment(selectedFile, ticketId))
			})
			.catch(error => {
				const { response = {} } = error
				console.warn('Error during support inquiry POST: ', response)
				setInquiryIsBeingSubmitted(false)
				if (response?.status === statusCodes.UNAUTHORIZED) {
					setTokenExpired(true)
				} else if (response?.status === statusCodes.NOT_FOUND) {
					setNoUserInfo(true)
				} else {
					setNetworkError(true)
				}
			})
	}

	const handleSubjectLineChange = value => {
		if (!isTextValid(value)) {
			addNewFormErrorMessage('subjectLineErrorMessage', intl.formatMessage(messages.TextRegexError))
		} else {
			resetFormErrorField('subjectLineErrorMessage')
		}
		setSubjectLine(value)
	}

	const handleDescriptionChange = value => {
		if (!isTextValid(value)) {
			addNewFormErrorMessage('descriptionErrorMessage', intl.formatMessage(messages.TextRegexError))
		} else {
			resetFormErrorField('descriptionErrorMessage')
		}
		setDescription(value)
	}

	const fields = [
		{
			id: 'subjectLine',
			label: `${intl.formatMessage(messages.ContactInputFieldSubjectLine)} (${intl.formatMessage(messages.Required)})`,
			size: '40',
			required: true,
			value: subjectLine,
			onChange: event => handleSubjectLineChange(event.target.value),
			errorMessage: formErrors?.subjectLineErrorMessage
		},
		{
			id: 'description',
			label: `${intl.formatMessage(messages.ContactInputFieldDescription)} (${intl.formatMessage(messages.Required)})`,
			rows: 4,
			cols: 40,
			type: 'textArea',
			required: true,
			value: description,
			onChange: event => handleDescriptionChange(event.target.value),
			errorMessage: formErrors?.descriptionErrorMessage,
			helpText: `${description?.length ? description.length : 0} ${intl.formatMessage(messages.Of)} 300 ${intl.formatMessage(messages.MaxCharacters)}`,
			maxLength: '300'
		},
		{
			id: 'appendix',
			label: intl.formatMessage(messages.ContactInputFieldAppendix),
			type: 'file',
			accept: 'image/png, image/jpeg, application/pdf',
			handleUpload: () => handleFileInputClick(),
			handleSelectedFile: e => handleSelectedFile(e),
			handleRemoveAttachment: selectedFileNames => handleRemoveSelectedFile(selectedFileNames),
			chooseFileText: intl.formatMessage(messages.FileInputFieldChooseFileText),
			noFileChosenText: intl.formatMessage(messages.FileInputFieldNoFileChosenText),
			chosenFileText: intl.formatMessage(messages.FileInputFieldChosenFileText),
			helpText: intl.formatMessage(messages.ContactInputFieldAppendixHelpText)
		}
	]

	const handleSelectedFile = e => {
		setShowAttachmentTooBigError(false)
		const selectedFile = e.target?.files[0]
		if (fileIsOverMaxSize(selectedFile)) {
			setShowAttachmentTooBigError(true)
			return
		}
		if (selectedFile) {
			/*if (selectedFiles.some((file) => file.name === selectedFile.name)) {
                selectedFile.name = selectedFile.name + " (2)"
            }*/
			setSelectedFiles(selectedFiles => [...selectedFiles, selectedFile])
			setSelectedFileNames(selectedFileNames => [...selectedFileNames, selectedFile.name])
		}
	}

	const handleFileInputClick = () => {
		document.getElementById('appendix').click()
	}

	const handleRemoveSelectedFile = selectedFileName => {
		const filteredSelectedFiles = selectedFiles?.filter(file => file.name !== selectedFileName)
		setSelectedFiles(filteredSelectedFiles)
		const filteredSelectedFileNames = selectedFileNames?.filter(fileName => fileName !== selectedFileName)
		setSelectedFileNames(filteredSelectedFileNames)
		document.getElementById('appendix').value = ''
	}

	const objectIsEmpty = object => {
		if (!object) return true
		return Object.keys(object).length === 0
	}

	const renderContactForm = () => {
		if (hasSubmittedSuccessfully) {
			return (
				<FlatFeedback
					image={{
						imageSrc: SentMessagesGreen,
						imageAlt: 'Mail sent'
					}}
					headerText={intl.formatMessage(messages.ContactFormSuccessLabel)}
					paragraphText={intl.formatMessage(messages.ContactFormSuccessText)}
					link={{
						linkText: intl.formatMessage(messages.ContactMenuMyInquiriesSubOption),
						linkTo: CONTACT_PATH_LOG_LIST,
						handleClick: () => dispatch(setActiveLink(menuItemsIndex.CONTACT_PAGE_LOG))
					}}
				/>
			)
		}

		return (
			<form onSubmit={handleSubmitContactForm}>
				<StyledFormWrapper>
					{fields.map(field => {
						switch (field.type) {
							case 'textArea':
								return (
									<TextAreaField
										key={field.id}
										autoComplete="off"
										label={field.label}
										id={field.id}
										rows={field.rows}
										cols={field.cols}
										disabled={field.disabled}
										helpText={field.helpText}
										defaultValue={field.defaultValue}
										errorMessage={field.errorMessage}
										{...field}
									/>
								)
							case 'file':
								return (
									<FileInputField
										key={field.id}
										autoComplete="off"
										id={field.id}
										label={field.label}
										selectedFileNames={selectedFileNames}
										handleUpload={field.handleUpload}
										handleFileChange={field.handleSelectedFile}
										handleRemoveAttachment={field.handleRemoveAttachment}
										chooseFileText={field.chooseFileText}
										noFileChosenText={field.noFileChosenText}
										chosenFileText={field.chosenFileText}
										helpText={field.helpText}
										{...props}
									/>
								)
							default:
								return (
									<InputField
										autoComplete="off"
										key={field.id}
										label={field.label}
										id={field.id}
										type={field.type}
										size={field.size}
										disabled={field.disabled}
										helpText={field.helpText}
										pattern={field.pattern}
										accept={field.accept}
										defaultValue={field.defaultValue}
										required={field.required}
										errorMessage={field.errorMessage}
										{...field}
									/>
								)
						}
					})}
					<StyledButton isLoading={inquiryIsBeingSubmitted} disabled={!objectIsEmpty(formErrors)} type="submit" value="Submit">
						{intl.formatMessage(messages.ContactFormButtonLabel)}
					</StyledButton>
				</StyledFormWrapper>
			</form>
		)
	} //inquiryIsBeingSubmitted

	const renderAlerts = () => {
		return (
			<StyledAlertSection>
				{(tokenExpired || noUserInfo || networkError) &&
					renderTimeoutError(() => setTokenExpired(false), intl.formatMessage(messages.InformationCouldNotBeSent), intl)}
				{showAttachmentError && (
					<AeraAlert severity="warning" handleClose={() => setShowAttachmentError(false)}>
						{intl.formatMessage(messages.FeedbackAttachmentCouldNotBeSent)}
					</AeraAlert>
				)}
				{showAttachmentTooBigError && (
					<AeraAlert severity="error" handleClose={() => () => setShowAttachmentTooBigError(false)}>
						{intl.formatMessage(messages.AttachmentTooBig, { size: '10 MB' })}
					</AeraAlert>
				)}
			</StyledAlertSection>
		)
	}

	return (
		<StyledContainer aria-labelledby={pageId}>
			<Helmet>
				<title>{intl.formatMessage(messages.ContactMenuNewInquirySubOption)}</title>
			</Helmet>
			{renderAlerts()}
			{!hasSubmittedSuccessfully && (
				<StyledHeaderSection>
					<H1 id={pageId}>{intl.formatMessage(messages.ContactTitle)}</H1>
					<P>{intl.formatMessage(messages.ContactDescription)}</P>
				</StyledHeaderSection>
			)}
			<StyledMainSection>{renderContactForm()}</StyledMainSection>
		</StyledContainer>
	)
}
