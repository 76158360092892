/**
 * i18n.js
 *
 * This will setup the i18n language files and locale data for your app.
 *
 *   IMPORTANT: This file is used by the internal build
 *   script `extract-intl`, and must use CommonJS module syntax
 *   You CANNOT use import/export in this file.
 */
const consumerEn = require("./translations/en.json")
const consumerNb = require("./translations/nb.json")

const enTranslationMessages = Object.assign({}, consumerEn)
const nbTranslationMessages = Object.assign({}, consumerNb)

if (!Intl.PluralRules) {
    // eslint-disable-next-line global-require
    require("@formatjs/intl-pluralrules/polyfill")
    // eslint-disable-next-line global-require
    require("@formatjs/intl-pluralrules/locale-data/nb")
    // eslint-disable-next-line global-require
    require("@formatjs/intl-pluralrules/locale-data/en")
}

if (!Intl.RelativeTimeFormat) {
    // eslint-disable-next-line global-require
    require("@formatjs/intl-relativetimeformat/polyfill")
    // eslint-disable-next-line global-require
    require("@formatjs/intl-relativetimeformat/locale-data/nb")
    // eslint-disable-next-line global-require
    require("@formatjs/intl-relativetimeformat/locale-data/en")
}

const DEFAULT_LOCALE = "nb"

// prettier-ignore
const appLocales = [
    'en',
    'nb',
];

const formatTranslationMessages = (locale, messages) => {
    const defaultFormattedMessages = locale !== DEFAULT_LOCALE ? formatTranslationMessages(DEFAULT_LOCALE, nbTranslationMessages) : {}
    const flattenFormattedMessages = (formattedMessages, key) => {
        const formattedMessage = !messages[key] && locale !== DEFAULT_LOCALE ? defaultFormattedMessages[key] : messages[key]
        return Object.assign(formattedMessages, { [key]: formattedMessage })
    }
    return Object.keys(messages).reduce(flattenFormattedMessages, {})
}

const translationMessages = {
    en: formatTranslationMessages("en", enTranslationMessages),
    nb: formatTranslationMessages("nb", nbTranslationMessages)
}

export { appLocales, formatTranslationMessages, translationMessages, DEFAULT_LOCALE }
