import React from "react"
import styled, { css } from "styled-components"

const baseHamburgerRowStyle = css`
    height: 5px;
    background-color: #007b3c;
    margin: 2px 0;
`

const StyledHamburgerRowOne = styled.div`
    ${baseHamburgerRowStyle};
    width: 22px;
`

const StyledHamburgerRowTwo = styled.div`
    ${baseHamburgerRowStyle};
    width: 17px;
`

const StyledHamburgerRowThree = styled.div`
    ${baseHamburgerRowStyle};
    width: 12px;
`

const StyledHamburgerWrapper = styled.div`
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
`

export const HamburgerIcon = () => {
    return (
        <StyledHamburgerWrapper aria-hidden="true">
            <StyledHamburgerRowOne />
            <StyledHamburgerRowTwo />
            <StyledHamburgerRowThree />
        </StyledHamburgerWrapper>
    )
}
