import React, { useState, useEffect } from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"

import messages from "../../translations/messages"
import { useStore } from "../../state/store"
import { StyledContainer, StyledMainSection } from "../../styles/styledComponents"
import { breakpoints, getPageIdByMenuItemIndex, menuItemsIndex } from "../../utils/menuUtil"
import { getLoginUrl } from "../../utils/loginUtil"
import { Colors } from "../../styles/Colors"

import LogoutDreaming from "../../assets/images/ManyPixels/logout/LogoutDreaming.png"
import { setActiveLink, setFetchedLoginUrl, setPath } from "../../state/actions"
import { PROFILE_PATH } from "../../containers/Header/Menu/routes"
import { setCodeVerifier } from "../../state/actions"
import { generateCodeVerifier } from "../../utils/loginUtil"
import { Helmet } from "react-helmet-async"
import { A, H2 } from "../../styles/Typography"

const StyledWrapper = styled(StyledMainSection)`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 160px;
    background-color: ${Colors.aeraWhite};
`

const StyledImage = styled.img`
    width: 200px;
    margin-bottom: 16px;
    margin-top: -16px;
    ${breakpoints.mediumDevice} {
        width: 250px;
    }
    ${breakpoints.largeDevice} {
        width: 300px;
    }
`

const StyledTextWrapper = styled.div`
    max-width: 500px;
    text-align: center;
`

export const LoggedOutPage = () => {
    const intl = useIntl()
    const { state, dispatch } = useStore()

    const [verifier, setVerifier] = useState(null)
    const [loginUrl, setLoginUrl] = useState("")

    const pageId = getPageIdByMenuItemIndex(menuItemsIndex.LOGOUT_PAGE)

    useEffect(() => {
        const oldVerifier = state.oldCodeVerifier
        let verifier = generateCodeVerifier()
        if (verifier === oldVerifier) {
            verifier = generateCodeVerifier()
        }
        setVerifier(verifier)
        dispatch(setCodeVerifier(verifier))
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            const url = await getLoginUrl(verifier)
            setLoginUrl(url)
            dispatch(setFetchedLoginUrl(url))
        }
        fetchData()
    }, [verifier])

    const setPathAndLink = (path, index) => {
        dispatch(setPath(path))
        dispatch(setActiveLink(index))
    }

    return (
        <StyledContainer aria-labelledby={pageId} $isLoggedIn={state.isAuthenticated}>
            <Helmet>
                <title>{intl.formatMessage(messages.LoggedOut)}</title>
            </Helmet>
            <StyledWrapper>
                <StyledImage src={LogoutDreaming} alt={"Dreaming"} />
                <StyledTextWrapper>
                    <H2>{intl.formatMessage(messages.BeenLoggedOut)}</H2>
                    <A href={loginUrl} onClick={() => setPathAndLink(PROFILE_PATH, menuItemsIndex.PROFILE_PAGE)}>
                        {intl.formatMessage(messages.ClickToReLogIn)}
                    </A>
                </StyledTextWrapper>
            </StyledWrapper>
        </StyledContainer>
    )
}
