import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import HelpIcon from '@mui/icons-material/Help'

import messages from '../../../../translations/messages'
import { Skeleton } from '@mui/material'
import { SROnlyParagraph } from '../../../../styles/styledComponents'
import { H3, P } from '../../../../styles/Typography'

const StyledInputWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
`

const StyledRadioButtonsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: start;
`

const StyledRadioButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: start;
	justify-content: start;
	margin-right: 30px;
`

const StyledLabel = styled.label`
	margin-bottom: 3px;
`

const StyledInputField = styled.input`
	padding: 10px;
	margin-bottom: 20px;
	font-size: 18px;
	margin-left: 10px;
	margin-top: 9px;
	:disabled {
		cursor: not-allowed;
	}
`

const StyledLabelWrapper = styled.div`
	margin-bottom: 6px;
`

const StyledHelpButton = styled.button`
	border: 0;
	background-color: transparent;
`

const StyledHelpIcon = styled(HelpIcon)`
	margin-bottom: -5px;
	margin-left: 5px;
	:hover {
		cursor: pointer;
		filter: invert(25%);
	}
	:focus {
		cursor: pointer;
	}
`

const StyledSkeletonLabelWrapper = styled.div`
	margin-right: 10px;
	margin-top: -5px;
`

export const RadioButtonField = ({ name, label, value, disabled, helpText, isLoading, helpIcon, onHelpClick, helpTextForButton, ariaButton }) => {
	const intl = useIntl()

	const renderLoadingSkeleton = () => {
		return (
			<>
				<div aria-hidden="true">
					<StyledLabelWrapper>
						<Skeleton variant="text" width={300} />
					</StyledLabelWrapper>
					<StyledRadioButtonsWrapper>
						<StyledRadioButtonWrapper>
							<StyledSkeletonLabelWrapper>
								<Skeleton variant="text" width={30} />
							</StyledSkeletonLabelWrapper>
							<Skeleton variant="circular" width={20} />
						</StyledRadioButtonWrapper>
						<StyledRadioButtonWrapper>
							<StyledSkeletonLabelWrapper>
								<Skeleton variant="text" width={30} />
							</StyledSkeletonLabelWrapper>
							<Skeleton variant="circular" width={20} />
						</StyledRadioButtonWrapper>
					</StyledRadioButtonsWrapper>
				</div>
				<SROnlyParagraph aria-live="polite">Loading...</SROnlyParagraph>
			</>
		)
	}

	return (
		<StyledInputWrapper>
			{isLoading ? (
				renderLoadingSkeleton()
			) : (
				<div role="radiogroup" aria-labelledby="group_label" id={`${name}-radiogroup`}>
					<StyledLabelWrapper>
						<H3 id="group_label">
							{label}
							{helpIcon && (
								<StyledHelpButton aria-pressed={ariaButton} aria-label={helpTextForButton} onClick={() => onHelpClick()}>
									<StyledHelpIcon aria-hidden="true" />
								</StyledHelpButton>
							)}
						</H3>
					</StyledLabelWrapper>
					<StyledRadioButtonsWrapper>
						<StyledRadioButtonWrapper>
							<StyledLabel htmlFor={`${name}-yes`}>{intl.formatMessage(messages.Yes)}</StyledLabel>
							<StyledInputField
								id={`${name}-yes`}
								name={`${name}-yes`}
								checked={value === true}
								aria-checked={value === true}
								value={true}
								disabled={disabled || false}
								type="radio"
							/>
						</StyledRadioButtonWrapper>
						<StyledRadioButtonWrapper>
							<StyledLabel htmlFor={`${name}-no`}>{intl.formatMessage(messages.No)}</StyledLabel>
							<StyledInputField
								id={`${name}-no`}
								name={`${name}-no`}
								checked={value === false}
								aria-checked={value === true}
								value={false}
								disabled={disabled || false}
								type="radio"
							/>
						</StyledRadioButtonWrapper>
					</StyledRadioButtonsWrapper>
					{helpText && <P>{helpText}</P>}
				</div>
			)}
		</StyledInputWrapper>
	)
}

RadioButtonField.propTypes = {
	id: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	value: PropTypes.any,
	disabled: PropTypes.bool,
	helpText: PropTypes.string,
	type: PropTypes.string,
	isLoading: PropTypes.bool,
	helpIcon: PropTypes.bool,
	onHelpClick: PropTypes.func,
	helpTextForButton: PropTypes.string,
	ariaButton: PropTypes.bool
}
