import React from "react"
import styled from "styled-components"
import { useIntl } from "react-intl"
import CloseIcon from "@mui/icons-material/Close"

import { HamburgerIcon } from "../../../assets/icons/HamburgerIcon"
import { toggleMenu } from "../../../state/actions"
import { useStore } from "../../../state/store"
import { Colors } from "../../../styles/Colors"
import messages from "../../../translations/messages"
import { breakpoints } from "../../../utils/menuUtil"
import { focusVisibleBaseStyles } from "../../../styles/styledComponents"
import { P } from "../../../styles/Typography"

const StyledMenuButton = styled.button`
    position: absolute;
    right: 0; // 140px;
    top: 0;
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 42px 20px 42px 30px;

    :focus-visible {
        ${focusVisibleBaseStyles};
        font-weight: 500;
    }
    :hover {
        cursor: pointer;
        font-weight: 500;
    }
    ${breakpoints.smallDevice} {
        padding: 42px 30px 42px 40px;
    }
    ${breakpoints.extraLargeDevice} {
        display: none;
    }
    ${(props) =>
        props.colMenuOpen &&
        `
            background-color: ${Colors.aeraPrimaryLightestGrey};
        `}
`

const StyledMenuText = styled(P)`
    margin-right: 10px;
    ${StyledMenuButton}:hover & {
        font-weight: 500;
    }
`

export const HamburgerIconContainer = () => {
    const intl = useIntl()
    const { state, dispatch } = useStore()

    return (
        <StyledMenuButton
            id="menuButton"
            $colMenuOpen={state.menuIsOpen}
            aria-label="Menu"
            onClick={() => dispatch(toggleMenu())}
            aria-expanded={state.menuIsOpen.toString()}
        >
            <StyledMenuText aria-hidden="true">{state.menuIsOpen ? intl.formatMessage(messages.Close) : intl.formatMessage(messages.Menu)}</StyledMenuText>
            {state.menuIsOpen ? <CloseIcon aria-hidden="true" style={{ fill: Colors.aeraPrimaryDarkGreen }} sx={{ fontSize: 35 }} /> : <HamburgerIcon />}
        </StyledMenuButton>
    )
}
