import React from "react"
import PropTypes from "prop-types"
import ErrorIcon from "@mui/icons-material/Error"
import styled from "styled-components"

import { StyledRow } from "../../formStyles"
import { Colors } from "../../../../../styles/Colors"
import { P2 } from "../../../../../styles/Typography"

const StyledErrorIcon = styled(ErrorIcon)`
    margin-right: 6px;
    margin-top: 1px;
`

const StyledContainer = styled.div`
    margin-top: 8px;
`

const StyledErrorText = styled(P2)`
    color: ${Colors.aeraError};
`

export const FieldError = ({ id, errorMessage }) => {
    return (
        <StyledContainer>
            <StyledRow>
                <StyledErrorIcon style={{ fill: Colors.aeraError }} fontSize="small" aria-hidden="true" />
                <StyledErrorText aria-live="polite" id={id}>
                    {errorMessage}
                </StyledErrorText>
            </StyledRow>
        </StyledContainer>
    )
}

FieldError.propTypes = {
    id: PropTypes.string.isRequired,
    errorMessage: PropTypes.string.isRequired,
    assertive: PropTypes.bool
}
