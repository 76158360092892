import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import { Skeleton } from '@mui/material'
import PersonIcon from '@mui/icons-material/Person'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'

import { Colors } from '../../../../styles/Colors'
import messages from '../../../../translations/messages'
import { SROnlyParagraph } from '../../../../styles/styledComponents'
import { convertTimeStampToLocalTimeZone, convertTimeStampToLocalTimeZoneHours, dateIsToday, dateIsYesterday } from '../../../../utils/formatUtil'
import { P } from '../../../../styles/Typography'
import { breakpoints } from '../../../../utils/menuUtil'

const StyledCommentWrapper = styled.div`
	margin-top: 20px;
	margin-left: 32px;
	margin-bottom: 26px;
	border: 1px solid ${Colors.aeraPrimaryGrey};
	border-radius: 5px;
	display: flex;
	align-items: center;
	max-width: 228px;
	word-break: break-word;
	${breakpoints.smallDevice} {
		min-width: 380px;
	}
`

const StyledParagraph = styled.p`
	font-size: 0.8rem;
	margin-top: 0;
	margin-left: 5px;
`

const StyledSmallParagraph = styled.p`
	font-size: 0.75rem;
	margin-top: 0;
	margin-left: 5px;
	font-weight: 450;
	letter-spacing: 0.01rem;
`

const StyledCommentText = styled.div`
	background-color: ${Colors.aeraWhite};
	border-radius: inherit;
	max-width: 662px;
`

const StyledCommentParagraph = styled(P)`
	padding: 20px;
`

const StyledCommentUserCircle = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 8px;
	margin-bottom: 0;
	background-color: ${Colors.aeraPrimaryDarkGreen};
	border: 1px solid ${Colors.aeraPrimaryGreen};
`

const StyledCommentSenderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: baseline;
	justify-content: start;
`

const StyledFromSection = styled.div`
	display: flex;
	align-items: center;
	justify-content: start;
	margin-bottom: -14px;
`

const StyledCommentSkeleton = styled(Skeleton)`
	margin-left: 38px;
	margin-top: -20px !important;
`

const StyledFromSkeleton = styled(Skeleton)`
	margin-left: 7px;
`

const StyledUpdatedDateWrapper = styled.div`
	margin-top: 12px;
	margin-left: 34px;
	margin-bottom: -12px;
`

export const Comment = ({ from, commentText, updatedDate, date, loading }) => {
	const intl = useIntl()

	const commentSenders = {
		AERA: 'Aera',
		CONSUMER: 'Consumer'
	}

	const fromSupport = from === commentSenders.AERA
	const fromConsumer = from === commentSenders.CONSUMER
	const sender = fromConsumer ? intl.formatMessage(messages.You) : fromSupport ? 'Aera' : ''

	const formatDate = date => {
		if (!date) return
		if (dateIsToday(date)) {
			return intl.formatMessage(messages.Today) + convertTimeStampToLocalTimeZoneHours(date)
		} else if (dateIsYesterday(date)) {
			return intl.formatMessage(messages.Yesterday) + convertTimeStampToLocalTimeZoneHours(date)
		} else return convertTimeStampToLocalTimeZone(date)
	}

	const renderCommentLoadingSkeleton = () => {
		return (
			<>
				<div aria-hidden="true">
					<StyledFromSection>
						<Skeleton animation="pulse" variant="circular" width={30} height={30} />
						<StyledFromSkeleton animation="pulse" variant="text" width={30} height={30} />
					</StyledFromSection>
					<StyledCommentSkeleton animation="pulse" variant="text" width={300} height={150} />
				</div>
				<SROnlyParagraph aria-live="polite">Loading...</SROnlyParagraph>
			</>
		)
	}

	return loading ? (
		renderCommentLoadingSkeleton()
	) : (
		<>
			<StyledFromSection>
				<StyledCommentUserCircle fromSupport={fromSupport}>
					{fromSupport ? (
						<SupportAgentIcon aria-hidden="true" style={{ fill: Colors.aeraWhite }} fontSize="small" />
					) : (
						<PersonIcon aria-hidden="true" style={{ fill: Colors.aeraWhite }} fontSize="small" />
					)}
				</StyledCommentUserCircle>
				<StyledCommentSenderWrapper>
					<P>{sender ? intl.formatMessage(messages.Commented, { sender: sender }) : intl.formatMessage(messages.Comment)}</P>
					{date && <StyledParagraph>- {formatDate(date)}</StyledParagraph>}
				</StyledCommentSenderWrapper>
			</StyledFromSection>
			<StyledUpdatedDateWrapper>
				{updatedDate && updatedDate != date && (
					<StyledSmallParagraph>
						{intl.formatMessage(messages.LastUpdated)}: {formatDate(updatedDate)}
					</StyledSmallParagraph>
				)}
			</StyledUpdatedDateWrapper>
			<StyledCommentWrapper>
				<StyledCommentText>
					<StyledCommentParagraph>{commentText}</StyledCommentParagraph>
				</StyledCommentText>
			</StyledCommentWrapper>
		</>
	)
}

Comment.propTypes = {
	from: PropTypes.string.isRequired,
	commentText: PropTypes.string.isRequired,
	date: PropTypes.string.isRequired,
	updatedDate: PropTypes.string,
	loading: PropTypes.bool
}
