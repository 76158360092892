import { useEffect, useCallback } from 'react'

const KEY_NAME_TAB = 'Tab'
const KEY_CODE_TAB = 9
const KEY_EVENT_TYPE = 'keydown'

// Allow tabbing only inside the first and last focusable element, i.e. inside modal
// Source: https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
export const useFocusInside = (firstFocusableElement, lastFocusableElement, condition = true) => {
	// useCallback: Cache function to not re-render as long as dependency does not change
	const handleKeyDown = useCallback((event, firstFocusableElement, lastFocusableElement) => {
		const isTabPressed = event.key === KEY_NAME_TAB || event.keyCode === KEY_CODE_TAB
		if (!isTabPressed || !firstFocusableElement || !lastFocusableElement) return
		// Shift + tab combo == back
		if (event.shiftKey) {
			if (document.activeElement === firstFocusableElement) {
				// TODO: Fix condition being correct: condition === true &&
				lastFocusableElement.focus()
				event.preventDefault()
			}
		} else {
			if (document.activeElement === lastFocusableElement) {
				firstFocusableElement.focus()
				event.preventDefault()
			}
		}
	}, [])

	useEffect(() => {
		if (condition === false) {
			document.removeEventListener(KEY_EVENT_TYPE, event => handleKeyDown(event, firstFocusableElement, lastFocusableElement), false)
		} else {
			document.addEventListener(KEY_EVENT_TYPE, event => handleKeyDown(event, firstFocusableElement, lastFocusableElement), false)
			return () => document.removeEventListener(KEY_EVENT_TYPE, event => handleKeyDown(event, firstFocusableElement, lastFocusableElement), false)
		}
	}, [firstFocusableElement, lastFocusableElement, condition])
}
