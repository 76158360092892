import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import messages from '../../../../translations/messages'

import { baseInputStyles, StyledHelpText, StyledInputWrapper } from '../formStyles'
import { FieldError } from '../Feedback/FieldError'
import { Label } from '../../../../styles/Typography'

export const StyledInput = styled.input`
	${baseInputStyles};
`

export const InputField = ({ id, value, label, required, disabled, errorMessage, helpText, onChange, type, size, pattern, maxLength, ...props }) => {
	const intl = useIntl()

	return (
		<StyledInputWrapper>
			<Label errorMessage={errorMessage} htmlFor={id}>
				{label}
				{!required && ' - ' + intl.formatMessage(messages.Optional)}
			</Label>
			<StyledInput
				//aria-required={required}
				aria-describedby={
					helpText && errorMessage ? `helpText-${id} error-message-${id}` : helpText ? `helpText-${id}` : errorMessage ? `error-message-${id}` : null
				}
				aria-invalid={errorMessage ? true : null}
				errorMessage={errorMessage}
				value={value}
				onChange={onChange}
				type={type || 'text'}
				id={id}
				name={id}
				size={size || '20'}
				pattern={pattern}
				disabled={disabled}
				maxLength={maxLength ? maxLength : '45'}
				{...props}
			/>
			{errorMessage && <FieldError id={`error-message-${id}`} errorMessage={errorMessage} />}
			{helpText && <StyledHelpText id={`helpText-${id}`}>{helpText}</StyledHelpText>}
		</StyledInputWrapper>
	)
}

InputField.propTypes = {
	id: PropTypes.string.isRequired,
	value: PropTypes.string,
	label: PropTypes.string.isRequired,
	required: PropTypes.bool,
	disabled: PropTypes.bool,
	errorMessage: PropTypes.string,
	helpText: PropTypes.string,
	onChange: PropTypes.func,
	type: PropTypes.string,
	size: PropTypes.string,
	pattern: PropTypes.string,
	maxLength: PropTypes.string
}
