export const SET_LOCALE = "setLocale"
export const SET_AUTHENTICATED = "setAuthenticated"
export const REMOVE_PERSONAL_DATA = "removePersonalData"
export const TOGGLE_AUTHENTICATED = "toggleAuthenticated"
export const SET_LOGIN_STATUS_ID = "setLoginStatusId"
export const SET_AUTH_TOKEN = "setAuthToken"
export const SET_REFRESH_TOKEN = "setRefreshToken"
export const TOGGLE_MENU = "toggleMenu"
export const SET_AUTHORIZING = "setAuthorizing"
export const SET_ACTIVE_LINK = "setActiveLink"
export const SET_PATH = "setPath"
export const SET_CODE_VERIFIER = "setCodeVerifier"
export const SET_LOGIN_URL = "setLoginUrl"
export const SET_LOGIN_FROM_TIMEOUT_URL = "setLoginFromTimeoutUrl"
export const SET_OLD_CODE_VERIFIER = "setOldCodeVerifier"
