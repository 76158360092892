import React, { useEffect, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import styled from 'styled-components'
import DataTable from 'react-data-table-component'

import { StyledAlertSection, StyledContainer, StyledHeaderSection, StyledMainSection, StyledTableWrapper } from '../../styles/styledComponents'
import messages from '../../translations/messages'
import { Helmet } from 'react-helmet-async'
import { Colors } from '../../styles/Colors'
import { getPageIdByMenuItemIndex, menuItemsIndex } from '../../utils/menuUtil'
import { TableLoadingSkeleton } from '../common/Table/TableLoadingSkeleton'
import { backend } from '../../api/backend'
import { renderDataTipCell } from '../common/Table/formatterUtil'
import { renderErrorFlatFeedback, renderNetworkError, renderNoInformationError, renderTimeoutError } from '../../utils/feedbackUtil'
import { fetchLoginUrlFromTimeout, useStore } from '../../state/store'
import { generateCodeVerifier, getLoginUrl } from '../../utils/loginUtil'
import { setActiveLink, setCodeVerifier, setFetchedLoginFromTimeoutUrl, setPath } from '../../state/actions'
import { DATA_PROCESSING_PATH } from '../../containers/Header/Menu/routes'
import { ExternalLink } from '../../assets/icons/ExternalLink'
import { statusCodes } from '../../utils/networkUtil'
import { STORAGE_DATA_PROCESSOR_REPORT } from '../../utils/storageUtil'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { H1, P } from '../../styles/Typography'

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
	height: 30px !important;
	width: 30px !important;
	margin-top: 10px;
`

export const DataProcessingPage = () => {
	const intl = useIntl()
	const { state, dispatch } = useStore()
	const pageId = getPageIdByMenuItemIndex(menuItemsIndex.PROCESSING_PAGE)

	const getReportFromSessionStorage = () => {
		return JSON.parse(sessionStorage.getItem(STORAGE_DATA_PROCESSOR_REPORT))
	}

	const saveReportInSessionStorage = report => {
		sessionStorage.setItem(STORAGE_DATA_PROCESSOR_REPORT, JSON.stringify(report))
	}

	const [dataProcessorReportInfo, setDataProcessorReportInfo] = useState(getReportFromSessionStorage() || [])
	const [dataProcessorReportInfoLoading, setDataProcessorReportInfoLoading] = useState(false)

	const [tokenExpired, setTokenExpired] = useState(false)
	const [noUserInfo, setNoUserInfo] = useState(false)
	const [networkError, setNetworkError] = useState(false)

	const [verifier, setVerifier] = useState(null)

	useEffect(() => {
		dispatch(setPath(DATA_PROCESSING_PATH))
		dispatch(setActiveLink(menuItemsIndex.PROCESSING_PAGE))
	}, [])

	useEffect(() => {
		if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
			const oldVerifier = state.oldCodeVerifier
			let verifier = generateCodeVerifier()
			if (verifier === oldVerifier) {
				verifier = generateCodeVerifier()
			}
			setVerifier(verifier)
			dispatch(setCodeVerifier(verifier))
		}
	}, [tokenExpired])

	useEffect(() => {
		if (fetchLoginUrlFromTimeout() === null && tokenExpired) {
			const fetchData = async () => {
				const url = await getLoginUrl(verifier)
				dispatch(setPath(DATA_PROCESSING_PATH))
				dispatch(setActiveLink(menuItemsIndex.PROCESSING_PAGE))
				dispatch(setFetchedLoginFromTimeoutUrl(url))
			}
			fetchData()
		}
	}, [verifier])

	const addIdToArrayObjects = array => {
		if (!array || array?.length === 0) return array
		const arrayWithIds = array.map((object, index) => ({ ...object, id: index }))
		return arrayWithIds
	}

	const hasMountedRef = useRef(false)
	useEffect(() => {
		const controller = new AbortController()
		if (!hasMountedRef.current) {
			setDataProcessorReportInfo([])
			setDataProcessorReportInfoLoading(true)
			backend
				.get(`/dataprocessor/report/${state.locale || 'nb'}`, { signal: controller.signal })
				.then(response => {
					const report = response.data.dataProcessorReport?.result
					setDataProcessorReportInfo(addIdToArrayObjects(report) || [])
					saveReportInSessionStorage(addIdToArrayObjects(report))
				})
				.catch(error => {
					const { response = {} } = error
					if (error.name !== 'CanceledError') {
						if (response?.status === statusCodes.UNAUTHORIZED) {
							setTokenExpired(true)
						} else if (response?.status === statusCodes.NOT_FOUND) {
							setNoUserInfo(true)
						} else {
							setNetworkError(true)
						}
					}
				})
				.finally(() => {
					setDataProcessorReportInfoLoading(false)
				})
		} else {
			return () => controller.abort()
		}
	}, [])

	const [resetPaginationToggle] = useState(false)

	// TODO: Customize own sort icon to not get accessibility issues
	// TODO: Fix searching by formatted cells, not backend values
	const columns = [
		{
			id: 'id',
			name: 'id',
			selector: row => row.id,
			hidden: true,
			sortable: false
		},
/*		{
			id: 'Data Category',
			name: intl.formatMessage(messages.DataCategoryHeader),
			selector: row => row['Data Category'],
			cell: row => renderDataTipCell(row['Data Category']),
			sortable: true,
			minWidth: '220px'
		}, */
		{
			id: 'Purpose',
			name: intl.formatMessage(messages.PurposeOfUseHeader),
			selector: row => row['Purpose'],
			cell: row => renderDataTipCell(row['Purpose'], 800),
			sortable: true,
			minWidth: '300px'
		},
		{
			id: 'Datacenter Location',
			name: intl.formatMessage(messages.DataCenterLocationHeader),
			selector: row => row['Datacenter Location'],
			cell: row => renderDataTipCell(row['Datacenter Location']),
			sortable: true
		},
		{
			id: 'Data Processor',
			name: intl.formatMessage(messages.DataProcessorHeader),
			selector: row => row['Data Processor'],
			cell: row => renderDataTipCell(row['Data Processor']),
			sortable: true
		},
		{
			id: 'Retention Time',
			name: intl.formatMessage(messages.RetentionTimeHeader),
			selector: row => row['Retention Time'],
			cell: row => renderDataTipCell(row['Retention Time']),
			sortable: true
		}
	]

	const paginationComponentOptions = {
		rangeSeparatorText: intl.formatMessage(messages.Of),
		noRowsPerPage: true
	}

	const renderAlerts = () => {
		return (
			<StyledAlertSection>
				{noUserInfo && renderNoInformationError(() => setNoUserInfo(false), intl)}
				{tokenExpired && renderTimeoutError(() => setTokenExpired(false), null, intl)}
				{networkError && renderNetworkError(() => setNetworkError(false), null, intl)}
			</StyledAlertSection>
		)
	}

	const renderDataProcessorReportTable = () => {
		if (!dataProcessorReportInfoLoading && !dataProcessorReportInfo?.length) {
			return renderErrorFlatFeedback(intl)
		}

		return (
			<StyledTableWrapper>
				<DataTable
					columns={columns.filter(column => column.hidden !== true)}
					data={dataProcessorReportInfo || []}
					keyField="id"
					pagination
					paginationComponentOptions={paginationComponentOptions}
					progressPending={dataProcessorReportInfoLoading}
					progressComponent={<TableLoadingSkeleton />}
					paginationResetDefaultPage={resetPaginationToggle}
					sortIcon={<StyledArrowDropDownIcon />}
				/>
			</StyledTableWrapper>
		)
	}

	return (
		<StyledContainer aria-labelledby={pageId}>
			<Helmet>
				<title>{intl.formatMessage(messages.ProcessingMenuOption)}</title>
			</Helmet>
			{renderAlerts()}
			<StyledHeaderSection>
				<H1 id={pageId}>{intl.formatMessage(messages.ProcessingTitle)}</H1>
				<P>
					{intl.formatMessage(messages.ProcessingDescription)}
					<a style={{ color: Colors.aeraLinkColor }} href={intl.formatMessage(messages.ProcessingPolicyLink)}>
						{intl.formatMessage(messages.AerasPrivacyPolicyPage)}{' '}
					</a>
					<ExternalLink />
				</P>
			</StyledHeaderSection>
			<StyledMainSection>{renderDataProcessorReportTable()}</StyledMainSection>
		</StyledContainer>
	)
}
