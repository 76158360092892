import randomBytes from 'randombytes'
import { STORAGE_LOCALE } from './storageUtil'
/*
import { TextEncoder, TextDecoder } from "util"
global.TextEncoder = TextEncoder
global.TextDecoder = TextDecoder*/

const sha256 = string => {
	const utf8 = new TextEncoder().encode(string)
	//const hash = crypto.subtle.digest('SHA-256', utf8)

	return crypto.subtle.digest('SHA-256', utf8).then(hashBuffer => {
		const hashArray = Array.from(new Uint8Array(hashBuffer))
		const hashHex = hashArray.map(bytes => bytes.toString(16).padStart(2, '0')).join('')
		return hashHex
	})
}

const base64URLEncode = str => {
	return str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

export const generateCodeVerifier = () => {
	let verifier = base64URLEncode(randomBytes(32).toString('hex'))
	return verifier
}

const generateCodeChallenge = async codeVerifier => {
	if (codeVerifier) {
		let sha = await sha256(codeVerifier)
		let challenge = base64URLEncode(sha)
		return challenge
	}
}

const getCallbackUri = () => {
	return encodeURIComponent(window.location.protocol + '//' + window.location.host + process.env.PUBLIC_URL + '/#/login/callback')
}

export const getLoginUrl = async verifier => {
	let code_challenge = await generateCodeChallenge(verifier)

	let locale = localStorage.getItem(STORAGE_LOCALE)
	if (locale && locale === `"nb"`) {
		locale = 'no'
	} else {
		locale = 'en'
	}
	return (
		process.env.REACT_APP_BACKEND_URL +
		'/consumerportal/oidc/authorize/' +
		locale +
		'?code_challenge=' +
		code_challenge +
		'&' +
		'callback_uri=' +
		getCallbackUri()
	)
}

