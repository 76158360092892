import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useIntl } from "react-intl"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

import { Button } from "../../Button"
import messages from "../../../../translations/messages"
import { backend } from "../../../../api/backend"
import { H2 } from "../../../../styles/Typography"

const StyledSelectWrapper = styled.div`
    //margin-bottom: 80px;
    margin-left: -24px;
`
const StyledHeader = styled(H2)`
    margin-bottom: 12px;
`

const StyledSingleSelectField = styled.select`
    height: 42px;
    width: ${(props) => (props.width ? props.width : `110px`)};
    border-radius: 3px 0 0 3px;
    border: 1px solid #e5e5e5;
    padding: 0 32px 0 16px;

    &:hover {
        cursor: pointer;
    }
    &:disabled {
        cursor: not-allowed;
    }
    appearance: none;
`

const StyledColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    margin-right: 16px;
`

const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    flex-wrap: wrap;
`

const StyledLabel = styled.label``

const StyledButton = styled(Button)`
    margin-top: 29px;
`

const StyledExpandMoreIcon = styled(ExpandMoreIcon)`
    margin-top: -32px;
    ${(props) => (props.$marginLeft ? `margin-left: ${props.$marginLeft}` : `margin-left: 105px`)};
    cursor: pointer;
    pointer-events: none;
`

export const YearMonthSelectFields = ({ setArray }) => {
    const intl = useIntl()
    const [yearOptions, setYearOptions] = useState([])

    const thisYear = new Date().getFullYear()
    const thisMonth = new Date().getMonth()

    const [selectedYear, setSelectedYear] = useState(thisYear)

    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]

    const [selectedMonth, setSelectedMonth] = useState(thisMonth + 1)
    const [isRetrievingData, setIsRetrievingData] = useState(false)

    const getMonthOption = (month) => {
        return { label: intl.formatMessage(messages[month]), value: months.indexOf(month) + 1 }
    }

    const populateMonthOptions = () => {
        return months.map((month) => {
            return getMonthOption(month)
        })
    }

    const [monthOptions, setMonthOptions] = useState(populateMonthOptions())

    const setMonthRestriction = () => {
        const lastIndexToInclude = thisMonth
        const filteredMonths = months.slice(0, lastIndexToInclude + 1)
        const filteredMonthOptions = filteredMonths.map((month) => {
            return getMonthOption(month)
        })
        if (filteredMonthOptions?.length && !filteredMonthOptions.some((month) => month.value == selectedMonth)) {
            setSelectedMonth(filteredMonthOptions[filteredMonthOptions.length - 1].value)
        }
        setMonthOptions(filteredMonthOptions)
    }

    useEffect(() => {
        const populateYearDropdown = async () => {
            setYearOptions([])
            let options = []
            let currentYear = new Date().getFullYear()
            let earliestYear = 2022
            while (currentYear >= earliestYear) {
                options = [...options, { label: currentYear, value: currentYear }]
                currentYear -= 1
            }
            setYearOptions(options)
            setMonthRestriction()
        }
        populateYearDropdown()
    }, [])

    const onlyOneYearOption = () => {
        return yearOptions.length === 1
    }

    const renderOption = (option) => {
        return (
            <option key={option.value} value={option.value}>
                {option.label}
            </option>
        )
    }

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value)

        const selectedYear = e.target.value
        if (selectedYear == thisYear) {
            setMonthRestriction()
        } else {
            setMonthOptions(populateMonthOptions())
        }
    }

    const handleMonthChange = (e) => {
        setSelectedMonth(e.target.value)
    }

    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate()
    }

    const appendZeroToSingleDigit = (digit) => {
        if (digit?.toString().length === 1) return "0" + digit
        else return digit
    }

    const getTransactionsByDate = () => {
        setIsRetrievingData(true)
        const queryParams = {
            fromDate: selectedYear + "-" + appendZeroToSingleDigit(selectedMonth) + "-" + "01",
            toDate: selectedYear + "-" + appendZeroToSingleDigit(selectedMonth) + "-" + getDaysInMonth(selectedYear, selectedMonth)
        }
        backend
            .get("/transactions", { params: queryParams })
            .then((response) => {
                setArray(response.data.transactions)
            })
            .catch((error) => {
                console.warn("Error retrieving transactions by date", error)
                setArray([])
            })
            .finally(() => {
                setIsRetrievingData(false)
            })
        return []
    }

    const handleDateSearch = (e) => {
        e.preventDefault()
        getTransactionsByDate()
    }

    return (
        <StyledSelectWrapper>
            <StyledHeader>{intl.formatMessage(messages.SearchByDateTitle)}</StyledHeader>
            <form onSubmit={handleDateSearch}>
                <StyledRow>
                    <StyledColumn>
                        <StyledLabel htmlFor="year">{intl.formatMessage(messages.Year)}</StyledLabel>
                        <StyledSingleSelectField
                            defaultValue={selectedYear}
                            onChange={(e) => handleYearChange(e)}
                            disabled={onlyOneYearOption()}
                            name="year"
                            id="year"
                        >
                            {yearOptions?.map((yearOption) => renderOption(yearOption))}
                        </StyledSingleSelectField>
                        <StyledExpandMoreIcon aria-hidden="true" $marginLeft="75px" />
                    </StyledColumn>
                    <StyledColumn>
                        <StyledLabel htmlFor="month">{intl.formatMessage(messages.Month)}</StyledLabel>
                        <StyledSingleSelectField defaultValue={selectedMonth} onChange={(e) => handleMonthChange(e)} width="140px" name="month" id="month">
                            {monthOptions?.map((monthOption) => renderOption(monthOption))}
                        </StyledSingleSelectField>
                        <StyledExpandMoreIcon aria-hidden="true" />
                    </StyledColumn>
                    <StyledColumn>
                        <StyledButton isLoading={isRetrievingData} small>
                            {intl.formatMessage(messages.SearchTrxDateButtonLabel)}
                        </StyledButton>
                    </StyledColumn>
                </StyledRow>
            </form>
        </StyledSelectWrapper>
    )
}
YearMonthSelectFields.propTypes = {
    setArray: PropTypes.func.isRequired
}
