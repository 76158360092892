export const freeTextValidation = `^[ÆØÅæøåa-zA-Z0-9\r|\n !?@(),.-]*$`

export const nonAllowedCharacters = `^[^<>'"]+$`

export const isTextValid = (text) => {
    if (!text || text?.length === 0 || text === "") {
        return true
    }
    const includesNonAllowedCharacters = text.match(nonAllowedCharacters)
    return !!includesNonAllowedCharacters
}

// 1MB = 1048576 in Bytes
export const fileIsOverMaxSize = (file, maxSizeInMegaBytes = 1048576 * 10) => {
    if (!file) return false
    return file?.size > maxSizeInMegaBytes
}
