import React from 'react'
import styled from 'styled-components'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import PropTypes from 'prop-types'

import { StyledHelpText } from '../formStyles'
import { Colors } from '../../../../styles/Colors'
import { FieldError } from '../Feedback/FieldError'
import { breakpoints } from '../../../../utils/menuUtil'
import { focusVisibleBaseStyles, StyledAppendiceImagesWrapper, StyledAppendiceImageWrapper } from '../../../../styles/styledComponents'
import { ActionButton } from '../../ActionButton'
import { useIntl } from 'react-intl'
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined'
import messages from '../../../../translations/messages'
import { Label, P, P2 } from '../../../../styles/Typography'

const StyledWrapper = styled.div`
	margin-bottom: 18px;
	width: 262px;
	${breakpoints.smallDevice} {
		width: 414px;
	}
`

const StyledInputWrapper = styled.div`
	width: 262px;
	${breakpoints.smallDevice} {
		width: 414px;
	}
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: start;
	padding: 8px;
	margin-bottom: -1px;
	height: 58px;
	border: 1px solid ${Colors.aeraPrimaryDarkGrey};
	border-radius: 5px;
	${props =>
		props.$errorMessage &&
		`
        border: 1px solid ${Colors.aeraRed};
    `}
`

const StyledDriveFolderUploadIcon = styled(DriveFolderUploadIcon)`
	margin-right: 6px;
	margin-bottom: 2px;
	margin-left: -4px;
`

const StyledInputButton = styled.button`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: -9px;
	width: 170px;
	${breakpoints.smallDevice} {
		min-width: 130px;
	}
	height: 58px;
	font-weight: 500;
	background-color: ${Colors.aeraPrimaryLightGrey};
	color: ${Colors.aeraBlack};
	border: 1px solid ${Colors.aeraPrimaryGrey};
	border-radius: 5px 0 0 5px;
	margin-right: 12px;
	&:hover {
		cursor: pointer;
	}
	&:focus-visible {
		${focusVisibleBaseStyles};
	}
	&:disabled {
		cursor: not-allowed;
		background-color: ${Colors.aeraPrimaryDarkGrey};
		color: ${Colors.aeraWhite};
		border: 1px solid ${Colors.aeraPrimaryDarkGrey};
	}
	${props =>
		props.$errorMessage &&
		`
        border: 1px solid ${Colors.aeraRed};
    `}
`

const StyledFileNamesWrapper = styled(P2)`
	width: 180px;
	${breakpoints.smallDevice} {
		width: 250px;
	}
	max-height: 46px;
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	//text-overflow: ellipsis;
	overflow-x: hidden;
	overflow-y: hidden;
`

const StyledFileNameParagraph = styled(P)`
	text-overflow: ellipsis;
	overflow-x: hidden;
	overflow-y: hidden;
	font-size: 0.8rem;
	max-width: 210px;
	margin-top: 2px;
	${breakpoints.smallDevice} {
		max-width: 305px;
	}
	white-space: nowrap;

	::-webkit-scrollbar {
		width: 10px;
		height: 5px;
	}
	/* Track */
	::-webkit-scrollbar-track {
		background: #f1f1f1;
	}
	/* Handle */
	::-webkit-scrollbar-thumb {
		background: #888;
	}
	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555;
	}
`

const StyledInput = styled.input`
	visibility: hidden;
	display: none;
`

export const FileInputField = ({
	id,
	label,
	selectedFileNames,
	disabled,
	errorMessage,
	helpText = 'Last opp fil som vedlegg',
	handleUpload,
	handleFileChange,
	chooseFileText = 'Velg fil',
	noFileChosenText = 'Ingen fil valgt',
	handleRemoveAttachment,
	...props
}) => {
	const intl = useIntl()

	const isDisabled = () => {
		return disabled || selectedFileNames?.length > 4
	}

	return (
		<StyledWrapper>
			<Label errorMessage={errorMessage} htmlFor="loadFileXml">
				{label}
			</Label>
			<StyledInputWrapper $errorMessage={errorMessage}>
				<StyledInputButton
					$errorMessage={errorMessage}
					aria-describedby={
						helpText && errorMessage ? `helpText-${id} error-message-${id}` : helpText ? `helpText-${id}` : errorMessage ? `error-message-${id}` : null
					}
					aria-invalid={errorMessage ? true : null}
					type="button"
					id="loadFileXml"
					onClick={() => handleUpload()}
					disabled={isDisabled()}
				>
					<StyledDriveFolderUploadIcon aria-hidden="true" style={{ fill: isDisabled() ? Colors.aeraWhite : Colors.aeraBlack }} fontSize="small" />
					<P>{chooseFileText}</P>
				</StyledInputButton>
				<StyledFileNamesWrapper>
					{selectedFileNames?.length
						? selectedFileNames?.length === 1
							? 1 + ' ' + intl.formatMessage(messages.FileInputChosenFile)
							: selectedFileNames?.length === 5
							? selectedFileNames?.length + ' ' + intl.formatMessage(messages.FileInputChosenFiles) + ` (${intl.formatMessage(messages.Max)})`
							: selectedFileNames?.length + ' ' + intl.formatMessage(messages.FileInputChosenFiles)
						: noFileChosenText}
				</StyledFileNamesWrapper>
				<StyledInput data-testid="contact-page-uploader" type="file" id={id} onChange={e => handleFileChange(e)} {...props} />
			</StyledInputWrapper>
			{selectedFileNames?.length > 0 && (
				<StyledAppendiceImagesWrapper>
					{selectedFileNames.map((selectedFileName, index) => {
						return (
							<StyledAppendiceImageWrapper key={`selectedFileName-${index}`}>
								<StyledFileNameParagraph>{selectedFileName}</StyledFileNameParagraph>
								<ActionButton
									type="button"
									Icon={<HighlightOffOutlinedIcon focusable="false" style={{ fill: 'red' }} aria-hidden="true" />}
									ariaLabel="Remove attachment"
									onClick={() => handleRemoveAttachment(selectedFileName)}
								/>
							</StyledAppendiceImageWrapper>
						)
					})}
				</StyledAppendiceImagesWrapper>
			)}
			{errorMessage && <FieldError id={`error-message-${id}`} errorMessage={errorMessage} />}
			{helpText && <StyledHelpText id={`helpText-${id}`}>{helpText}</StyledHelpText>}
		</StyledWrapper>
	)
}

FileInputField.propTypes = {
	id: PropTypes.string.isRequired,
	selectedFileNames: PropTypes.array,
	label: PropTypes.string.isRequired,
	disabled: PropTypes.string,
	errorMessage: PropTypes.string,
	helpText: PropTypes.string,
	handleUpload: PropTypes.func.isRequired,
	handleFileChange: PropTypes.func.isRequired,
	handleRemoveAttachment: PropTypes.func.isRequired,
	chooseFileText: PropTypes.string,
	noFileChosenText: PropTypes.string
}
