import React, { useEffect } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import CloseIcon from "@mui/icons-material/Close"

import { Colors } from "../../../../styles/Colors"
import { breakpoints } from "../../../../utils/menuUtil"
import { Button } from "../../Button"
import { useEscapeKey } from "../../../../hooks/useEscapeKey"
import { useFocusInside } from "../../../../hooks/useFocusInside"
import { H1, P } from "../../../../styles/Typography"

const StyledDialogBackground = styled.div`
    z-index: 3;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${(props) => (props.open ? `display: block;` : `display: none;`)}
    background: rgba(0, 0, 0, 0.5);
`

const StyledDialogContainer = styled.div`
    background-size: cover;
    position: fixed;
    text-align: center;
    background: ${Colors.aeraWhite};
    border-radius: 16px;
    padding: 30px 30px;
    color: ${Colors.aeraBlack};
    height: auto;
    top: 20%;
    left: 50%;
    max-width: 800px;
    min-width: 200px;
    width: 360px;
    margin-left: -180px;
    ${breakpoints.smallDevice} {
        width: 400px;
        margin-left: -200px;
    }
`

const StyledDialogHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 0 12px 0;
`

const StyledHeaderTitle = styled(H1)`
    font-size: 1.2rem;
    max-width: 200px;
    ${breakpoints.smallDevice} {
        font-size: 1.5rem;
        max-width: 100%;
    }
    ${(props) => props.headerColor && `color: ${props.headerColor};`};
`

const StyledDialogBody = styled.div`
    padding: 0 0 28px 0;
`

const StyledFooter = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
`

const StyledCloseButtonContainer = styled.div`
    margin-right: -20px;
    margin-top: -20px;
    margin-bottom: -24px;
    display: flex;
    align-content: end;
    justify-content: end;
`

export const Modal = ({ id, handleClose, open, title, subTitle, renderAdditionalInfo, headerColor, firstFocusedElementIndex, ...props }) => {
    const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    const modal = document.getElementById(id)
    const firstFocusableElement = modal?.querySelectorAll(focusableElements)[0]
    const firstDefaultFocusedElement = modal?.querySelectorAll(focusableElements)[firstFocusedElementIndex || 1]
    const focusableContent = modal?.querySelectorAll(focusableElements)
    const lastFocusableElement = focusableContent?.[focusableContent?.length - 1]

    useFocusInside(firstFocusableElement, lastFocusableElement)
    useEscapeKey(handleClose)

    useEffect(() => {
        if (open) {
            firstDefaultFocusedElement?.focus()
        }
    }, [open])

    return (
        <StyledDialogBackground open={open} aria-hidden={!open}>
            <StyledDialogContainer id={id} role="alertdialog" aria-labelledby="modal-title" aria-describedby="modal-body" {...props}>
                <StyledCloseButtonContainer>
                    <Button cancel round aria-label="Close" onClick={() => handleClose()}>
                        <CloseIcon aria-hidden="true" />
                    </Button>
                </StyledCloseButtonContainer>
                <StyledDialogHeader>
                    <StyledHeaderTitle id="modal-title" headerColor={headerColor}>
                        {title}
                    </StyledHeaderTitle>
                </StyledDialogHeader>
                <StyledDialogBody id="modal-body">
                    <P>{subTitle}</P>
                </StyledDialogBody>
                <StyledFooter>{renderAdditionalInfo()}</StyledFooter>
            </StyledDialogContainer>
        </StyledDialogBackground>
    )
}

Modal.propTypes = {
    id: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    subTitle: PropTypes.string,
    renderAdditionalInfo: PropTypes.func,
    firstFocusedElementIndex: PropTypes.number,
    headerColor: PropTypes.string
}
