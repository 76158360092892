import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Colors } from '../../../../styles/Colors'
import { setActiveLink } from '../../../../state/actions'
import { useStore } from '../../../../state/store'
import PropTypes from 'prop-types'
import { getColorBySeverity, severities } from '../../../../utils/feedbackUtil'
import { breakpoints } from '../../../../utils/menuUtil'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutline'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import WarningIcon from '@mui/icons-material/Warning'
import CloseIcon from '@mui/icons-material/Close'
import { Button } from '../../Button'
import { P2 } from '../../../../styles/Typography'

export const StyledAlert = styled.div`
	z-index: 2;
	height: 70px;
	width: 100%;
	background-color: white;
	position: fixed;
	top: 120px;
	left: 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 44px 26px 44px 16px;
	${breakpoints.smallDevice} {
		padding: 26px 26px 24px 34px;
	}
	border-bottom: 3px solid ${Colors.aeraPrimaryLightGrey}; //${props => props.color && `3px solid ${props.color}`};
`

const StyledParagraph = styled(P2)`
	margin-left: 12px;
`

const StyledTextWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`

const StyledCheckCircleIcon = styled(CheckCircleIcon)`
	margin-top: 3px;
`

const StyledIconBackground = styled.div`
	display: flex;
	align-content: center;
	justify-content: center;
	min-width: 30px;
	height: 30px;
	border-radius: 30%;
	background: ${props => (props.$bgColor ? props.$bgColor : Colors.aeraPrimaryLightestGrey)};
`

const StyledWarningAmberOutlinedIcon = styled(WarningAmberOutlinedIcon)`
	margin-top: 2px;
`

const StyledDangerousOutlinedIcon = styled(WarningIcon)`
	margin-top: 2px;
`

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
	margin-top: 2px;
`

/*
 * Created for accessibility.
 * Based on: https://mui.com/material-ui/react-alert/
 */
export const AeraAlert = ({ handleClose, severity = 'info', link, ...props }) => {
	const { dispatch } = useStore()
	const { linkTo, linkText, pageIndex } = link || {}

	const handleLinkClick = () => {
		dispatch(setActiveLink(pageIndex))
	}

	//const longText = props.children?.length > 20

	const getIconBySeverity = severity => {
		switch (severity) {
			case severities.SUCCESS:
				return (
					<StyledIconBackground $bgColor={getColorBySeverity(severities.SUCCESS)?.color}>
						<StyledCheckCircleIcon aria-hidden="true" style={{ fill: getColorBySeverity(severities.SUCCESS)?.iconColor }} />
					</StyledIconBackground>
				)
			case severities.WARNING:
				return (
					<StyledIconBackground $bgColor={getColorBySeverity(severities.WARNING)?.color}>
						<StyledWarningAmberOutlinedIcon aria-hidden="true" style={{ fill: getColorBySeverity(severities.WARNING)?.iconColor }} />
					</StyledIconBackground>
				)
			case severities.ERROR:
				return (
					<StyledIconBackground $bgColor={getColorBySeverity(severities.ERROR)?.color}>
						<StyledDangerousOutlinedIcon aria-hidden="true" style={{ fill: getColorBySeverity(severities.ERROR)?.iconColor }} />
					</StyledIconBackground>
				)
			default:
				return (
					<StyledIconBackground $bgColor={getColorBySeverity(severity)?.color}>
						<StyledInfoOutlinedIcon aria-hidden="true" style={{ fill: getColorBySeverity(severity)?.iconColor }} />
					</StyledIconBackground>
				)
		}
	}

	const getLive = severity => {
		switch (severity) {
			case severities.ERROR:
				return 'assertive'
			default:
				return 'polite'
		}
	}

	return (
		<StyledAlert color={getColorBySeverity(severity)?.color} aria-live={getLive(severity)} $severity={severity}>
			<StyledTextWrapper>
				{getIconBySeverity(severity)}
				<StyledParagraph>
					{props.children}
					{link && (
						<Link style={{ color: Colors.aeraLinkColor }} to={linkTo} onClick={() => handleLinkClick()}>
							{linkText}
						</Link>
					)}
				</StyledParagraph>
			</StyledTextWrapper>
			<Button round cancel aria-label="Close" onClick={() => handleClose()}>
				<CloseIcon aria-hidden="true" />
			</Button>
		</StyledAlert>
	)
}

AeraAlert.propTypes = {
	handleClose: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	severity: PropTypes.string,
	ariaLive: PropTypes.string,
	link: PropTypes.object
}
