import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Colors } from '../../../styles/Colors'
import { statuses } from '../../../utils/statusUtil'

const StyledBadge = styled.div`
	display: inline-block;
	background-color: ${props => (props.color ? `${props.color}` : `${Colors.aeraPrimaryDarkGreen}`)};
	border-radius: 3px;
	color: ${Colors.aeraWhite};
	font-size: 0.65rem;
	font-weight: 600;
	letter-spacing: 0.5px;
	margin-bottom: 16px;
	padding: 3px 8px 0 8px;
	text-align: center;
	text-transform: uppercase;
`

const scope = 'status'

export const inquiryStatusMessages = defineMessages({
	NEW: {
		id: `${scope}.NEW`,
		defaultMessage: 'New'
	},
	IN_PROGRESS: {
		id: `${scope}.IN_PROGRESS`,
		defaultMessage: 'In Progress'
	},
	CLOSED: {
		id: `${scope}.CLOSED`,
		defaultMessage: 'Closed'
	}
})

export const statusMessages = defineMessages({
	...inquiryStatusMessages,
	DEACTIVATED: {
		id: `${scope}.DEACTIVATED`,
		defaultMessage: 'Deactivated'
	},
	NO_STATUS: {
		id: `${scope}.NO_STATUS`,
		defaultMessage: 'No status'
	},
	ACTIVE: {
		id: `${scope}.ACTIVE`,
		defaultMessage: 'Active'
	},

	INACTIVE: {
		id: `${scope}.INACTIVE`,
		defaultMessage: 'Inactive'
	},
	BLOCKED: {
		id: `${scope}.BLOCKED`,
		defaultMessage: 'Blocked'
	},
	REVOKED: {
		id: `${scope}.REVOKED`,
		defaultMessage: 'Revoked'
	},
	PENDING_DELETE: {
		id: `${scope}.PENDING_DELETE`,
		defaultMessage: 'Pending deletion'
	},
	OPEN: {
		id: `${scope}.OPEN`,
		defaultMessage: 'Open'
	}
})

const AeraBadge = props => {
	return <StyledBadge color={props.color}>{props.children}</StyledBadge>
}

AeraBadge.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	color: PropTypes.string
}

export const StatusBadge = ({ status }) => {
	const intl = useIntl()

	if (!status) {
		return <AeraBadge color={Colors.aeraPrimaryGrey}>{intl.formatMessage(statusMessages.NO_STATUS)}</AeraBadge>
	}

	switch (status) {
		case statuses.NEW_TICKET:
			return <AeraBadge color={Colors.aeraSecondaryDarkBlue}>{intl.formatMessage(statusMessages.NEW)}</AeraBadge>
		case statuses.IN_PROGRESS_TICKET:
			return <AeraBadge color={'blue'}>{intl.formatMessage(statusMessages.IN_PROGRESS)}</AeraBadge>
		case statuses.CLOSED_TICKET:
			return <AeraBadge color={Colors.aeraPrimaryDarkGreen}>{intl.formatMessage(statusMessages.CLOSED)}</AeraBadge>
		case statuses.CLOSED:
			return <AeraBadge color={Colors.aeraRed}>{intl.formatMessage(statusMessages.CLOSED)}</AeraBadge>
		case statuses.ACTIVE:
			return <AeraBadge color={Colors.aeraPrimaryDarkGreen}>{intl.formatMessage(statusMessages.ACTIVE)}</AeraBadge>
		case statuses.OPEN:
			return <AeraBadge color={Colors.aeraPrimaryDarkGreen}>{intl.formatMessage(statusMessages.OPEN)}</AeraBadge>
		case statuses.INACTIVE:
			return <AeraBadge color={Colors.aeraRed}>{intl.formatMessage(statusMessages.INACTIVE)}</AeraBadge>
		default:
			return <AeraBadge color={Colors.aeraPrimaryGrey}>{status}</AeraBadge>
	}
}

StatusBadge.propTypes = {
	status: PropTypes.string.isRequired
}
