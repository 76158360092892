export const MAIN_PATH = ''
export const HOME_PATH = '/'
export const LOGIN_HOME_PATH = MAIN_PATH + '/login'
export const LOGOUT_HOME_PATH = MAIN_PATH
export const PROFILE_PATH = MAIN_PATH + '/profile'
export const TRANSACTIONS_PATH = MAIN_PATH + '/transactions'
export const CONSENTS_PATH = MAIN_PATH + '/consents'
export const DATA_PROCESSING_PATH = MAIN_PATH + '/privacy'
export const CONTACT_PATH = MAIN_PATH + '/contact'
export const CONTACT_PATH_LOG_LIST = MAIN_PATH + CONTACT_PATH + '/log'
export const CONTACT_PATH_LOG = MAIN_PATH + CONTACT_PATH_LOG_LIST
export const SITEMAP_PATH = MAIN_PATH + '/sitemap'
export const CALLBACK_PATH = MAIN_PATH + '/login/callback'
export const DIRECT_LOGIN_PATH = MAIN_PATH + '/direct-login'
export const LOGGED_OUT_PATH = MAIN_PATH + '/logged-out'
