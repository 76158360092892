import React from "react"
import { useIntl } from "react-intl"
import styled from "styled-components"
import { Link } from "react-router-dom"

import messages from "../../translations/messages"
import { Colors } from "../../styles/Colors"
import { setActiveLink, setLocale } from "../../state/actions"
import { useStore } from "../../state/store"
import { menuItemsIndex, breakpoints } from "../../utils/menuUtil"
import { SITEMAP_PATH } from "../Header/Menu/routes"
import { AeraLogoLink } from "../Header/AeraLogoLink"
import { ExternalLink } from "../../assets/icons/ExternalLink"
import { A, H2, P, P2 } from "../../styles/Typography"

//${(props) => props.menuIsOpen && `display: none`};
const StyledFooterWrapper = styled.footer`
    position: relative;
    bottom: 0;
    width: 100%;
    background-color: ${Colors.aeraPrimaryLightestGrey};
    height: 100%;
`

const StyledFooterRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    padding: 15px 0 0 30px;
    margin-bottom: 40px;
    gap: 25px;
    ${breakpoints.smallDevice} {
        padding: 15px 0 0 80px;
    }
`

const StyledOptionCategoryText = styled(H2)`
    font-size: 1rem;
    margin-top: 20px;
    ${breakpoints.smallDevice} {
        margin-top: -3px;
        font-size: 1.2rem;
    }
`

const StyledOption = styled(P)`
    list-style-type: none;
    color: ${Colors.aeraBlack};
    :hover {
        font-weight: 500;
    }
`

const StyledLanguageButton = styled.button`
    border: none;
    background-color: ${Colors.aeraPrimaryLightestGrey};
    :hover {
        cursor: pointer;
        font-weight: 500;
    }
    :first-of-type {
        margin: 0 0 10px 0;
    }
    :disabled {
        cursor: not-allowed;
        color: ${Colors.aeraDisabledButtonLink};
    }
`

const StyledCopyrightWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 15px 40px 15px 30px;
`

const StyledCopyrightText = styled(P2)`
    text-align: center;
    font-weight: 300;
`

const StyledBorder = styled.div`
    width: 80%;
    border: 0.5px solid #ccc;
    margin-bottom: 10px;
    ${breakpoints.smallDevice} {
        width: 120px;
        border: 0.5px solid #ccc;
        margin-bottom: 10px;
    }
    ${breakpoints.mediumDevice} {
        width: 140px;
    }
`

const StyledLogoWrapper = styled.div`
    width: 20%;
`

const StyledOptionsWrapper = styled.div`
    margin-left: 38px;
    display: flex;
    flex-direction: row;
    gap: 32px;
    margin-top: 3px;
    ${breakpoints.smallDevice} {
        margin-left: 64px;
        gap: 58px;
        margin-top: 30px;
    }
    ${breakpoints.mediumDevice} {
        justify-content: center;
        width: 40%;
        gap: 70px;
    }
    ${breakpoints.largeDevice} {
        width: 50%;
    }
`

export const Footer = () => {
    const intl = useIntl()
    const { state, dispatch } = useStore()

    return (
        <StyledFooterWrapper>
            {state.isAuthenticated && (
                <StyledFooterRow>
                    <StyledLogoWrapper>
                        <AeraLogoLink footer />
                    </StyledLogoWrapper>
                    <StyledOptionsWrapper>
                        <div>
                            <StyledOptionCategoryText>{intl.formatMessage(messages.Support)}</StyledOptionCategoryText>
                            <StyledBorder />
                            <StyledOption>
                                <Link
                                    style={{ color: Colors.aeraLinkColor }}
                                    to={SITEMAP_PATH}
                                    onClick={() => dispatch(setActiveLink(menuItemsIndex.SITEMAP_PAGE))}
                                >
                                    {intl.formatMessage(messages.Sitemap)}
                                </Link>
                            </StyledOption>
                        </div>
                        <div>
                            <StyledOptionCategoryText>{intl.formatMessage(messages.Languages)}</StyledOptionCategoryText>
                            <StyledBorder />
                            <StyledOption>
                                <StyledLanguageButton
                                    disabled={state.locale === "nb"}
                                    onClick={() => dispatch(setLocale("nb"))}
                                    aria-label={
                                        state.locale === "nb"
                                            ? "Selected language: " + intl.formatMessage(messages["nb"])
                                            : "Select language: " + intl.formatMessage(messages["nb"])
                                    }
                                >
                                    {intl.formatMessage(messages.nb)}
                                </StyledLanguageButton>
                            </StyledOption>
                            <StyledOption>
                                <StyledLanguageButton
                                    disabled={state.locale === "en"}
                                    onClick={() => dispatch(setLocale("en"))}
                                    aria-label={
                                        state.locale === "en"
                                            ? "Selected language: " + intl.formatMessage(messages["en"])
                                            : "Select language: " + intl.formatMessage(messages["en"])
                                    }
                                >
                                    {intl.formatMessage(messages.en)}
                                </StyledLanguageButton>
                            </StyledOption>
                        </div>
                    </StyledOptionsWrapper>
                </StyledFooterRow>
            )}
            <StyledCopyrightWrapper>
                <StyledCopyrightText>
                    {state.isAuthenticated ? <>
                        {intl.formatMessage(messages.Footer)} -{" "} </>
                        : "© 2022 Aera Payment & Identification AS – Askekroken 11, 0277 Oslo - Telefon +47 22 55 25 00 "
                    }
                    <A href="https://www.aera.id">
                        {intl.formatMessage(messages.aeraURL) + " "}
                        <ExternalLink />
                    </A>
                </StyledCopyrightText>
            </StyledCopyrightWrapper>
        </StyledFooterWrapper>
    )
}
