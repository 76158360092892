import React from 'react'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { Colors } from '../../../styles/Colors'
import messages from '../../../translations/messages'
import TrumfLogo from '../../../assets/images/companyLogos/trumf.png'
import CooPayLogo from '../../../assets/images/companyLogos/coopay.png'
import AeraLogo from '../../../assets/images/companyLogos/AeraLogo.png'
import { formatStringToDate } from '../Table/formatterUtil'
import { StatusBadge } from '../StatusBadge'
import { consentStatuses as statuses } from '../../../utils/statusUtil'
import { mpaNames } from '../../../utils/customerUtil'

import { Button } from '../Button'
import { H2, P } from '../../../styles/Typography'

const StyledJumbotronWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: start;
	justify-content: start;
	padding: 36px;
	min-width: 270px;
	min-height: 350px;
	background-color: ${props => props.color || Colors.aeraPrimaryLightestGrey};
	${props =>
		props.$borderColor
			? props.$borderColor === Colors.aeraPrimaryLightGrey
				? `
                border: 6px solid ${Colors.aeraPrimaryLightGrey};
                padding-top: 76px;
                min-height: 360px;
                `
				: `
                border: 6px solid ${props.$borderColor};`
			: `border: 6px solid ${Colors.aeraPrimaryLightGrey};`}
	border-radius: 6px;
`

const StyledParagraph = styled.p`
	margin-top: -9px;
	font-size: 0.8rem;
	margin-bottom: 16px;
`

const StyledImageSection = styled.div`
	margin-bottom: 20px;
	margin-top: -10px;
`

const StyledDeleteWrapper = styled.div`
	display: flex;
	flex-direction: column;

	margin-top: 16px;
	margin-bottom: 28px;
`

const StyledMPALogo = styled.img`
	width: 100px;
	margin-bottom: -30px;
	margin-top: -5px;
	${props => (props.src && props.src === TrumfLogo ? `margin-bottom: -50px;` : props.src === CooPayLogo && ``)}
`

export const LabelBox = ({ consent, handleDelete }) => {
	const intl = useIntl()

	const { createdConsent, lastUpdatedConsent, mpaName, consentStatus } = consent || {}

	let borderColor
	let image

	// TODO: Test this functionality!
	if (mpaName === mpaNames.TRUMF) {
		borderColor = Colors.customerTrumfColor
		image = TrumfLogo
	} else if (mpaName === mpaNames.COOPAY) {
		borderColor = Colors.customerCoopColor
		image = CooPayLogo
	} else if (mpaName === mpaNames.AERA) {
		borderColor = Colors.aeraPrimaryGreen
		image = AeraLogo
	} else if (mpaName === mpaNames.AERA_PILOT) {
		borderColor = Colors.aeraPrimaryDarkGreen
		image = AeraLogo
	} else {
		borderColor = Colors.aeraPrimaryLightGrey
		image = null
	}

	const getConsentStatus = consentStatus => {
		// We don't want to show the consumer if the status is blocked, revoked or pending delete for simplicity
		if (!consentStatus) return null
		switch (consentStatus) {
			case statuses.ACTIVE:
				return statuses.ACTIVE
			default:
				return statuses.INACTIVE
		}
	}

	const consentCanBeDeleted = consentStatus => {
		return handleDelete && consentStatus === statuses.ACTIVE
	}

	return (
		<>
			<StyledJumbotronWrapper $borderColor={borderColor}>
				{image && (
					<StyledImageSection>
						<StyledMPALogo alt={`${mpaName} logo`} src={image} />
					</StyledImageSection>
				)}
				<H2>{mpaName || '-'}</H2>
				<P>{intl.formatMessage(messages.CreatedConsent)}</P>
				<StyledParagraph>{formatStringToDate(createdConsent) || '-'}</StyledParagraph>
				<P>{intl.formatMessage(messages.LastUpdatedConsent)}</P>
				<StyledParagraph>{formatStringToDate(lastUpdatedConsent) || '-'}</StyledParagraph>
				<P>{intl.formatMessage(messages.Status)}</P>
				<StatusBadge status={getConsentStatus(consentStatus)} />
			</StyledJumbotronWrapper>
			<StyledDeleteWrapper>
				<Button width="270px" medium outlined onClick={handleDelete} disabled={!consentCanBeDeleted(consentStatus)}>
					{intl.formatMessage(messages.RevokeConsent)}
				</Button>
			</StyledDeleteWrapper>
		</>
	)
}

LabelBox.propTypes = {
	consent: PropTypes.object.isRequired,
	handleDelete: PropTypes.func
}
